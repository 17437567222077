import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { GlobalContext } from "../../../App";

const Spouse = (props) => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const { fetchSpouData, spouseModal, setSpouseModal } = props;
    const [usersSpouse, setUsersSpouse] = useState([]);
    const [address, setAddress] = useState('');
    const [province, setProvince] = useState('');

    const onSubmit = () => {
        setShowLoader(true);
        const newUser = {
            ...formValues,
        };
        setUsersSpouse([...usersSpouse, newUser]);
        http(
            {
                method: "POST",
                url: `client/spouse/${id}/add`,
                body: {
                    first_name: formValues.first_name,
                    middle_name: formValues.middle_name,
                    last_name: formValues.last_name,
                    sin: formValues.sin,
                    contact_number: formValues.contact_number,
                    email: formValues.email,
                    province: formValues.province,
                    postal_code: formValues.postal_code,
                    date_of_birth: formValues.date_of_birth,
                    country_id: formValues.country_id,
                    city: formValues.city,
                    date_of_landing: formValues.date_of_landing,
                    mis_notes: formValues.mis_notes,
                    address: formValues.address,
                },
                isSecure: true,
            },
            (res) => {
                setSpouseModal(false);
                toast.success("Individual client spouse successfully added");
                reset();
                fetchSpouData();
                setTimeout(() => {
                    setShowLoader(false);
                }, 500);
            },
            (err) => {
                setTimeout(() => {
                    setShowLoader(false);
                }, 500);
                console.error("Error Add Contact:", err);
            }
        );
    }

    const onHideHandler = () => {
        setSpouseModal(false);

    };

    const handleSelect = async (value) => {
        setAddress(value);

        try {
            const results = await geocodeByAddress(value);

            if (results && results[0]) {
                const addressComponents = results[0].address_components;
                const formattedAddress = results[0].formatted_address;
                let selectedProvince = '';
                let postalCode = '';

                // Extract the province (administrative_area_level_1) and postal code
                addressComponents.forEach((component) => {
                    if (component.types.includes('administrative_area_level_1')) {
                        selectedProvince = component.short_name; // Use `long_name` for full province name
                    }
                    if (component.types.includes('postal_code')) {
                        postalCode = component.short_name; // or long_name if you want the full postal code
                    }
                });

                // Debugging: Display the full selected address, province, and postal code in the console
                console.log('Full selected address:', formattedAddress);
                console.log('Selected province:', selectedProvince);
                console.log('Postal code:', postalCode);

                // Set the selected address, province, and postal code in the form
                setValue('address', formattedAddress);
                setValue('province', selectedProvince);
                setValue('postal_code', postalCode);
                setProvince(selectedProvince); // Update the province state
            } else {
                console.warn('No results found for the selected address.');
            }
        } catch (error) {
            // Improved error logging
            console.error('Error selecting place:', error?.message || 'No message', error?.stack || 'No stack trace');
        }
    };


    const handleChange = (value) => {
        setAddress(value);
    };


    return (
        <Modal
            show={spouseModal}
            onHide={onHideHandler}
            centered
            size="xl"
            contentClassName="mx-auto"
            className="modal operating-modal font-poppins"
            backdrop="static"
            keyboard={false}
        >


            <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                <h4 className="fs-20">Spouse</h4>
                <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
            </div>
            <div className="modal-body px-4 pt-0 pb-0">
                <form
                    onSubmit={handleSubmit(onSubmit)}

                >
                    <div className="advance-form row">
                        <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                            <label className="fs-18">Name</label>
                            <input
                                type="text"
                                name="first_name"
                                id="first_name"
                                className="form-input mt-2"
                                {...register("first_name", {
                                    required: "Required ",
                                })}
                            />
                            {errors?.first_name && <span className="error">{errors?.first_name.message}</span>}
                        </div>
                        <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                            <label className="fs-18">Midlle Name</label>
                            <input
                                type="text"
                                name="middle_name"
                                id="middle_name"
                                className="form-input mt-2"
                                {...register("middle_name", {

                                })}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                            <label className="fs-18">Last Name</label>
                            <input
                                type="text"
                                name="last_name"
                                id="last_name"
                                className="form-input mt-2"
                                {...register("last_name", {
                                    required: "Required ",
                                })}
                            />
                            {errors?.last_name && <span className="error">{errors?.last_name.message}</span>}
                        </div>
                        <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                            <label className="fs-18">SIN</label>
                            <input
                                type="text"
                                name="sin"
                                id="sin"
                                className="form-input mt-2"
                                {...register("sin", {

                                })}

                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                            <label className="fs-18">Email Address</label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                className="form-input mt-2"
                                {...register("email", {
                                    required: "Required ",
                                })}
                            />
                            {errors?.email && <span className="error">{errors?.email.message}</span>}
                        </div>
                        <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                            <label className="fs-18">Phone No.</label>
                            <input
                                type="text"
                                name="contact_number"
                                id="contact_number"
                                className="form-input mt-2"
                                {...register("contact_number", {

                                })}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                            <label className="fs-18">Date of Birth</label>
                            <input
                                type="date"
                                name="date_of_birth"
                                id="date_of_birth"
                                className="form-input mt-2"
                                {...register("date_of_birth", {

                                })}

                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                            <label className="fs-18">Date of Landing</label>
                            <input
                                type="date"
                                name="date_of_landing"
                                id="date_of_landing"
                                className="form-input mt-2"
                                {...register("date_of_landing", {

                                })}
                            />
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">Country of Residence</label>
                            <select
                                className="form-input mt-2"
                                name="country_id"
                                id="country_id"
                                {...register("country_id", {

                                })}

                            >
                                <option value="" disabled selected>Select</option>
                                <option value="1" >Canada</option>
                                <option value="2">USA</option>
                                <option value="3">India</option>
                                <option value="4">Other</option>
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                            <label className="fs-18">City</label>
                            <input
                                type="text"
                                name="city"
                                id="city"
                                className="form-input mt-2"
                                {...register("city", {

                                })}

                            />
                        </div>
                        <div className="col-lg-8 col-md-12 col-md-12 mt-4">
                            <label className="fs-18">Street Address</label>
                            {/* <input
                                type="text"
                                name="address"
                                id="address"
                                className="form-input mt-2 google-address"
                                {...register("address", {

                                })}
                            /> */}

                            <PlacesAutocomplete
                                value={address}
                                onChange={handleChange}  // Handle input change here
                                onSelect={handleSelect}  // Handle address selection here
                                searchOptions={{
                                    componentRestrictions: { country: ['us', 'ca'] },
                                }}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Type address here...',
                                                className: 'form-input mt-2 google-address',
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion) => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                        })}
                                                        key={suggestion.placeId}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </div>
                        <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                            <label className="fs-18">Province</label>
                            {/* <select className="form-input mt-2"
                                name="province"
                                id="province"

                                {...register("province", {


                                })}
                            >
                                <option value="" disabled selected>Select</option>
                                <option value="AB">AB</option>
                                <option value="BC">BC</option>
                                <option value="MB">MB</option>
                                <option value="NB">NB</option>
                                <option value="NL">NL</option>
                                <option value="NS">NS</option>
                                <option value="NT">NT</option>
                                <option value="NU">NU</option>
                                <option value="ON" selected="">ON</option>
                                <option value="PE">PE</option>
                                <option value="QC">QC</option>
                                <option value="SK">SK</option>
                                <option value="YT">YT</option>
                            </select> */}
                            <input
                                className="form-input mt-2"
                                name="province"
                                id="province"
                                value={province} // Bind the province state to this input field
                                readOnly
                                {...register("province", {})}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                            <label className="fs-18">Postal Code</label>
                            {/* <input
                                type="text"
                                nmae="postal_code"
                                id="postal_code"
                                className="form-input mt-2"
                                {...register("postal_code", {


                                })}

                            /> */}
                            <input
                                type="text"
                                name="postal_code"
                                id="postal_code"
                                className="form-input mt-2"
                                placeholder="Type here..."
                                readOnly
                                {...register("postal_code", {

                                })}
                            />
                        </div>
                        <div className="col-lg-8 col-md-12 col-md-12 mt-4">
                            <label className="fs-18">Misc. Note</label>
                            <input
                                type="text"
                                name="mis_notes"
                                id="mis_notes"
                                className="form-input mt-2"
                                {...register("mis_notes", {


                                })}

                            />
                        </div>

                    </div>

                    <div className="modal-footer border-0 px-0 pb-4 mt-4">
                        <button type="submit" className="btn-blue">Add</button>
                    </div>
                </form>



            </div>
        </Modal>
    )
}
export default Spouse;