import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import Add_Individual_Task from "./tsidebarTaskModal/Add_individual_Task";
import moment from "moment";
import { http } from "../../http/http";
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactPaginate from 'react-paginate';
import { GlobalContext } from "../../../App";
import { Modal } from "react-bootstrap";
import Select from 'react-select';
// import 'survey-core/defaultV2.min.css';
// import { Model } from 'survey-core';
// import { Survey } from 'survey-react-ui';
// import CreatorComponent from '../../servay/CreatorComponent';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Add_Main_Task = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('tasks');
    const [addTaskModal, setAddTaskModal] = useState(false);
    const [priorityList, setPriorityList] = useState([]);
    const [interactionList, setInteractionList] = useState([]);
    const [StatusList, setStatusList] = useState([]);
    const [assignData, setAssignData] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [recurringTaskList, setRecurringTaskList] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState("All");
    const [selectedStatus, setSelectedStatus] = useState("All");
    const [selectedStatusRecurringType, setSelectedStatusRecurringType] = useState(null);
    const [selectedTaskType, setSelectedTaskType] = useState(null);
    const [selectedName, setSelectedName] = useState("All");
    const [selectedAssignee, setSelectedAssignee] = useState("All");
    const [searchInput, setSearchInput] = useState("");
    const [assigneeSearchInput, setAssigneeSearchInput] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startDateRecurring, setStartDateRecurring] = useState(null);
    const [nextMonthDateRecurring, setNextMonthDateRecurring] = useState(null);
    const [lastMonthDateRecurring, setLastMonthDateRecurring] = useState(null);
    const [filteredTasks, setFilteredTasks] = useState(taskList);
    const [filteredRecurringTasks, setFilteredRecurringTasks] = useState(recurringTaskList)
    const [filteredNames, setFilteredNames] = useState([]);
    const [filteredAssignees, setFilteredAssignees] = useState([]);
    const [isPriorityOpen, setIsPriorityOpen] = useState(false);
    const [isStatusOpen, setIsStatusOpen] = useState(false);
    const [isStatusOpenRecurring, setIsStatusOpenRecurring] = useState(false);
    const [isTaskTypeOpen, setIsTaskTypeOpen] = useState(false);
    const [isNameOpen, setIsNameOpen] = useState(false);
    const [isAssigneeOpen, setIsAssigneeOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [currentRecurringPage, setCurrentRecurringPage] = useState(1);
    const [totalRecurringPages, setRecurringTotalPages] = useState(1);
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showSubTask, setShowSubTask] = useState(false);
    const options = [
        { value: '1', label: 'Option 1' },
        { value: '2', label: 'Option 2' },
        { value: '3', label: 'Option 3' },
        { value: '4', label: 'Option 4' },
        { value: '5', label: 'Option 5' },
        { value: '6', label: 'Option 6' },
        { value: '7', label: 'Option 7' },
        { value: '8', label: 'Option 8' },
    ];
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [selectedOption, setSelectedOption] = useState(null);

    const dropdownRef = useRef(null);

    const convertHexToRGBA = (hex, opacity) => {
        if (typeof hex !== 'string' || hex === null || hex.length === 0) {
            console.error('Invalid hex value:', hex);
            throw new TypeError('Hex value must be a non-empty string');
        }

        let r = 0, g = 0, b = 0;

        // 3 digits hex
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        }
        // 6 digits hex
        else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        } else {
            console.error('Invalid hex length:', hex);
            throw new Error('Hex value must be either 4 or 7 characters long');
        }

        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }
    // Handle Drag End
    const handleDragEnd = (result) => {
        const { destination, source } = result;
        if (!destination) return; // Exit if dropped outside the list

        // Reorder tasks
        const reorderedTasks = Array.from(filteredTasks);
        const [removed] = reorderedTasks.splice(source.index, 1);
        reorderedTasks.splice(destination.index, 0, removed);

        setFilteredTasks(reorderedTasks);
    };

    // Handle Drag End
    const handleDragEndRecurring = (result) => {
        const { destination, source } = result;
        if (!destination) return; // Exit if dropped outside the list

        // Reorder tasks
        const reorderedTasks = Array.from(filteredRecurringTasks);
        const [removed] = reorderedTasks.splice(source.index, 1);
        reorderedTasks.splice(destination.index, 0, removed);

        setFilteredRecurringTasks(reorderedTasks);
    };


    const handleTabClick = (tab) => {
        setShowLoader(true);
        setActiveTab(tab);
        setTimeout(() => {
            setShowLoader(false);
        }, 1000); // Simulate a delay of 1 second
    };

    // const handleClick = () => {
    //     setShowLoader(true);
    //     setTimeout(() => {
    //         if (activeTab === 'tasks') {
    //             navigate('/admin/task/add_task');
    //         } else {
    //             navigate('/admin/task/add_recurring_task');
    //         }
    //         setShowLoader(false);
    //     }, 500); // Simulate a delay of 1 second
    // };

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        setFilteredOptions(
            options.filter(option =>
                option.label.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option); // Set the selected option
        setIsOpen(false); // Close the dropdown
        setSearchTerm(''); // Clear the search term
        setFilteredOptions(options); // Reset filtered options to the full list
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    const toggleDropdown = () => {
        setIsOpen(prevState => !prevState);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleButtonClick = () => {
        setShowLoader(true);
        setTimeout(() => {
            if (activeTab === 'tasks') {
                navigate('/admin/task/add_task');
            } else {
                navigate('/admin/task/add_recurring_task');
            }
            setShowLoader(false);
        }, 500);
    };

    const handleDropdownClick = (event) => {
        event.stopPropagation(); // Prevent the dropdown from closing the button action
        setShowDropdown(!showDropdown);
    };

    const handleDropdownOptionClick = (option) => {
        setShowLoader(true);
        setShowDropdown(false);
        setTimeout(() => {
            if (option === 'add_task') {
                navigate('/admin/task/add_task');
            } else if (option === 'add_subtask') {
                // navigate('/admin/task/add_subtask');
                setShowSubTask(true); // Show the modal for adding a subtask

            }
            setShowLoader(false);
        }, 500);
    };

    const closeModal = () => {
        setShowSubTask(false);
    };
    const uniqueNames = useMemo(() => [
        ...new Set(
            taskList?.map(task =>
                task.task_type === "3"
                    ? task.client_self?.name
                    : `${task.client?.first_name} ${task.client?.last_name}`
            )
        )
    ], [taskList]);
    const uniqueAssignees = useMemo(() => [
        ...new Set(
            taskList.flatMap(task => task.assignees.map(assignee => assignee.first_name))
        )
    ], [taskList]);

    useEffect(() => {
        setFilteredNames(uniqueNames.filter(name => name?.toLowerCase().includes(searchInput.toLowerCase())));
    }, [searchInput, uniqueNames]);


    useEffect(() => {
        setFilteredAssignees(uniqueAssignees.filter(assignee => assignee.toLowerCase().includes(assigneeSearchInput.toLowerCase())));
    }, [assigneeSearchInput, uniqueAssignees])

    const uniqueNamesRecurring = useMemo(() => [
        ...new Set(
            recurringTaskList.flatMap(recurringTask =>
                recurringTask.tasks.map(task =>
                    task.task_type === "3"
                        ? task.client_self?.name
                        : `${task.client?.first_name} ${task.client?.last_name}`
                )
            )
        )
    ], [recurringTaskList]);

    const uniqueAssigneesRecurring = useMemo(() => [
        ...new Set(
            recurringTaskList.flatMap(recurringTask =>
                recurringTask.tasks.flatMap(task =>
                    task.assignees.map(assignee => assignee.first_name)
                )
            )
        )
    ], [recurringTaskList]);

    useEffect(() => {
        setFilteredNames(uniqueNamesRecurring.filter(name => name?.toLowerCase().includes(searchInput.toLowerCase())));
    }, [searchInput, uniqueNamesRecurring]);

    useEffect(() => {
        setFilteredAssignees(uniqueAssigneesRecurring.filter(assignee => assignee.toLowerCase().includes(assigneeSearchInput.toLowerCase())));
    }, [assigneeSearchInput, uniqueAssigneesRecurring]);

    const handlePriorityClick = () => {
        setIsPriorityOpen(!isPriorityOpen);
    };

    const handleStatusClick = () => {
        setIsStatusOpen(!isStatusOpen);
    };

    const handleStatusRecurringClick = () => {
        setIsStatusOpenRecurring(!isStatusOpenRecurring)
    }


    const handleTaskTypeClick = () => {
        setIsTaskTypeOpen(!isTaskTypeOpen);
    };
    const handleNameClick = () => {
        setIsNameOpen(!isNameOpen);
    };
    const handleAssigneeClick = () => {
        setIsAssigneeOpen(!isAssigneeOpen);
    };

    const handlePriorityOptionClick = (value) => {
        setSelectedPriority(value);
        setIsPriorityOpen(false);
    };

    const handleStatusOptionClick = (value) => {
        setSelectedStatus(value);
        setIsStatusOpen(false);
    };


    const handleStatusRecurringOptionClick = (value) => {
        setSelectedStatusRecurringType(value);
        setIsStatusOpenRecurring(false);
    };
    const handleTaskTypeOptionClick = (value) => {
        setSelectedTaskType(value);
        setIsTaskTypeOpen(false);
    };
    const handleNameOptionClick = (value) => {
        setSelectedName(value);
        setIsNameOpen(false);
    };
    const handleAssigneeOptionClick = (value) => {
        setSelectedAssignee(value);
        setIsAssigneeOpen(false);
    };
    const stopPropagation = (event) => {
        event.stopPropagation();
    };

    const handlePageClick = (data) => {
        const selectedPage = data.selected + 1;
        setCurrentPage(selectedPage);
    };
    const handleRecurringPageClick = (data) => {
        const selectedPage = data.selected + 1;
        setCurrentRecurringPage(selectedPage);
    };

    const handlePriorityData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `priority/listing`,
                    isSecure: true,
                },
                (res) => {
                    setPriorityList(res?.data?.data);
                },
            )
        } catch (error) {

        }
    };

    const handleInteractionData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `interaction_type/listing`,
                    isSecure: true,
                },
                (res) => {
                    setInteractionList(res?.data?.data);
                },
            )
        } catch (error) {

        }
    };

    const handleStatusData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `status_task/listing`,
                    isSecure: true,
                },
                (res) => {
                    setStatusList(res?.data?.data);
                },
            )
        } catch (error) {

        }
    };

    const handleAssisgnData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `staff/listing`,
                    isSecure: true,
                },
                (res) => {
                    setAssignData(res?.data?.data);
                },
            )
        } catch (error) {

        }
    };

    const handleTaskList = async (page) => {
        setShowLoader(true);
        try {
            http(
                {
                    method: "GET",
                    url: `client/outertask/listing?page=${page}`,
                    isSecure: true,
                },
                (res) => {
                    setTaskList(res?.data?.data);
                    setTotalPages(res?.data?.last_page); // Set total pages from the response
                    setShowLoader(false);
                },
            )
        } catch (error) {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        handleTaskList(currentPage);
    }, [currentPage]);

    const handleRecurringTaskList = async (page) => {
        setShowLoader(true);
        try {
            http(
                {
                    method: "GET",
                    url: `client/outertask/reccuring-listing?page=${page}`,
                    isSecure: true,
                },
                (res) => {
                    setRecurringTaskList(res?.data?.data);
                    setRecurringTotalPages(res?.data?.last_page);
                    setShowLoader(false);
                },
            )
        } catch (error) {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        handleRecurringTaskList(currentRecurringPage);
    }, [currentRecurringPage]);





    useEffect(() => {
        handleInteractionData();
        handlePriorityData();
        handleStatusData();
        handleAssisgnData();
    }, []);

    useEffect(() => {
        let filtered = taskList; // Start with the full task list

        // Apply assignee filter
        if (selectedAssignee !== "All") {
            filtered = filtered.filter(task =>
                task.assignees.some(assignee => assignee.first_name === selectedAssignee)
            );
        }

        // Apply name filter
        if (selectedName !== "All") {
            filtered = filtered.filter(task =>
                task.task_type === "3"
                    ? task.client_self?.name === selectedName
                    : `${task.client?.first_name} ${task.client?.last_name}` === selectedName
            );
        }

        // Apply priority filter
        if (selectedPriority !== "All") {
            filtered = filtered.filter(task => task.priority?.id == selectedPriority);
        }

        // Apply status filter
        if (selectedStatus !== "All") {
            filtered = filtered.filter(task => task.statustask?.id == selectedStatus);
        }

        // Apply task type filter
        if (selectedTaskType && selectedTaskType !== "All") {
            filtered = filtered.filter(task => task.task_type == selectedTaskType);
        }

        // Apply date range filter
        if (startDate && endDate) {
            filtered = filtered.filter(task => {
                const taskDate = moment(task.created_at);
                return taskDate.isBetween(startDate, endDate, 'day', '[]');
            });
        }

        setFilteredTasks(filtered); // Update state with the filtered tasks

    }, [selectedAssignee, selectedName, selectedPriority, selectedStatus, selectedTaskType, startDate, endDate, taskList]); // Dependencies



    useEffect(() => {
        let filtered = recurringTaskList;
        console.log(filtered, "filtered")



        if (selectedStatusRecurringType && selectedStatusRecurringType !== "All") {
            filtered = filtered.filter(task => task.reccuring_status == selectedStatusRecurringType);

        }

        // Apply task type filter
        if (selectedTaskType && selectedTaskType !== "All") {
            filtered = filtered.filter(task => task.reccuring_repeat == selectedTaskType);
        }


        if (startDateRecurring) {
            const selectedDate = moment(startDateRecurring).startOf('day');
            console.log('Selected Date:', selectedDate.format('YYYY-MM-DD')); // Debugging output

            filtered = filtered.filter(recurringTask =>
                recurringTask.tasks.some(task => {
                    const taskDate = moment(recurringTask.reccuring_start_date).startOf('day');
                    console.log('Task Date:', taskDate.format('YYYY-MM-DD')); // Debugging output
                    return taskDate.isSame(selectedDate, 'day');
                })
            );
        }

        // Filter by the next month date
        if (nextMonthDateRecurring) {
            const nextMonthSelectedDate = moment(nextMonthDateRecurring).startOf('day');
            console.log('Selected Date (Next Month):', nextMonthSelectedDate.format('YYYY-MM-DD')); // Debugging output

            filtered = filtered.filter(recurringTask =>
                recurringTask.tasks.some(task => {
                    const taskDate = moment(recurringTask.reccuring_start_next_date).startOf('day');
                    console.log('Task Date (Next Month):', taskDate.format('YYYY-MM-DD')); // Debugging output
                    return taskDate.isSame(nextMonthSelectedDate, 'day'); // Exact match for next month date
                })
            );
        }


        // Filter by the last month date
        if (lastMonthDateRecurring) {
            const lastMonthSelectedDate = moment(lastMonthDateRecurring).startOf('day');
            console.log('Selected Date (Last Month):', lastMonthSelectedDate.format('YYYY-MM-DD')); // Debugging output

            filtered = filtered.filter(recurringTask =>
                recurringTask.tasks.some(task => {
                    const taskDate = moment(recurringTask.reccuring_end_date).startOf('day'); // Convert task end date to a moment object at start of day
                    console.log('Task Date (Last Month):', taskDate.format('YYYY-MM-DD')); // Debugging output
                    return taskDate.isSame(lastMonthSelectedDate, 'day'); // Exact match for the last month date
                })
            );
        }


        setFilteredRecurringTasks(filtered);
    }, [selectedAssignee, selectedName, selectedPriority, selectedStatus, selectedTaskType, selectedStatusRecurringType, startDateRecurring, nextMonthDateRecurring, lastMonthDateRecurring, recurringTaskList]);

    return (
        <div class="right_section font-poppins">
            <div class="clients-detail-area py-3">
                <div class="tasks-area mt-2 align-items-center">
                    <div class="row tabs-content">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="individual">
                                <div className="row">
                                    <div className="col-md-12 right_head d-flex align-items-center flex-wrap justify-content-start gap-2 p-0">
                                        <div class="col-md-12 client-tabs">
                                            <ul class="nav nav-tabs border-0">
                                                <li class="nav-item me-2 mt-2">
                                                    <button
                                                        data-bs-toggle="tab"
                                                        className={`nav-link ${activeTab === 'tasks' ? 'active' : ''}`}
                                                        onClick={() => handleTabClick('tasks')}
                                                    >
                                                        Tasks
                                                    </button>
                                                </li>
                                                <li class="nav-item me-2 mt-2">
                                                    <button
                                                        data-bs-toggle="tab"
                                                        className={`nav-link ${activeTab === 'recurring' ? 'active' : ''}`}
                                                        onClick={() => handleTabClick('recurring')}
                                                    >
                                                        Recurring Tasks
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mt-2">
                                    <div className="col-md-6 p-0">
                                        <h4 className="fs-24">{activeTab === 'tasks' ? 'Tasks' : 'Recurring Tasks'}</h4>
                                    </div>
                                    <div className="col-md-6 right_head d-flex align-items-center flex-wrap justify-content-end gap-2 p-0 mt-4">
                                        <div className="btn-trans">
                                            <button className="transbtn">Create From Template</button>

                                        </div>
                                        {/* <div className="add-btn">
                                            <button
                                                className="add-new"
                                                onClick={handleClick}
                                                disabled={showLoader} // Disable button while loading
                                            >
                                                <i className="fa fa-plus me-2 w-8"></i>
                                                {activeTab === 'tasks' ? 'Add New Task' : 'Add Recurring Task'}
                                            </button>
                                            {showLoader && <div className="loader">Loading...</div>}
                                        </div> */}
                                        <div className="add-btn-icon">
                                            <button
                                                className="add-new-button"
                                                onClick={handleButtonClick}
                                                disabled={showLoader} // Disable button while loading
                                            >
                                                <span>Add New Task</span>
                                                {activeTab === 'tasks' && (
                                                    <>
                                                        <span className="divider-line mx-2"></span>
                                                        <i className="fa fa-caret-down" onClick={handleDropdownClick}></i>
                                                    </>
                                                )}
                                            </button>
                                            {showLoader && <div className="loader">Loading...</div>}
                                            {showDropdown && activeTab === 'tasks' && (
                                                <div className="dropdown">
                                                    <div onClick={() => handleDropdownOptionClick('add_task')}>Add New Task</div>
                                                    <div onClick={() => handleDropdownOptionClick('add_subtask')}>Add Subtask</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>


                                    <div className="col-md-12 d-flex items-start justify-content-end gap-3 mt-4 flex-wrap p-0">

                                        {activeTab === 'recurring' && (

                                            <div className="drop_box">
                                                <div className="dropdown" onClick={handleTaskTypeClick}>
                                                    <div className="dropdown__selected">
                                                        <label className="fs--14">
                                                            {/* Display "Type" if no type is selected; otherwise, show the selected type */}
                                                            {selectedTaskType === "All"
                                                                ? 'Type'
                                                                : selectedTaskType === 'All'
                                                                    ? 'All'
                                                                    : selectedTaskType === '1'
                                                                        ? 'Weekly'
                                                                        : selectedTaskType === '2'
                                                                            ? 'Monthly'
                                                                            : selectedTaskType === '3'
                                                                                ? 'Half Monthly'
                                                                                : selectedTaskType === '4'
                                                                                    ? 'Yearly'
                                                                                    : selectedTaskType === '5'
                                                                                        ? 'Day'
                                                                                        : 'Type'}
                                                        </label>
                                                        <span className="dropdown__icon">
                                                            {isTaskTypeOpen ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className={`dropdown__options ${isTaskTypeOpen ? 'dropdown__options--open' : ''}`}>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("All")}>All</div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("1")}>Weekly</div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("2")}>Monthly</div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("3")}>Half Monthly</div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("4")}>Yearly</div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("5")}>Day</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {activeTab === 'tasks' && (
                                            <div className="drop_box">
                                                <div className="dropdown" onClick={handleTaskTypeClick}>
                                                    <div className="dropdown__selected">
                                                        <label className="fs--14">
                                                            {/* Display "Type" if no type is selected; otherwise, show the selected type */}
                                                            {selectedTaskType === "All"
                                                                ? 'Type'
                                                                : selectedTaskType === 'All'
                                                                    ? 'All'
                                                                    : selectedTaskType === '1'
                                                                        ? 'Individual'
                                                                        : selectedTaskType === '2'
                                                                            ? 'Business'
                                                                            : selectedTaskType === '3'
                                                                                ? 'Self'
                                                                                : 'Type'}
                                                        </label>
                                                        <span className="dropdown__icon">
                                                            {isTaskTypeOpen ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className={`dropdown__options ${isTaskTypeOpen ? 'dropdown__options--open' : ''}`}>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("All")}>All</div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("1")}>Individual</div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("2")}>Business</div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("3")}>Self</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div>
                                            {activeTab === "tasks" && (
                                                <div className="drop_box">
                                                    <div className="dropdown" onClick={handleAssigneeClick}>
                                                        <div className="dropdown__selected">
                                                            <label className="fs--14">Assignee</label>
                                                            <span className="dropdown__icon">
                                                                {isAssigneeOpen ? (
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="9.447"
                                                                        height="7.875"
                                                                        viewBox="0 0 9.447 7.875"
                                                                    >
                                                                        <path
                                                                            id="drop_down"
                                                                            d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z"
                                                                            transform="translate(-1658 -1171.987)"
                                                                            fill="#212529"
                                                                        />
                                                                    </svg>
                                                                ) : (
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="9.447"
                                                                        height="7.875"
                                                                        viewBox="0 0 9.447 7.875"
                                                                    >
                                                                        <path
                                                                            id="drop_down"
                                                                            d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z"
                                                                            transform="translate(-1658 -1171.987)"
                                                                            fill="#212529"
                                                                        />
                                                                    </svg>
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div
                                                            className={`dropdown__options ${isAssigneeOpen ? 'dropdown__options--open' : ''
                                                                }`}
                                                            onClick={stopPropagation}
                                                        >
                                                            <input
                                                                type="text"
                                                                value={assigneeSearchInput}
                                                                onChange={(e) => setAssigneeSearchInput(e.target.value)}
                                                                onMouseDown={stopPropagation}
                                                                placeholder="Search"
                                                            />
                                                            <div
                                                                key="all-assignee"
                                                                className="dropdown__option"
                                                                onClick={() => handleAssigneeOptionClick("All")}
                                                            >
                                                                All
                                                            </div>
                                                            {filteredAssignees.map((assignee) => (
                                                                <div
                                                                    key={assignee}
                                                                    className="dropdown__option"
                                                                    onClick={() => handleAssigneeOptionClick(assignee)}
                                                                >
                                                                    {assignee}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {activeTab === "recurring" && (
                                                <div className="drop_box datepick">
                                                    <div className="date-picker-wrapper">
                                                        <DatePicker
                                                            className="dropdown fs--14 date-picker-input"
                                                            selected={startDateRecurring}
                                                            onChange={(date) => setStartDateRecurring(date)}
                                                            isClearable={true}
                                                            placeholderText="Start Date"
                                                        />
                                                        {/* Conditionally render the calendar icon based on whether a date is selected */}
                                                        {!startDateRecurring && (
                                                            <span className="calendar-icon">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    fill="currentColor"
                                                                    className="bi bi-calendar"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1zm1-1h12a1 1 0 0 1 1 1v1H1V4a1 1 0 0 1 1-1zm3.5 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 3 8z" />
                                                                </svg>
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                        <div>
                                            {activeTab === "tasks" && (
                                                <div className="drop_box">
                                                    <div className="dropdown" onClick={handleNameClick}>
                                                        <div className="dropdown__selected">
                                                            <label>Created by</label>
                                                            <span className="dropdown__icon">
                                                                {isNameOpen ? (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                        <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                    </svg>
                                                                ) : (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                        <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                    </svg>
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className={`dropdown__options ${isNameOpen ? 'dropdown__options--open' : ''}`} onClick={stopPropagation}>
                                                            <input
                                                                type="text"
                                                                value={searchInput}
                                                                onChange={(e) => setSearchInput(e.target.value)}
                                                                onMouseDown={stopPropagation}
                                                                placeholder="Search"
                                                            />
                                                            <div
                                                                key="all-name"
                                                                className="dropdown__option"
                                                                onClick={() => handleNameOptionClick("All")}
                                                            >
                                                                All
                                                            </div>
                                                            {filteredNames.map(name => (
                                                                <div
                                                                    key={name}
                                                                    className="dropdown__option"
                                                                    onClick={() => handleNameOptionClick(name)}
                                                                >
                                                                    {name}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {activeTab === "recurring" && (
                                                <div className="drop_box datepick">
                                                    <div className="date-picker-wrapper">
                                                        <DatePicker
                                                            className="dropdown fs--14 date-picker-input"
                                                            selected={nextMonthDateRecurring}
                                                            onChange={(date) => setNextMonthDateRecurring(date)}
                                                            isClearable={true}
                                                            placeholderText="Next Month Date"
                                                        />
                                                        {/* Conditionally render the calendar icon based on whether a date is selected */}
                                                        {!nextMonthDateRecurring && (
                                                            <span className="calendar-icon">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    fill="currentColor"
                                                                    className="bi bi-calendar"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1zm1-1h12a1 1 0 0 1 1 1v1H1V4a1 1 0 0 1 1-1zm3.5 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 3 8z" />
                                                                </svg>
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                        <div>
                                            {activeTab === "tasks" && (
                                                <div className="drop_box datepick">
                                                    <DatePicker
                                                        className="dropdown fs--14"
                                                        selectsRange
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        onChange={(update) => {
                                                            setStartDate(update[0]);
                                                            setEndDate(update[1]);
                                                        }}
                                                        isClearable={true}
                                                        placeholderText="Select a date range"
                                                    />
                                                </div>
                                            )}
                                            {activeTab === "recurring" && (
                                                <div className="drop_box datepick">
                                                    <div className="date-picker-wrapper">
                                                        <DatePicker
                                                            className="dropdown fs--14 date-picker-input"
                                                            selected={lastMonthDateRecurring}
                                                            onChange={(date) => setLastMonthDateRecurring(date)}
                                                            isClearable={true}
                                                            placeholderText="End Month Date"
                                                        />
                                                        {/* Conditionally render the calendar icon based on whether a date is selected */}
                                                        {!lastMonthDateRecurring && (
                                                            <span className="calendar-icon">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    fill="currentColor"
                                                                    className="bi bi-calendar"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1zm1-1h12a1 1 0 0 1 1 1v1H1V4a1 1 0 0 1 1-1zm3.5 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 3 8z" />
                                                                </svg>
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        {activeTab === "tasks" ? (
                                            <div className="drop_box">
                                                <div className="dropdown" onClick={handlePriorityClick}>
                                                    <div className="dropdown__selected">
                                                        <label className="fs--14">
                                                            {selectedPriority === 'All'
                                                                ? 'Priority'
                                                                : priorityList.find((option) => option.id == selectedPriority)?.name || 'Priority'}
                                                        </label>
                                                        <span className="dropdown__icon">
                                                            {isPriorityOpen ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className={`dropdown__options ${isPriorityOpen ? 'dropdown__options--open' : ''}`}>
                                                        <div
                                                            key="all-priority"
                                                            className="dropdown__option"
                                                            onClick={() => handlePriorityOptionClick('All')}
                                                        >
                                                            All
                                                        </div>
                                                        {priorityList.map((option) => (
                                                            <div
                                                                key={option.id}
                                                                className="dropdown__option"
                                                                onClick={() => handlePriorityOptionClick(option.id)}
                                                            >
                                                                {option.name}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            null // Show nothing when activeTab is "recurring" or anything else
                                        )}


                                        {activeTab === 'tasks' && (
                                            <div className="drop_box">
                                                <div className="dropdown" onClick={handleStatusClick}>
                                                    <div className="dropdown__selected">
                                                        <label className="fs--14">
                                                            {selectedStatus === 'All'
                                                                ? 'Status'
                                                                : StatusList.find((option) => option.id == selectedStatus)?.name || 'Status'}
                                                        </label>
                                                        <span className="dropdown__icon">
                                                            {isStatusOpen ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className={`dropdown__options ${isStatusOpen ? 'dropdown__options--open' : ''}`}>
                                                        <div
                                                            key="all-status"
                                                            className="dropdown__option"
                                                            onClick={() => handleStatusOptionClick("All")}
                                                        >
                                                            All
                                                        </div>
                                                        {StatusList.map(option => (
                                                            <div
                                                                key={option.id}
                                                                className="dropdown__option"
                                                                onClick={() => handleStatusOptionClick(option.id)}
                                                            >
                                                                {option.name}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {activeTab === 'recurring' && (

                                            <div className="drop_box">
                                                <div className="dropdown" onClick={handleStatusRecurringClick}>
                                                    <div className="dropdown__selected">
                                                        <label className="fs--14">
                                                            {/* Display "Type" if no type is selected; otherwise, show the selected type */}
                                                            {selectedStatusRecurringType === "All"
                                                                ? 'Status'
                                                                : selectedStatusRecurringType === 'All'
                                                                    ? 'All'
                                                                    : selectedStatusRecurringType === '1'
                                                                        ? 'Active'
                                                                        : selectedStatusRecurringType === '2'
                                                                            ? 'InActive'
                                                                            : selectedStatusRecurringType === '3'
                                                                                ? 'Stopped'

                                                                                : 'Status'}
                                                        </label>
                                                        <span className="dropdown__icon">
                                                            {isStatusOpenRecurring ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className={`dropdown__options ${isStatusOpenRecurring ? 'dropdown__options--open' : ''}`}>
                                                        <div className="dropdown__option" onClick={() => handleStatusRecurringOptionClick("All")}>All</div>
                                                        <div className="dropdown__option" onClick={() => handleStatusRecurringOptionClick("1")}>Active</div>
                                                        <div className="dropdown__option" onClick={() => handleStatusRecurringOptionClick("2")}>InActive</div>
                                                        <div className="dropdown__option" onClick={() => handleStatusRecurringOptionClick("3")}>Stopped</div>

                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {activeTab === 'tasks' && (
                                        <>
                                            <DragDropContext onDragEnd={handleDragEnd}>
                                                <Droppable droppableId="recurringTaskList">
                                                    {(provided) => (
                                                        <div
                                                            className="col-md-12 table_part mt-2 p-0"
                                                            ref={provided.innerRef}
                                                            {...provided.droppableProps}
                                                        >
                                                            <div className="table-responsive">
                                                                <table className="w-100">
                                                                    <thead className="pb-2">
                                                                        <tr>
                                                                            <th>#Task No</th>
                                                                            <th>Task</th>
                                                                            <th>Type</th>
                                                                            <th>Customer Name</th>
                                                                            <th>Created By</th>
                                                                            <th>Created On</th>
                                                                            <th>Priority</th>
                                                                            <th>Status</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {filteredTasks && filteredTasks.map((individualTask, index) => (
                                                                            <Draggable key={individualTask.id} draggableId={individualTask.id.toString()} index={index}>
                                                                                {(provided) => (
                                                                                    <tr
                                                                                        key={index}
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        onClick={() => navigate(`/admin/task/taskdetail/${individualTask?.id}`)}
                                                                                    >
                                                                                        <td>{individualTask?.task_unique_id}</td>
                                                                                        <td>{individualTask?.name}</td>
                                                                                        <td>{individualTask?.task_type === "1" ? 'Individual' :
                                                                                            individualTask?.task_type === "2" ? 'Business' :
                                                                                                individualTask?.task_type === "3" ? 'Self' : ''}</td>
                                                                                        <td>
                                                                                            {individualTask && individualTask.assignees.length > 0 && (
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                                                                    <label className="d-block ms-2">{individualTask.assignees[0].first_name}</label>
                                                                                                </div>
                                                                                            )}
                                                                                        </td>
                                                                                        <td>
                                                                                            {individualTask?.task_type && (
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                                                                    <label className="d-block ms-2">{individualTask?.task_type === "3" ? individualTask?.client_self?.name : `${individualTask?.client?.first_name} ${individualTask?.client?.last_name}`}</label>
                                                                                                </div>
                                                                                            )}
                                                                                        </td>
                                                                                        <td>{moment(individualTask?.created_at).format('DD MMM, YYYY')}</td>
                                                                                        <td>
                                                                                            <span
                                                                                                style={{
                                                                                                    backgroundColor: convertHexToRGBA(individualTask.priority.color_code, 0.2),
                                                                                                    padding: '3px 10px',
                                                                                                    borderRadius: '8px',
                                                                                                    textAlign: "center",
                                                                                                    width: "fit-content",
                                                                                                    fontSize: "14px",
                                                                                                    color: individualTask.priority.color_code,
                                                                                                }}
                                                                                            >
                                                                                                {individualTask.priority.name}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td><a className="text-blue">{individualTask?.statustask?.name}</a></td>
                                                                                    </tr>
                                                                                )}

                                                                            </Draggable>
                                                                        ))}
                                                                        {provided.placeholder}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                            <ReactPaginate
                                                previousLabel="<"
                                                nextLabel=">"
                                                breakLabel="..."
                                                pageCount={totalPages}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={handlePageClick}
                                                containerClassName="pagination justify-content-end align-items-center mt-2 pe-0"
                                                pageClassName='page-item nowrap'
                                                pageLinkClassName='page-link'
                                                previousClassName='page-item'
                                                previousLinkClassName='page-link'
                                                nextClassName='page-item'
                                                nextLinkClassName='page-link'
                                                breakClassName='page-item'
                                                breakLinkClassName='page-link'
                                                activeClassName='active'
                                            />
                                        </>
                                    )}
                                    {activeTab === 'recurring' && (
                                        <>
                                            <DragDropContext onDragEnd={handleDragEndRecurring}>
                                                <Droppable droppableId="recurringTaskList">
                                                    {(provided) => (
                                                        <div
                                                            className="col-md-12 table_part mt-2 p-0"
                                                            ref={provided.innerRef}
                                                            {...provided.droppableProps}
                                                        >
                                                            <div className="table-responsive">
                                                                <table className="w-100">
                                                                    <thead className="pb-2">
                                                                        <tr>
                                                                            <th>#RTask No</th>
                                                                            <th>Task</th>
                                                                            <th>Type</th>
                                                                            <th>Started On</th>
                                                                            <th>Next Month Date</th>
                                                                            <th>End Date</th>
                                                                            <th>Expires On</th>
                                                                            {/* <th>Priority</th> */}
                                                                            <th>Status</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {Array.isArray(filteredRecurringTasks) &&
                                                                            filteredRecurringTasks.map((recurringTask, recurringIndex) =>
                                                                                recurringTask.tasks.map((task, taskIndex) => (
                                                                                    <Draggable
                                                                                        key={`${recurringTask.id}-${taskIndex}`}
                                                                                        draggableId={`${recurringTask.id}-${taskIndex}`}
                                                                                        index={taskIndex}
                                                                                    >
                                                                                        {(provided) => (
                                                                                            <tr
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                onClick={() =>
                                                                                                    navigate(
                                                                                                        `/admin/task/recurring_task_detail/${task.id}`
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <td>{recurringTask?.reccuring_unique_id}</td>
                                                                                                <td>{task.name}</td>
                                                                                                <td>
                                                                                                    {recurringTask.reccuring_repeat === '1'
                                                                                                        ? 'Weekly'
                                                                                                        : recurringTask.reccuring_repeat === '2'
                                                                                                            ? 'Monthly'
                                                                                                            : recurringTask.reccuring_repeat === '3'
                                                                                                                ? 'Half Yearly'
                                                                                                                : recurringTask.reccuring_repeat === '4'
                                                                                                                    ? 'Yearly'
                                                                                                                    : recurringTask.reccuring_repeat === '5'
                                                                                                                        ? 'Day'
                                                                                                                        : ''}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {moment(recurringTask?.reccuring_start_date).format(
                                                                                                        'DD MMM, YYYY'
                                                                                                    )}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {moment(recurringTask?.reccuring_start_next_date).format(
                                                                                                        'DD MMM, YYYY'
                                                                                                    )}
                                                                                                </td>

                                                                                                <td>
                                                                                                    {recurringTask.reccuring_expire === '1'
                                                                                                        ? '--'
                                                                                                        : recurringTask.reccuring_expire === '0' ? (
                                                                                                            <span>
                                                                                                                {moment(
                                                                                                                    recurringTask?.reccuring_end_date
                                                                                                                ).format('DD MMM, YYYY')}
                                                                                                            </span>
                                                                                                        ) : (
                                                                                                            ''
                                                                                                        )}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {recurringTask.reccuring_expire === '0'
                                                                                                        ? 'Expire'
                                                                                                        : recurringTask.reccuring_expire === '1'
                                                                                                            ? 'Never Expire'
                                                                                                            : ''}
                                                                                                </td>
                                                                                                <td>
                                                                                                    <span
                                                                                                        className="publish"
                                                                                                        style={{
                                                                                                            backgroundColor:
                                                                                                                recurringTask?.reccuring_status == 1
                                                                                                                    ? '#D5E2F6' // light green
                                                                                                                    : recurringTask?.reccuring_status == 2
                                                                                                                        ? '#F1F1F1' // light gray
                                                                                                                        : recurringTask?.reccuring_status == 3
                                                                                                                            ? '#FED6D6' // light red
                                                                                                                            : 'transparent',
                                                                                                            color:
                                                                                                                recurringTask?.reccuring_status == 1
                                                                                                                    ? '#024FC5' // green
                                                                                                                    : recurringTask?.reccuring_status == 2
                                                                                                                        ? '#202529' // black
                                                                                                                        : recurringTask?.reccuring_status == 3
                                                                                                                            ? '#F93333' // red
                                                                                                                            : 'inherit',
                                                                                                        }}
                                                                                                    >
                                                                                                        {recurringTask?.reccuring_status == 1
                                                                                                            ? 'Active'
                                                                                                            : recurringTask?.reccuring_status == 2
                                                                                                                ? 'InActive'
                                                                                                                : recurringTask?.reccuring_status == 3
                                                                                                                    ? 'Stopped'
                                                                                                                    : ''}
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )}
                                                                                    </Draggable>
                                                                                ))
                                                                            )}
                                                                        {provided.placeholder}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                            <ReactPaginate
                                                previousLabel="<"
                                                nextLabel=">"
                                                breakLabel="..."
                                                pageCount={totalRecurringPages}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={handleRecurringPageClick}
                                                containerClassName="pagination justify-content-end align-items-center mt-2 pe-0"
                                                pageClassName="page-item nowrap"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                activeClassName="active"
                                            />
                                        </>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={showSubTask}
                centered
                className="modal operating-modal"
                backdrop="static"
                keyboard={false}
                onHide={closeModal} // Handle closing the modal
            >
                <div className="modal-dialog modal-dialog-centered m-0">
                    <div className="modal-content font-poppins">
                        <div className="modal-header border-0 justify-content-between">
                            <h4 className="fs-20">Add Operating Details</h4>
                            <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                        </div>
                        <div className="modal-body px-4 pb-0">
                            <form>
                                {/* <div className="modal-body p-0">
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label className="fs-18">HST Return</label>
                                        <select
                                            name="hst_return_id"
                                            className="form-input mt-2"

                                        >
                                            <option value="" disabled="disabled" selected>Select</option>

                                            <option value="volvo">Volvo</option>
                                            <option value="saab">Saab</option>
                                            <option value="mercedes">Mercedes</option>
                                            <option value="audi">Audi</option>

                                        </select>

                                    </div>
                                </div> */}
                                <div className="custom-select-container" ref={dropdownRef}>
                                    <div className="custom-select-display" onClick={toggleDropdown}>
                                        {selectedOption ? selectedOption.label : 'Select an option'}
                                    </div>
                                    {isOpen && (
                                        <div className="custom-select-dropdown">
                                            <input
                                                type="text"
                                                value={searchTerm}
                                                onChange={handleSearchChange}
                                                placeholder="Search..."
                                                className="custom-select-search"
                                                onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing when clicking inside input
                                            />
                                            <div className="custom-select-options">
                                                {filteredOptions.length > 0 ? (
                                                    filteredOptions.map(option => (
                                                        <div
                                                            key={option.value}
                                                            className="custom-select-option"
                                                            onClick={() => handleOptionClick(option)} // Close dropdown after selecting
                                                        >
                                                            {option.label}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="custom-select-no-options">No options found</div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="modal-footer border-0 px-0 pb-4 mt-4">
                                    <button type="submit" className="btn-blue">Add</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
            <Add_Individual_Task
                addTaskModal={addTaskModal}
                setAddTaskModal={setAddTaskModal}
                setPriorityList={setPriorityList}
                priorityList={priorityList}
                handlePriorityData={handlePriorityData}
                interactionList={interactionList}
                setInteractionList={setInteractionList}
                handleInteractionData={handleInteractionData}
                StatusList={StatusList}
                setStatusList={setStatusList}
                handleStatusData={handleStatusData}
                assignData={assignData}
                setAssignData={setAssignData}
                handleAssisgnData={handleAssisgnData}
                handleTaskList={handleTaskList}
            />
        </div >
    )
}
export default Add_Main_Task