import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { http } from "../../http/http";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

const Operation_Detail = (props) => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
        unregister, // Importing unregister to remove fields
    } = useForm({ mode: "all", defaultValues: { operations: [] } });
    const formValues = watch();
    const { operationModal, setOperationModal, fetchData } = props;
    const [inputFields, setInputFields] = useState([{ id: 0 }]);

    const handleAddFields = () => {
        const newInputFields = [...inputFields, { id: inputFields[inputFields.length - 1].id + 1 }];
        setInputFields(newInputFields);
    };

    const handleRemoveFields = (id) => {
        if (id === 0) return; // Prevent deleting the field with ID 0
        const newInputFields = inputFields.filter((field) => field.id !== id);
        setInputFields(newInputFields);

        // Unregister the fields when removed
        unregister(`operations[${id}].name`);
        unregister(`operations[${id}].registration_date`);
    };

    const addDependent = () => {
        const operationsData = formValues.operations
            .filter((operation) => operation.name && operation.registration_date) // Filter out empty entries
            .map((operation) => ({
                name: operation.name,
                registration_date: operation.registration_date,
            }));

        // Submit the dependents data to the server
        http(
            {
                method: "POST",
                url: `corporate/operating/${id}/add`,
                body: operationsData,
                isSecure: true,
            },
            (res) => {
                setOperationModal(false);
                fetchData();
                reset(); // Rese
                setInputFields([{ id: 0 }]); // Reset input fieldst the form after successful submission
            },
            (err) => {
                console.error("Error Add Dependent:", err);
            }
        );
    };

    const onHideHandler = () => {
        setOperationModal(false);
        setInputFields([{ id: 0 }]); // Reset the input fields to the initial state
        reset(); // Reset the form
    };

    return (
        <Modal
            show={operationModal}
            onHide={onHideHandler}
            centered
            size="md" 
            contentClassName="mx-auto p-3 h-auto"
            className="modal operating-modal font-poppins" 
            backdrop="static" 
            keyboard={false}
        >
            <div className="modal-dialog modal-dialog-centered m-0">
                <div className="modal-content font-poppins ">
                    <div className="modal-header p-0 justify-content-between align-items-center border-0">
                        <h4 className="fs-20">Add Operating Details</h4>
                        <Modal.Header closeButton className="close-icon border-0 py-0"></Modal.Header>
                    </div>
                        <form onSubmit={handleSubmit(addDependent)}>
                            <div className="modal-body p-0">
                                {inputFields.map((inputField, index) => (
                                    <div className="input_wrap" key={inputField.id}>
                                        <div className="row">
                                            <div className="col-md-12 mt-4 p-0">
                                                <label className="fs-18">Operating Name</label>
                                                <input
                                                    type="text"
                                                    name={`operations[${index}].name`}
                                                    id={`name${inputField.id}`}
                                                    className="form-input mt-2"
                                                    placeholder="Type here..."
                                                    {...register(`operations[${index}].name`, { required: "Required" })}
                                                />
                                                {errors?.operations?.[index]?.name && (
                                                    <span className="error">{errors.operations[index].name.message}</span>
                                                )}
                                            </div>
                                            <div className="col-md-12 mt-4 p-0">
                                                <label className="fs-18">Registration Date</label>
                                                <input
                                                    type="date"
                                                    name={`operations[${index}].registration_date`}
                                                    id={`registration_date${inputField.id}`}
                                                    className="form-input mt-2"
                                                    placeholder="Type here..."
                                                    {...register(`operations[${index}].registration_date`, { required: "Required" })}
                                                />
                                                {errors?.operations?.[index]?.registration_date && (
                                                    <span className="error">{errors.operations[index].registration_date.message}</span>
                                                )}
                                            </div>

                                            <div className="col-lg-8 col-sm-12 mt-4 text-end align-content-center p-0">
                                                {index !== 0 && <button type="button" onClick={() => handleRemoveFields(inputField.id)} className="btn btn-danger mt-4">Remove</button>}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <button type="button" onClick={handleAddFields} className="add_field_button bg-transparent border-0 d-flex align-items-center p-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17.998" viewBox="0 0 18 17.998">
                                        <g id="add" transform="translate(0.002 -0.003)">
                                            <path id="Subtraction_3" data-name="Subtraction 3" d="M9,18a9,9,0,1,1,9-9A9.011,9.011,0,0,1,9,18ZM5.515,8.238a.7.7,0,0,0,0,1.394H8.3V12.42a.7.7,0,1,0,1.394,0V9.632h2.786a.7.7,0,1,0,0-1.394H9.7V5.45a.7.7,0,0,0-1.394,0V8.238Z" transform="translate(-0.002 0.003)" fill="#004fc5" />
                                        </g>
                                    </svg>
                                    <p className="ms-1 text-blue">Add More</p>
                                </button>
                            </div>
                            <div className="modal-footer border-0 px-0 mt-4 mx-0 pb-0">
                                <button type="submit" className="btn-blue m-0">Add</button>
                            </div>
                        </form>
                </div>
            </div>
        </Modal>
    );
};

export default Operation_Detail;
