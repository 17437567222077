import React, { useEffect, useState, useCallback, useContext } from "react";
import { http } from "../../http/http";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputMask from 'react-input-mask';
import { toast } from "react-toastify";
import { debounce } from 'lodash';
import { GlobalContext } from "../../../App";
import moment from 'moment';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Add_Task_Interaction from "./tsidebarTaskModal/Add_Task_Interaction";
import Add_Task_Priority from "./tsidebarTaskModal/Add_Task_Priority";
import Add_Task_Assisgn from "./tsidebarTaskModal/Add_Task_Assisgn";
import Add_Task_Status from "./tsidebarTaskModal/Add_Task_Status";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import JoditEditor from "jodit-react";

const config = {
    readonly: false, // Set to true if you want the editor to be read-only
    useSearch: false,
    spellcheck: false,
    enter: "P",
    defaultMode: "1",
    toolbarAdaptive: false,
    toolbarSticky: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    minHeight: 200,
    maxHeight: 500,
    minWidth: null,
    buttons: "bold,strikethrough,underline,italic,|,font,fontsize,brush,link,|,ul,ol,align,undo,redo",
    placeholder: "",
    controls: {
        fontsize: {
            list: [
                "8", "9", "10", "11", "12", "14", "16", "18", "24", "30", "36", "48", "60", "72", "96", "100"
            ],
        },
        font: {
            command: "fontname",
            list: {
                "": "Default",
                "'Open Sans',sans-serif": "Open Sans",
                "Helvetica,sans-serif": "Helvetica",
                "Arial,Helvetica,sans-serif": "Arial",
                "Georgia,serif": "Georgia",
                "Impact,Charcoal,sans-serif": "Impact",
                "Tahoma,Geneva,sans-serif": "Tahoma",
                "'Times New Roman',Times,serif": "Times New Roman",
                "Verdana,Geneva,sans-serif": "Verdana",
            },
        },
    },
};

const Task_Details = () => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        getValues,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });

    const watchedStaffIds = watch("staff_id", []);
    const navigate = useNavigate();
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const [taskViewData, setTaskViewData] = useState([]);
    const [editTaskModal, setEditTaskModal] = useState(false);
    const [editTaskDetail, setEditTaskDetail] = useState({
        documents: [],
        start_date: '', // Ensure start_date is initialized
        end_date: '',
        start_time: '',
        end_time: '',
        estimation_time: '',
        time_spent: '',
    });
    console.log(editTaskDetail, "editTaskDetail")
    const [deleteImageIDs, setDeleteImageIDs] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [taskDetails, setTaskDetails] = useState({
        interaction_type_id: 0,
        priority_id: 0,
        status_task_id: 0,
    });
    console.log(taskDetails?.interaction_type_id, "taskDetails")
    const [assigneeIds, setAssigneeIds] = useState([]);
    const [showEditAssignOpen, setShowEditAssignOpen] = useState(false);
    const [interactionList, setInteractionList] = useState([]);
    const [priorityList, setPriorityList] = useState([]);
    const [assignData, setAssignData] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [comment, setComment] = useState('');
    const [commentList, setCommentList] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const userID = JSON.parse(localStorage.getItem('__assist_erp'));
    const location = useLocation();
    const { type } = location.state || {};
    const [showInderaction, setShowInderaction] = useState(false);
    const [showPriority, setShowPriority] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [assigned, setAssigned] = useState(false);
    const [showAssignOpen, setShowAssignOpen] = useState(false);
    const [showAssisgnModal, setShowAssignModal] = useState(false);
    const [pendingUpdate, setPendingUpdate] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const initialSelectedOption = interactionList.find(option => option.id === taskDetails.interaction_type_id);
    const initialPrioritySelectedOption = priorityList.find(option => option.id === taskDetails.priority_id);
    const initialStatusSelectedOption = statusList.find(option => option.id === taskDetails.status_task_id);
    const [dropdownState, setDropdownState] = useState({
        isOpen: false,
        search: '',
        selectedOption: initialSelectedOption,
        isPriorityOpen: false,
        prioritySearch: '',
        prioritySelectedOption: initialPrioritySelectedOption || null,
        isStatusOpen: false,
        statusSearch: '',
        statusSelectedOption: initialStatusSelectedOption || null
    });

    const [timeFormats, setTimeFormats] = useState({
        start_time_format: 'AM',
        end_time_format: 'AM',
    });
    const [surveyModels, setSurveyModels] = useState([]);
    const [currentTask, setCurrentTask] = useState(null);
    const [selectedForm, setSelectedForm] = useState(null)
    console.log(selectedForm, "selectedForm")
    const [showCustomFormModal, setShowCustomFormModal] = useState(false);
    const [isFormUpdate, setIsFormUpdate] = useState(false);
    console.log(isFormUpdate, "isFormUpdate")
    const [surveyFormId, setSurveyFormId] = useState(null);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0); // Track the selected tab
    console.log(selectedTabIndex, "selectedTabIndex")
    const [showSurvey, setShowSurvey] = useState(false);
    const [formListing, setFormListing] = useState([])
    console.log(formListing, "formListing")
    const [editForm, setEditForm] = useState(null);
    console.log(editForm, "editForm")






    const handleModalOpen = (taskData) => {
        setCurrentTask(taskData);
        setShowCustomFormModal(true);
    };
    const handleTabSelect = async (index) => {
        setSelectedTabIndex(index);
        setShowSurvey(true);

        const form = currentTask.links[index];

        const formListingItem = formListing.find(item => item.id === form.id);
        console.log(formListingItem, "formListingItem")

        console.log('Selected form:', form);
        console.log('Form ID:', form?.id);

        if (!form?.id) {
            console.error(`No valid form or ID found for index ${index}`);
            return;
        }

        setSurveyFormId(form.id);
        setSelectedForm(form);

        // Parse the form_data structure
        let surveyData = form.form_data[0];
        console.log(surveyData, "surveyData");

        // Fetch data from the API using fetchSurveyDataForEdit
        let apiData = await fetchSurveyDataForEdit(formListingItem);

        if (!apiData) {
            console.error('Failed to fetch survey data from API.');
            return;
        }

        console.log('API Response Data:', apiData);

        const existingData = apiData.data?.data?.data || {};
        console.log("Existing Data Extracted from API Response:", existingData);

        // Check if there is existing data to decide if this form is in "update" mode
        setIsFormUpdate(Object.keys(existingData).length > 0);

        const surveyModel = new Model({
            title: surveyData.title,
            pages: surveyData.pages.map((page) => ({
                ...page,
                elements: page.elements.map((element) => {
                    const elementName = element.name;
                    const existingValue = existingData[elementName];

                    console.log(`Processing element: ${elementName}, Found value: ${existingValue}`);

                    const updatedElement = {
                        ...element,
                        value: existingValue !== undefined ? existingValue : element.value,
                        defaultValue: existingValue !== undefined ? existingValue : element.defaultValue,
                    };

                    console.log("Updated Element:", updatedElement);

                    return updatedElement;
                }),
            })),
        });

        console.log('Created Survey Model:', surveyModel);

        const updatedSurveyModels = [...surveyModels];
        updatedSurveyModels[index] = surveyModel;
        setSurveyModels(updatedSurveyModels);

        console.log('Updated Survey Models:', updatedSurveyModels);
    };

    const handleCompleteSurvey = (sender) => {
        try {
            // Capture the filled survey data
            const surveyData = sender.data;

            // Dynamically retrieve id, title, and module_name
            const moduleName = taskViewData?.links?.[0]?.module_name;

            const url = isFormUpdate
                ? `custom_form/link/update/${surveyFormId}`
                : `custom_form/link/${surveyFormId}/save`;

            http(
                {
                    method: "POST",
                    url: url,
                    isSecure: true,
                    body: {
                        "module_name": moduleName, // Dynamic module name
                        "data": {
                            "data": surveyData // Captured survey data
                        }
                    }
                },
                (res) => {
                    console.log('Survey data saved successfully:', res);
                    if (!isFormUpdate) {
                        // After first save, mark the form as updateable
                        setIsFormUpdate(true);
                    }
                },
                (error) => {
                    console.error('Error saving survey data:', error);
                }
            );
        } catch (error) {
            console.error('Error completing survey:', error);
        }
    };

    const fetchSurveyDataForEdit = async (formListingItem) => {
        try {
            console.log('Fetching data for form ID:', formListingItem?.id); // Debugging: Log the ID being used
            return new Promise((resolve, reject) => {
                http(
                    {
                        method: "GET",
                        url: `custom_form/link/${formListingItem?.id}/edit`,  // Use the id from the formListingItem passed in
                        isSecure: true,
                    },
                    (res) => {
                        console.log(res, "API Response Data");
                        resolve(res?.data);  // Return the nested data object
                        setEditForm(res?.data)
                    },
                    (error) => {
                        console.error('Error fetching survey data for edit:', error);
                        reject(error);
                    }
                );
            });
        } catch (error) {
            console.error('Error fetching survey data for edit:', error);
            return null;
        }
    };

    const formListingData = () => {
        try {
            http(
                {
                    method: "GET",
                    url: `custom_form/listing`,
                    isSecure: true,

                },
                (res) => {
                    setFormListing(res?.data?.data)
                },
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    useEffect(() => {

        formListingData();
    }, []);



    // const handleDropdownOpen = (type) => {
    //     setDropdownState(prevState => ({
    //         ...prevState,
    //         isOpen: type === 'interaction' ? !prevState.isOpen : false,
    //         isPriorityOpen: type === 'priority' ? !prevState.isPriorityOpen : false,
    //         isStatusOpen: type === 'status' ? !prevState.isStatusOpen : false
    //     }));
    // };
    const convertHexToRGBA = (hex, opacity) => {
        if (typeof hex !== 'string' || hex === null || hex.length === 0) {
            console.error('Invalid hex value:', hex);
            throw new TypeError('Hex value must be a non-empty string');
        }

        let r = 0, g = 0, b = 0;

        // 3 digits hex
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        }
        // 6 digits hex
        else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        } else {
            console.error('Invalid hex length:', hex);
            throw new Error('Hex value must be either 4 or 7 characters long');
        }

        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }

    const handleDropdownOpen = (type) => {
        setDropdownState(prevState => ({
            ...prevState,
            isOpen: type === 'interaction' ? !prevState.isOpen : prevState.isOpen,
            isPriorityOpen: type === 'priority' ? !prevState.isPriorityOpen : prevState.isPriorityOpen,
            isStatusOpen: type === 'status' ? !prevState.isStatusOpen : prevState.isStatusOpen
        }));
    };

    useEffect(() => {
        if (initialSelectedOption) {
            setDropdownState(prevState => ({ ...prevState, selectedOption: initialSelectedOption }));
        }
        if (initialPrioritySelectedOption) {
            setDropdownState(prevState => ({ ...prevState, prioritySelectedOption: initialPrioritySelectedOption }));
        }
        if (initialStatusSelectedOption) {
            setDropdownState(prevState => ({ ...prevState, statusSelectedOption: initialStatusSelectedOption }));
        }

    }, [initialSelectedOption, initialPrioritySelectedOption, initialStatusSelectedOption, setDropdownState]);



    const formatTimeAgo = (date) => {
        const now = moment();
        const commentDate = moment(date);
        const diffInMinutes = now.diff(commentDate, 'minutes');
        const diffInHours = now.diff(commentDate, 'hours');
        const diffInDays = now.diff(commentDate, 'days');
        const diffInMonths = now.diff(commentDate, 'months');
        const diffInYears = now.diff(commentDate, 'years');

        if (diffInMinutes < 60) {
            return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
        } else if (diffInHours < 24) {
            const hours = Math.floor(diffInMinutes / 60);
            const minutes = diffInMinutes % 60;
            return `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else if (diffInDays < 30) {
            return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
        } else if (diffInMonths < 12) {
            return `${diffInMonths} month${diffInMonths !== 1 ? 's' : ''} ago`;
        } else {
            return `${diffInYears} year${diffInYears !== 1 ? 's' : ''} ago`;
        }
    };

    const handleFormatChange = (e) => {
        const { name, value } = e.target;
        setTimeFormats({ ...timeFormats, [name]: value });

        if (name === 'start_time_format') {
            updateStartTimeFormat(value);
        }
        if (name === 'end_time_format') {
            updateEndTimeFormat(value);
        }



    };



    const handleInteractionChange = (option) => {
        if (option.id === "add-more") {
            setShowInderaction(true);
            setDropdownState(prevState => ({ ...prevState, selectedOption: null }));
            setValue("interaction_type_id", "0");
        } else {
            setValue("interaction_type_id", option.id);
            setDropdownState(prevState => ({ ...prevState, selectedOption: option }));
            updateIntreactinType(option.id);
        }
    };
    const filteredOptions = interactionList.filter(option =>
        option.name.toLowerCase().includes(dropdownState.search.toLowerCase())
    );
    const handleSelect = (option) => {
        setDropdownState(prevState => ({
            ...prevState,
            selectedOption: option,
            isOpen: false,
            search: ''
        }));
        handleInteractionChange(option);
    };

    /* Add PriorityType Function */

    const handleSelectPriority = (option) => {
        setDropdownState(prevState => ({
            ...prevState,
            prioritySelectedOption: option,
            isPriorityOpen: false,
            prioritySearch: ''
        }));
        handlePriorityChange(option);
    };
    const handlePriorityChange = (option) => {
        if (option.id === "add-more") {
            setShowPriority(true);
            setDropdownState(prevState => ({ ...prevState, prioritySelectedOption: null }));
            setValue("priority_id", "0");
        } else {
            setValue("priority_id", option.id);
            setDropdownState(prevState => ({ ...prevState, prioritySelectedOption: option }));
            updatePriorityType(option.id);
        }
    };

    const filteredOptionsPriority = priorityList.filter(option =>
        option.name.toLowerCase().includes(dropdownState.prioritySearch.toLowerCase())
    );

    /* Add PriorityType Function End */

    /* Add StatusType Function */

    const handleStatusChange = (option) => {
        if (option.id === "add-more") {
            setShowStatus(true);
            // Reset the select to the default value
            setDropdownState(prevState => ({ ...prevState, statusSelectedOption: null }));
            setValue("status_task_id", "0");
        } else {
            setValue("status_task_id", option.id);
            setDropdownState(prevState => ({ ...prevState, statusSelectedOption: option }));
            updateStatusType(option.id)
        }
    };

    const handleSelectStatus = (option) => {
        setDropdownState(prevState => ({
            ...prevState,
            statusSelectedOption: option,
            isStatusOpen: false,
            statusSearch: ''
        }));
        handleStatusChange(option);
    };

    const filteredOptionsStatus = statusList.filter(option =>
        option.name.toLowerCase().includes(dropdownState.statusSearch.toLowerCase())
    );

    /* Add StatusType Function End */

    /* Add Assign Staff Function */

    const displayStaffNames = () => {
        if (selectedStaff.length > 0) {
            return selectedStaff.map(staff => (
                <span
                    key={staff.id}
                    className="mt-2 bg-blue-light assignee_name"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={staff.name}
                >
                    {staff.name ? staff.name.charAt(0).toUpperCase() : ''}
                </span>
            ));
        }
        return (
            <span className="select-box fw-medium" onClick={toggleAssignOpen}>
                Unassigned
            </span>
        );
    };

    useEffect(() => {
        // Pre-select staff if needed
        const preSelectedStaff = assignData.filter(staff => assigneeIds.includes(staff.id));
        setSelectedStaff(preSelectedStaff);
    }, [assigneeIds, assignData]);



    const toggleAssignee = (id) => {
        setAssigneeIds(prevIds => {
            let updatedIds;
            if (prevIds.includes(id)) {
                updatedIds = prevIds.filter(assigneeId => assigneeId !== id);
            } else {
                updatedIds = [...prevIds, id];
            }
            setPendingUpdate(true);
            return updatedIds;
        });
    };

    useEffect(() => {
        if (pendingUpdate) {
            updateStaffType(assigneeIds);
            setPendingUpdate(false);
        }
    }, [assigneeIds, pendingUpdate]);

    const toggleAssignOpen = () => {
        setShowAssignOpen(prevState => !prevState);
    };


    /* Add Assign Staff Function End */




    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setEditTaskDetail((prevDetail) => ({
    //         ...prevDetail,
    //         custom_field: {
    //             ...prevDetail.custom_field,
    //             [name]: value,
    //         },
    //     }));
    //     if (name === 'start_date') {
    //         updateStartTime(value);
    //     }
    // };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditTaskDetail((prevDetail) => {
            const updatedDetail = {
                ...prevDetail,
                custom_field: {
                    ...prevDetail.custom_field,
                    [name]: value,
                },
            };
            if (name === 'start_date') {
                updatedDetail.start_date = value;
            }
            if (name === 'end_date') {
                updatedDetail.end_date = value;
            }
            if (name === 'start_time') {
                updatedDetail.start_time = value;
            }
            if (name === 'end_time') {
                updatedDetail.end_time = value;
            }
            if (name === 'estimation_time') {
                updatedDetail.estimation_time = value;
            }
            if (name === 'time_spent') {
                updatedDetail.time_spent = value;
            }
            return updatedDetail;
        });

        // Debounced API call function
        debouncedApiCall(name, value);
    };

    // Use useCallback to ensure the debounced function is stable
    const debouncedApiCall = useCallback(debounce((name, value) => {
        if (name === 'start_date') {
            updateStartDate(value); // Call API when start_date changes
        }
        if (name === 'end_date') {
            updateEndDate(value); // Call API when end_date changes
        }
        if (name === 'start_time') {
            updateStartTime(value); // Call API when start_time changes
        }
        if (name === 'end_time') {
            updateEndTime(value); // Call API when end_time changes
        }
        if (name === 'estimation_time') {
            updateEstimateTime(value); // Call API when estimation_time changes
        }
        if (name === 'time_spent') {
            updateSpendTime(value); // Call API when time_spent changes
        }
    }, 1000), []);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    };


    const handleTaskView = () => {
        setShowLoader(true);
        try {
            http(
                {
                    method: "GET",
                    url: `client/outertask/${id}/view`,
                    isSecure: true,
                },
                (res) => {
                    setTaskViewData(res?.data);
                    setShowLoader(false);
                },
            )
        }

        catch (error) {
            console.error('Error fetching staff data:', error);
            setShowLoader(false);
        }

    }

    useEffect(() => {
        handleTaskView();
    }, []);

    const handleEditTask = async (id) => {
        try {
            http(
                {
                    method: "GET",
                    url: `client/outertask/${id}/edit`,
                    isSecure: true,
                },
                (res) => {

                    setTaskViewData(res?.data);
                    // setEditTaskModal(true);
                    setEditTaskDetail(res?.data);
                    Object.keys(res?.data).forEach((key) => {
                        setValue(key, res?.data[key]);
                    });
                    setDataLoaded(true); // Mark data as loaded

                },

            );
        } catch (error) {
            console.error('Error fetching task data:', error);
        }
    };

    useEffect(() => {
        if (taskViewData?.id && !dataLoaded) {
            handleEditTask(taskViewData.id,);
        }
    }, [taskViewData, dataLoaded]);


    const oepnEditModal = () => {
        setEditTaskModal(true);
    }



    const deleteImage = async (documentId, isNew = false) => {

        if (isNew) {
            setSelectedFiles(prevFiles => prevFiles.filter((_, index) => index !== documentId));
        } else {
            setDeleteImageIDs(prevState => [...prevState, documentId]);
            setEditTaskDetail(prevState => ({
                ...prevState,
                documents: prevState.documents.filter(doc => doc.id !== documentId)
            }));
        }
    };

    const handleInteractionData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `interaction_type/listing`,
                    isSecure: true,
                },
                (res) => {

                    setInteractionList(res?.data?.data);
                },
            )
        }

        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };
    const handlePriorityData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `priority/listing`,
                    isSecure: true,

                },
                (res) => {
                    setPriorityList(res?.data?.data);

                },
            )
        }

        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };
    const handleAssisgnData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `staff/listing`,
                    isSecure: true,
                },
                (res) => {
                    setAssignData(res?.data?.data);
                },
            )
        }

        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };
    const handleStatusData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `status_task/listing`,
                    isSecure: true,
                },
                (res) => {
                    setStatusList(res?.data?.data);
                },
            )
        }

        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };


    const updateTaskHandle = async () => {
        setShowLoader(true);
        try {
            const formData = new FormData();
            formData.append('name', editTaskDetail?.name);
            formData.append('description', editTaskDetail?.description);
            formData.append('interaction_type_id', taskDetails.interaction_type_id);
            formData.append('priority_id', taskDetails.priority_id);
            formData.append('status_task_id', taskDetails.status_task_id);
            formData.append('start_date', editTaskDetail?.start_date);
            formData.append('start_time', editTaskDetail?.start_time);
            formData.append('start_time_format', timeFormats?.start_time_format);
            formData.append('end_date', editTaskDetail?.end_date);
            formData.append('end_time', editTaskDetail?.end_time);
            formData.append('end_time_format', timeFormats?.end_time_format);
            formData.append('estimation_time', editTaskDetail?.estimation_time);
            formData.append('time_spent', editTaskDetail?.time_spent);
            formData.append('id', editTaskDetail?.id);
            // formData.append('client_id', editTaskDetail?.client_id);
            formData.append('task_type', editTaskDetail?.task_type);
            formData.append('image_delete_ids', deleteImageIDs.join(','));

            // Conditionally append user_id or client_id
            if (editTaskDetail?.task_type === "3") {
                // Self task
                formData.append('user_id', editTaskDetail?.user_id);
            } else {
                // Individual or business task
                formData.append('client_id', editTaskDetail?.client_id);
            }


            assigneeIds.forEach(id => {
                formData.append('staff_id[]', id);
            });

            selectedFiles.forEach(file => {
                formData.append('file[]', file);
                console.log('File:', file);
            });
            // Append custom fields
            Object.keys(editTaskDetail.custom_field || {}).forEach((key) => {
                formData.append(`custom_field[${key}]`, editTaskDetail.custom_field[key]);
            });


            // let endpoint;
            // if (type === "individual") {
            //     endpoint = `client/task/update/${TaskId}/${editTaskDetail?.id}`
            // } else if (type === "business") {
            //     endpoint = `corporate/task/update/${TaskId}/${editTaskDetail?.id}`;
            // } else {
            //     console.error("Unknown type:", type);
            //     return;
            // }

            // console.log("Fetching tasks from endpoint:", endpoint); // Debugging

            http(
                {
                    method: "POST",
                    // url: endpoint,
                    url: `client/outertask/update/${id}`,
                    isSecure: true,
                    body: formData,
                    contentType: "multipart/form-data",
                },
                (res) => {
                    setSelectedFiles([]);
                    setDeleteImageIDs([])
                    setEditTaskModal(false);
                    handleTaskView();
                    setShowLoader(false);
                },
            )
        } catch (error) {
            console.error('Error fetching task data:', error);
            setShowLoader(false);
        }


    };

    const addCommentHandle = (event) => {
        event.preventDefault();
        setShowLoader(true);
        try {
            http(
                {
                    method: "POST",
                    url: `task/${id}/comment/add`,
                    isSecure: true,
                    body: {
                        user_id: userID?.user?.id,
                        comment: comment
                    }
                },
                (res) => {
                    console.log(res, "comment res");
                    setComment("");
                    setShowLoader(false);
                    listCommentHandle();
                },
            )
        }

        catch (error) {
            console.error('Error fetching Comment data:', error);
            setShowLoader(false);
        }
    }



    const listCommentHandle = () => {
        try {
            http(
                {
                    method: "GET",
                    url: `task/${id}/comment`,
                    isSecure: true,

                },
                (res) => {
                    console.log(res);
                    setCommentList(res?.comments);

                },
            )
        }

        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }


    const updateIntreactinType = (interactionTypeId) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_interaction_type/${id}`,
                    isSecure: true,
                    body: {
                        interaction_type_id: interactionTypeId,

                    }
                },
                (res) => {
                    toast.success(res.message);


                },
            )
        }

        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updatePriorityType = (priorityTypeId) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_priority/${id}`,
                    isSecure: true,
                    body: {
                        priority_id: priorityTypeId,

                    }
                },
                (res) => {
                    toast.success(res.message);


                },
            )
        }

        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updateStatusType = (StatusTypeId) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_status/${id}`,
                    isSecure: true,
                    body: {
                        status_task_id: StatusTypeId,

                    }
                },
                (res) => {
                    toast.success(res.message);


                },
            )
        }

        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updateStaffType = (StaffTypeId) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_assignee/${id}`,
                    isSecure: true,
                    body: {
                        staff_id: StaffTypeId,

                    }
                },
                (res) => {
                    toast.success(res.message);


                },
            )
        }

        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updateStartDate = (startDate) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_start_date/${id}`,
                    isSecure: true,
                    body: {
                        start_date: startDate,

                    }
                },
                (res) => {
                    toast.success(res.message);
                },
            )
        }

        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }
    const updateEndDate = (EndDate) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_end_date/${id}`,
                    isSecure: true,
                    body: {
                        end_date: EndDate,

                    }
                },
                (res) => {
                    toast.success(res.message);
                },
            )
        }

        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updateStartTime = (StartTime) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_start_time/${id}`,
                    isSecure: true,
                    body: {
                        start_time: StartTime,

                    }
                },
                (res) => {
                    toast.success(res.message);
                },
            )
        }

        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updateEndTime = (EndTime) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_end_time/${id}`,
                    isSecure: true,
                    body: {
                        end_time: EndTime,

                    }
                },
                (res) => {
                    toast.success(res.message);
                },
            )
        }

        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updateStartTimeFormat = (StartTimeFormat) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_start_time_format/${id}`,
                    isSecure: true,
                    body: {
                        start_time_format: StartTimeFormat,

                    }
                },
                (res) => {
                    toast.success(res.message);
                },
            )
        }

        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }
    const updateEndTimeFormat = (EndTimeFormat) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_end_time_format/${id}`,
                    isSecure: true,
                    body: {
                        end_time_format: EndTimeFormat,

                    }
                },
                (res) => {
                    toast.success(res.message);
                },
            )
        }

        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updateEstimateTime = (EstimateTime) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_estimation_time/${id}`,
                    isSecure: true,
                    body: {
                        estimation_time: EstimateTime,

                    }
                },
                (res) => {
                    toast.success(res.message);
                },
            )
        }

        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    const updateSpendTime = (SpendTime) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_time_spent/${id}`,
                    isSecure: true,
                    body: {
                        time_spent: SpendTime,

                    }
                },
                (res) => {
                    toast.success(res.message);
                },
            )
        }

        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }
    const deleteTask = () => {
        setShowLoader(true);
        try {
            http(
                {
                    method: "DELETE",
                    url: `client/task/${id}/destroy`,
                    isSecure: true,
                },
                (res) => {
                    toast.success(res.message);
                    setShowLoader(false);
                    navigate("/admin/task")
                },
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
            setShowLoader(false);
        }
    }



    useEffect(() => {
        handleInteractionData();
        handlePriorityData();
        handleAssisgnData();
        handleStatusData();
        listCommentHandle();

    }, []);

    useEffect(() => {
        if (editTaskDetail) {
            setTaskDetails(prevTaskDetails => ({
                ...prevTaskDetails,
                interaction_type_id: editTaskDetail?.interactiontype?.id || 0,
                priority_id: editTaskDetail?.priority?.id || 0,
                status_task_id: editTaskDetail?.statustask?.id || 0,
            }));
        }
    }, [editTaskDetail?.interactiontype?.id, editTaskDetail?.priority?.id, editTaskDetail?.statustask?.id]);

    useEffect(() => {
        if (editTaskDetail) {
            setTimeFormats({
                start_time_format: editTaskDetail.start_time_format || 'AM',
                end_time_format: editTaskDetail.end_time_format || 'AM',
            });
        }
    }, [editTaskDetail]);

    useEffect(() => {
        if (editTaskDetail && editTaskDetail.assignees) {
            const ids = editTaskDetail.assignees.map(assignee => assignee.id);
            setAssigneeIds(ids);
        }
    }, [editTaskDetail?.start_time_format, editTaskDetail?.end_time_format]);





    return (
        <div className="right_section font-poppins">
            <div className="tasks-detail-area">
                <div className="row">
                    <div className="col-md-8 mt-4 ps-0">
                        <div className="row align-items-center task-detail-head">
                            <div className="col-sm-12 mt-2 l_dethead">
                                <h2 className="fs-20 fw-bold"> Task Details</h2>
                            </div>
                            <div className="col-md-6 my-3 d-flex align-items-center gap-3">
                                <div className="ttl-name d-flex align-items-center justify-content-center">
                                    <p>{taskViewData?.client_self?.first_name.charAt(0).toUpperCase()}{taskViewData?.client_self?.last_name.charAt(0).toUpperCase()}</p>
                                </div>
                                <div className="ttl-detail">
                                    <h4 className="fs-18 fw-semibold">{taskViewData?.client_self?.first_name} {taskViewData?.client_self?.last_name}</h4>

                                    {
                                        taskViewData?.task_type === "1" ? (
                                            <p className="fs-16 text-secondary">Individual</p>
                                        ) : taskViewData?.task_type === "2" ? (
                                            <p className="fs-16 text-secondary">Business</p>
                                        ) : taskViewData?.task_type === "3" && (
                                            <p className="fs-16 text-secondary">Self</p>
                                        )}
                                </div>
                            </div>
                            <div className="col-md-6 my-3 r_dethead">
                                <ul className="ps-0 d-flex align-items-center gap-2 justify-content-end">
                                    <li onClick={() => handleModalOpen(taskViewData)}>
                                        <span className="file-form"
                                        //  style={style}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12.599" height="13.999" viewBox="0 0 12.599 13.999">
                                                <path id="Path_506" data-name="Path 506" d="M402.367,1279h5.866a3.067,3.067,0,0,1,3.366,3.381v7.231a3.056,3.056,0,0,1-3.366,3.388h-5.866a3.072,3.072,0,0,1-3.367-3.388v-7.231A3.083,3.083,0,0,1,402.367,1279Zm.189,3.262v-.007h2.092a.541.541,0,0,1,.388.161.549.549,0,0,1,0,.777.541.541,0,0,1-.388.161h-2.092a.546.546,0,0,1,0-1.092Zm0,4.256h5.488a.546.546,0,0,0,0-1.092h-5.488a.546.546,0,0,0,0,1.092Zm0,3.2h5.488a.531.531,0,0,0,.35-.182.55.55,0,0,0,0-.735.531.531,0,0,0-.35-.182h-5.488a.5.5,0,0,0-.3.056.5.5,0,0,0-.225.2.56.56,0,0,0,0,.588.541.541,0,0,0,.525.252Z" transform="translate(-399 -1279)" fill="#202529" fillRule="evenodd" />
                                            </svg>
                                            Fill Required Forms
                                        </span>
                                    </li>
                                    <li>
                                        <span className="whitespace-nowrap text-black"
                                        //  style={style}
                                        >
                                            {/* {taskViewData?.priority?.name} */}

                                            {taskViewData.priority && taskViewData.priority.color_code && taskViewData.priority.name && (
                                                <span
                                                    style={{
                                                        backgroundColor: convertHexToRGBA(taskViewData.priority.color_code, 0.2),
                                                        padding: '3px 10px',
                                                        borderRadius: '8px',
                                                        textAlign: "center",
                                                        width: "fit-content",
                                                        fontSize: "14px",
                                                        color: taskViewData.priority.color_code,
                                                    }}
                                                >
                                                    {taskViewData.priority.name}
                                                </span>
                                            )}
                                        </span>
                                    </li>
                                    <li>
                                        <a className="file-edit"
                                            // onClick={() => handleEditTask(taskViewData?.id)}

                                            onClick={() => oepnEditModal()}
                                        >
                                            <img alt="" src={require('../../../assets/images/edit-icon.png')} />
                                        </a>
                                    </li>
                                    <li>
                                        <a className="file-delete" onClick={() => deleteTask()}>
                                            <img alt="" src={require('../../../assets/images/delete-icon.png')} />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 task-detail-area mt-2">
                                <h4 className="fs-18 fw-semibold">{taskViewData?.name} <span className="text-blue fs-14 fw-normal">{taskViewData?.statustask?.name}</span></h4>
                                {/* <p className="fs-14">Created by <b>{taskViewData?.client?.first_name} {taskViewData?.client?.last_name}</b> about 2 days ago</p> */}

                                {taskViewData?.task_type === "1" ? (
                                    <p className="fs-14">
                                        Created by <b>{taskViewData?.client?.first_name} {taskViewData?.client?.last_name}</b> about 2 days ago
                                    </p>
                                ) : taskViewData?.task_type === "2" ? (
                                    <p className="fs-14">
                                        Created by <b>{taskViewData?.client?.first_name} {taskViewData?.client?.last_name}</b> about 2 days ago
                                    </p>
                                ) : taskViewData?.task_type === "3" && (
                                    <p className="fs-14">
                                        Created by <b>{taskViewData?.user?.name}</b> about 2 days ago
                                    </p>
                                )}
                                {/* <p className="fs-18 mt-3 mb-2">{taskViewData?.description}</p> */}
                                <p
                                    className="mt-3 "
                                    dangerouslySetInnerHTML={{
                                        __html: taskViewData?.description,
                                    }}
                                />
                            </div>
                            <div className="d-flex align-items-start gap-3 flex-wrap task-pics mt-3">
                                {
                                    taskViewData && taskViewData?.documents?.map((taskImage) => (
                                        <div className="">
                                            <img alt="" src={taskImage?.document_path} />
                                            {/* <div className="pdf-box">
                                            <svg id="pdf" xmlns="http://www.w3.org/2000/svg" width="40" height="54" viewBox="0 0 40 54">
                                                <g id="Vrstva_x0020_1_15_" transform="translate(0 0)">
                                                    <path id="Path_3537" data-name="Path 3537" d="M106.736,0h17.882l15.155,15.949V46.97a6.989,6.989,0,0,1-6.95,7.03H106.736a6.992,6.992,0,0,1-6.963-7.03V7.03A6.992,6.992,0,0,1,106.736,0Z" transform="translate(-99.773 0)" fill="#e5252a" fill-rule="evenodd" />
                                                    <g id="Group_706" data-name="Group 706" transform="translate(7.721 0)">
                                                        <path id="Path_3538" data-name="Path 3538" d="M467.219,0V15.685H482.4Z" transform="translate(-450.116)" fill="#fff" fill-rule="evenodd" opacity="0.302" />
                                                        <path id="Path_3539" data-name="Path 3539" d="M214.278,455.734v-9.775h4.159a3.447,3.447,0,0,1,2.46.856,3.262,3.262,0,0,1,0,4.547,3.447,3.447,0,0,1-2.46.856h-1.658v3.517Zm2.5-5.643h1.377a1.232,1.232,0,0,0,.869-.268,1.033,1.033,0,0,0,0-1.471,1.232,1.232,0,0,0-.869-.267h-1.377Zm6.071,5.643v-9.775h3.463a6.045,6.045,0,0,1,1.926.294,5.172,5.172,0,0,1,1.631.869,3.839,3.839,0,0,1,1.15,1.524,6.043,6.043,0,0,1,0,4.4,3.838,3.838,0,0,1-1.15,1.524,5.178,5.178,0,0,1-1.631.869,6.051,6.051,0,0,1-1.926.294Zm2.447-2.126h.722a4,4,0,0,0,1.083-.134,3.249,3.249,0,0,0,.923-.441,1.983,1.983,0,0,0,.669-.856,3.8,3.8,0,0,0,0-2.661,1.982,1.982,0,0,0-.669-.856,3.244,3.244,0,0,0-.923-.441,4.008,4.008,0,0,0-1.083-.134H225.3Zm7.395,2.126v-9.775h6.953v2.126h-4.453v1.564h3.557v2.113h-3.557v3.971Z" transform="translate(-214.278 -415.806)" fill="#fff" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div> */}
                                        </div>
                                    ))
                                }
                            </div>

                            <div className="col-md-12">
                                <hr className="task-hr" />
                            </div>
                        </div>
                        <div className="row mt-2 comments-area">
                            <div className="comment-title mb-3">
                                <h1 className="fs-20 fw-bold">Add Comment</h1>
                            </div>
                            <div className="comment-area position-relative">
                                <textarea
                                    name="comment"
                                    cols="3"
                                    rows="3"
                                    className="bg-white p-3"
                                    id="comment"
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                >

                                </textarea>
                                <div className="sendarea" onClick={addCommentHandle}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22.473" height="22.473" viewBox="0 0 22.473 22.473">
                                        <g id="send" transform="translate(0 0)">
                                            <g id="Icon_00000145049271370995700300000003487412756501951400_" transform="translate(0 0)">
                                                <path id="Path_3581" data-name="Path 3581" d="M16.076,6.4,8.66,11.545.9,8.959A1.32,1.32,0,0,1,.915,6.45L20.747.063a1.32,1.32,0,0,1,1.662,1.662L16.022,21.557a1.32,1.32,0,0,1-2.51.013l-2.6-7.794Z" transform="translate(0 0)" fill="#004fc5" fill-rule="evenodd" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2 comments-area mt-3">
                            <div className="comment-title mb-3">
                                <h1 className="fs-20 fw-bold">Comments</h1>
                            </div>
                        </div>
                        {commentList && commentList.length > 0 ? (
                            commentList.map((comment, index) => (
                                <div className="row comment-boxes" key={index}>
                                    <div className="col-md-12 mt-3 d-flex align-items-start gap-3">
                                        <div className="l_comment">
                                            <img alt="" />
                                        </div>
                                        <div className="r_comment">
                                            <h4 className="fs-18 fw-semibold">{comment?.user?.name}</h4>
                                            Commented about {formatTimeAgo(comment.created_at)}
                                            <div className="msg-text mt-1">
                                                <p className="fs-18">
                                                    {comment?.comment}
                                                    {/* {comment.mentions && comment.mentions.map((mention, idx) => (
                                                <span key={idx} className="text-blue">@{mention}</span>
                                            ))}
                                            {comment.links && comment.links.map((link, idx) => (
                                                <span key={idx} className="text-blue">{link}</span>
                                            ))} */}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="row no-comments mt-3">
                                <p className="col-md-12 fs-18 text-secondary">No comments found.</p>
                            </div>
                        )}
                    </div>

                    <div className="col-md-4 overflow-hidden">
                        <div class="right-task-edit overflow-y-scroll">
                            <div className="row mt-4 px-4">
                                <label className="fs-18 px-1">Interaction Type</label>
                                <div className={`select-box t_blue ${dropdownState.isOpen ? 'active' : ''}`} onClick={() => handleDropdownOpen('interaction')}>
                                    {dropdownState.selectedOption ? dropdownState.selectedOption.name : "Select"}
                                </div>
                                {dropdownState.isOpen && (
                                    <div className="options-container pb-2">
                                        <div className="srchinput position-relative">
                                            <input
                                                type="text"
                                                placeholder="Search..."
                                                value={dropdownState.search}
                                                onChange={e => setDropdownState(prevState => ({ ...prevState, search: e.target.value }))}
                                                className="search-field t_blue"
                                            />
                                            <i className="fa fa-close position-absolute pe-3 align-content-center top-0 end-0"
                                                onClick={() => setDropdownState(prevState => ({ ...prevState, isOpen: false }))}
                                            ></i>
                                        </div>
                                        {filteredOptions.map(option => (
                                            <div
                                                key={option.id}
                                                className="option"
                                                onClick={() => handleSelect(option)}

                                            >
                                                {option.name}
                                            </div>
                                        ))}
                                        <div
                                            className="option text-blue text-start d-flex align-items-center gap-1"
                                            onClick={() => {
                                                handleDropdownOpen(null);
                                                setShowInderaction(true);
                                            }}
                                        >
                                            <span className="addplus">+</span> Add More
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="row mt-4 px-4 relative">
                                <label className="fs-18 px-1">Priority</label>

                                <div className={`select-box ${dropdownState.isPriorityOpen ? 'active' : ''}`} onClick={() => handleDropdownOpen('priority')}>
                                    {dropdownState.prioritySelectedOption ? dropdownState.prioritySelectedOption.name : "Select"}
                                </div>
                                {dropdownState.isPriorityOpen && (
                                    <div className="options-container pb-2">
                                        <div className=" srchinput position-relative">
                                            <input
                                                type="text"
                                                placeholder="Search..."
                                                value={dropdownState.prioritySearch}
                                                onChange={e => setDropdownState(prevState => ({ ...prevState, prioritySearch: e.target.value }))}
                                                className="search-field t_blue"
                                            />
                                            <i className="fa fa-close position-absolute pe-3 align-content-center top-0 end-0"
                                                onClick={() => setDropdownState(prevState => ({ ...prevState, isPriorityOpen: false }))}
                                            ></i>
                                        </div>
                                        {filteredOptionsPriority.map(option => (
                                            <div
                                                key={option.id}
                                                className="option d-flex align-items-center justify-content-between gap-2"
                                                onClick={() => handleSelectPriority(option)}

                                            >
                                                {option.name}

                                                <div
                                                    className="color-circle"
                                                    style={{
                                                        backgroundColor: option.color_code,
                                                        width: '8px',
                                                        height: '8px',
                                                        borderRadius: '100%',
                                                        border: option.color_code ? 'none' : '1px solid #ccc'
                                                    }}
                                                ></div>
                                            </div>
                                        ))}
                                        <div
                                            className="option text-blue text-start d-flex align-items-center gap-1"
                                            onClick={() => {
                                                handleDropdownOpen(null);
                                                setShowPriority(true);
                                            }}
                                        >
                                            <span className="addplus">+</span> Add More
                                        </div>

                                    </div>
                                )}
                            </div>
                            <div className="row mt-4 px-4 position-relative">
                                <label className="fs-18 px-1" onClick={toggleAssignOpen}>Assignee</label>
                                <div className="d-flex align-items-center gap-1 flex-wrap select-box">
                                    {displayStaffNames()}
                                </div>
                                {showAssignOpen && (
                                    <div className="options-container px-3 py-2">
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                            placeholder="Search staff..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <ul className="ps-0">
                                            {assignData && assignData
                                                .filter((staff) => staff.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                                .map((staff) => (
                                                    <li key={staff.id} className="d-flex justify-content-between align-items-center my-1">
                                                        <label className="fw-semibold text-black">
                                                            <img src={require("../../../assets/images/user-pic.png")} className="me-2" alt="user-pic" />
                                                            {staff?.name}
                                                        </label>
                                                        <input
                                                            type="checkbox"
                                                            name="staff_id"
                                                            id={`staff_id_${staff.id}`}
                                                            value={staff.id} // Ensure the value is correctly set to staff.id
                                                            checked={assigneeIds.includes(staff.id)}
                                                            onChange={() => toggleAssignee(staff.id)}
                                                        />
                                                    </li>
                                                ))}
                                            <li className="option text-blue d-flex align-items-center gap-1 text-blue mt-1 text-start px-0" onClick={() => setShowAssignModal(true)}>
                                                <span className="addplus">+</span> Add More
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>

                            <div className="row mt-4 px-4 position-relative">
                                <label className="fs-18 px-1">Status</label>
                                <div className={`select-box ${dropdownState.isStatusOpen ? 'active' : ''}`} onClick={() => handleDropdownOpen('status')}>
                                    {dropdownState.statusSelectedOption ? dropdownState.statusSelectedOption.name : "Select"}
                                </div>
                                {dropdownState.isStatusOpen && (
                                    <div className="options-container">
                                        <div className="srchinput position-relative">
                                            <input
                                                type="text"
                                                placeholder="Search..."
                                                value={dropdownState.statusSearch}
                                                onChange={e => setDropdownState(prevState => ({ ...prevState, statusSearch: e.target.value }))}
                                                className="search-field"
                                            />
                                            <i className="fa fa-close position-absolute pe-3 align-content-center top-0 end-0"
                                                onClick={() => setDropdownState(prevState => ({ ...prevState, isStatusOpen: false }))}
                                            ></i>
                                        </div>
                                        {filteredOptionsStatus.map(option => (
                                            <div
                                                key={option.id}
                                                className="option"
                                                onClick={() => handleSelectStatus(option)}
                                            >
                                                {option.name}
                                            </div>
                                        ))}
                                        <div
                                            className="option text-blue text-start d-flex align-items-center gap-1 py-2  mb-2"
                                            onClick={() => {
                                                handleDropdownOpen(null);
                                                setShowStatus(true);
                                            }}
                                        >
                                            <span className="addplus">+</span> Add More
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="row px-4 date mt-4">
                                <h4 className="fs-18 px-1 fw-bold text-black">Start Date</h4>
                                <div className="col-md-7 ps-0 pe-2">
                                    <label>Date</label>
                                    <input
                                        type="date"
                                        name="start_date"
                                        className="form-input mt-1"
                                        // value={editTaskDetail?.start_date} // Ensure this is correctly bound
                                        // onChange={(e) => {
                                        //     setEditTaskDetail({ ...editTaskDetail, start_date: e.target.value });
                                        // }}
                                        value={editTaskDetail.start_date}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-md-3 ps-0 pe-2">
                                    <label>Time</label>
                                    <InputMask
                                        mask="99:99"
                                        maskChar={null}
                                        name="start_time"
                                        placeholder="HH:MM"
                                        className="form-input mt-1"
                                        value={editTaskDetail.start_time}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-md-2 ps-0 pe-1">
                                    <label>Format</label>
                                    <select
                                        className="form-input mt-1 px-3 bg-white"
                                        name="start_time_format"
                                        value={timeFormats.start_time_format}
                                        onChange={handleFormatChange}
                                    >
                                        <option value="PM">PM</option>
                                        <option value="AM">AM</option>
                                    </select>
                                </div>
                                <div className="col-md-12 ps-0 pe-2">
                                    <p className="text-danger text-end fs-14 mt-2">Clear</p>
                                </div>

                            </div>

                            <div class="row px-4 date mt-4">
                                <h4 class="fs-16 px-1 fw-bold text-black">End Date</h4>
                                <div class="col-md-7 ps-0 pe-2">
                                    <label>Date</label>
                                    <input
                                        type="date"
                                        name="end_date"
                                        class="form-input mt-1"
                                        value={editTaskDetail.end_date}
                                        onChange={handleInputChange}

                                    />
                                </div>
                                <div class="col-md-3 ps-0 pe-2">
                                    <label>Time</label>
                                    <InputMask
                                        mask="99:99"
                                        maskChar={null}
                                        name="end_time"
                                        className="form-input mt-1 "
                                        placeholder="HH:MM"
                                        value={editTaskDetail.end_time}
                                        onChange={handleInputChange}

                                    />
                                </div>
                                <div class="col-md-2 ps-0 pe-1">
                                    <label>Format</label>
                                    <select
                                        class="form-input mt-1 px-3 bg-white"
                                        name="end_time_format"
                                        value={timeFormats.end_time_format}
                                        onChange={handleFormatChange}
                                    >
                                        <option value="PM">PM</option>
                                        <option value="AM">AM</option>
                                    </select>
                                </div>
                                <div class="col-md-12 ps-0 pe-2">
                                    <p className="text-danger text-end fs-14 mt-2">Clear</p>
                                </div>
                            </div>
                            <div class="row px-4 date mt-4">
                                <h4 class="fs-16 px-1 fw-bold text-black">Estimation Time</h4>
                                <div class="row py-2 px-0">
                                    <div class="col-md-12 ps-0 pe-2">
                                        <input
                                            type="text"
                                            name="estimation_time"
                                            class="form-input mt-1 bg-white"
                                            placeholder="1w 1d 1h 1m"
                                            value={editTaskDetail.estimation_time}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row px-4 date mt-4">
                                <h4 class="fs-16 px-1 fw-bold text-black">Time Spent</h4>
                                <div class="row py-2 px-0">
                                    <div class="col-md-12 ps-0 pe-2">
                                        <input
                                            type="text"
                                            name="time_spent"
                                            class="form-input mt-1 bg-white"
                                            placeholder="1w 1d 1h 1m"
                                            value={editTaskDetail.time_spent}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={editTaskModal}
                onHide={() => setEditTaskModal(false)}
                centered
                size="md"
                contentClassName="mx-auto"
                className="modal modal-lg email-modal"
                backdrop="static"
                keyboard={false}
            >

                <div className="modal-body p-4 position-relative">
                    <form
                        onSubmit={handleSubmit(updateTaskHandle)}
                    >
                        <div className="row">
                            <div className="col-md-12 left-task-modal p-0">
                                <div className="d-flex align-items-end justify-content-between gap-4">
                                    <div className="w-100">
                                        <h4 className="fs-20">Edit Task</h4>
                                        <input
                                            type="text"
                                            className="w-bold text-black p-0 fs-3 h-0 det-input w-100"
                                            placeholder="Add Details"
                                            name="name"
                                            value={editTaskDetail?.name} // Bind the value from editTaskDetail
                                            onChange={(e) => {
                                                setEditTaskDetail({ ...editTaskDetail, name: e.target.value });
                                            }}
                                        />
                                    </div>
                                    <Modal.Header closeButton className="close-icon border-0 p-0" />
                                </div>
                                <hr />
                                <div className="mt-3">
                                    <div>
                                        {/* <textarea
                                            name="description"
                                            id="description"
                                            cols="30"
                                            rows="15"
                                            placeholder="Type here..."
                                            className="bg-white border-1 border-gray"
                                            value={editTaskDetail?.description} // Bind the value from editTaskDetail
                                            onChange={(e) => {
                                                setEditTaskDetail({ ...editTaskDetail, description: e.target.value });
                                            }}
                                        >
                                        </textarea> */}

                                        <JoditEditor
                                            value={editTaskDetail?.description}
                                            // config={{
                                            //     readonly: false, // Change this to true if you want the editor to be read-only
                                            //     placeholder: 'Type here...',
                                            //     // You can add more Jodit configuration options here
                                            // }}
                                            config={config}
                                            onChange={(newContent) => setEditTaskDetail({ ...editTaskDetail, description: newContent })}
                                        />
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="main-wrapper">
                                        <div className="upload-main-wrapper d-flex align-items-center justify-content-between w-100 border-1 border-dashed gap-2">
                                            <h4 className="fs-14">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="13" height="20" viewBox="0 0 13 20">
                                                    <path id="XMLID_197_" d="M68.356,0h0a4.663,4.663,0,0,0-3.283,1.331,4.469,4.469,0,0,0-1.36,3.214v8.182a2.786,2.786,0,0,0,5.571,0V8.006a.929.929,0,0,0-1.857,0v4.721a.929.929,0,0,1-1.857,0V4.545a2.681,2.681,0,0,1,.816-1.928,2.8,2.8,0,0,1,1.97-.8,2.76,2.76,0,0,1,2.786,2.727v9.091A4.6,4.6,0,0,1,66.5,18.182a4.663,4.663,0,0,1-3.283-1.331,4.468,4.468,0,0,1-1.36-3.214V4.545a.929.929,0,0,0-1.857,0v9.091a6.256,6.256,0,0,0,1.9,4.5A6.528,6.528,0,0,0,66.5,20,6.44,6.44,0,0,0,73,13.636V4.545A4.6,4.6,0,0,0,68.356,0Z" transform="translate(-59.999)" fill="#202529" />
                                                </svg>
                                                Drag &amp; drop here
                                            </h4>
                                            <div className="upload-wrapper">
                                                <input
                                                    type="file"
                                                    id="file"
                                                    name="file"
                                                    multiple
                                                    onChange={handleFileChange}
                                                />
                                                <i className="fa fa-upload me-2"></i>
                                                <span className="file-upload-text" >Browse Files</span>
                                            </div>

                                        </div>

                                        <div className="image-preview-area">
                                            {editTaskDetail?.documents.map((preview, index) => (
                                                <div key={`preview-${preview.id}`} className="image-preview position-relative">
                                                    <img src={preview?.document_path} alt="Preview" style={{ width: 100, height: 100 }} />
                                                    <button className="border-0 bg-transparent" type="button" onClick={() => deleteImage(preview.id)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 36 36">
                                                            <g id="Group_1561" data-name="Group 1561" transform="translate(-1173 -1120)">
                                                                <rect id="Rectangle_245" data-name="Rectangle 245" width="36" height="36" rx="10" transform="translate(1173 1120)" fill="#aaa" />
                                                                <path id="Union_9" data-name="Union 9" d="M1.156,10A1.162,1.162,0,0,1,.343,8.014L3.352,5,.335,1.987A1.163,1.163,0,0,1,1.981.351L4.993,3.363,8.016.34A1.163,1.163,0,0,1,9.659,1.985L6.637,5.007,9.654,8.024A1.16,1.16,0,0,1,8.007,9.66L5,6.648,1.987,9.657A1.158,1.158,0,0,1,1.164,10Z" transform="translate(1186 1133)" fill="#000" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                            ))}

                                            {selectedFiles.map((file, index) => (
                                                <div key={`file-${index}`} className="image-preview position-relative">
                                                    <img src={URL.createObjectURL(file)} alt="Preview" style={{ width: 100, height: 100 }} />
                                                    <button className="border-0 bg-transparent" type="button" onClick={() => deleteImage(index, true)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 36 36">
                                                            <g id="Group_1561" data-name="Group 1561" transform="translate(-1173 -1120)">
                                                                <rect id="Rectangle_245" data-name="Rectangle 245" width="36" height="36" rx="10" transform="translate(1173 1120)" fill="#aaa" />
                                                                <path id="Union_9" data-name="Union 9" d="M1.156,10A1.162,1.162,0,0,1,.343,8.014L3.352,5,.335,1.987A1.163,1.163,0,0,1,1.981.351L4.993,3.363,8.016.34A1.163,1.163,0,0,1,9.659,1.985L6.637,5.007,9.654,8.024A1.16,1.16,0,0,1,8.007,9.66L5,6.648,1.987,9.657A1.158,1.158,0,0,1,1.164,10Z" transform="translate(1186 1133)" fill="#000" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-btns gap-4 mt-4 d-flex align-items-center justify-content-between gap-2">
                                    <div className="view-full">
                                        <button className="view-full">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="15" height="15" viewBox="0 0 15 15">
                                                <path id="expand" d="M5.309.938a.938.938,0,0,1-.937.938h-2.5v2.5A.938.938,0,1,1,0,4.371V.938A.938.938,0,0,1,.938,0H4.371A.938.938,0,0,1,5.309.938ZM4.371,13.125h-2.5v-2.5a.938.938,0,0,0-1.875,0v3.434A.938.938,0,0,0,.938,15H4.371a.938.938,0,0,0,0-1.875Zm9.691-3.434a.938.938,0,0,0-.937.938v2.5h-2.5a.938.938,0,0,0,0,1.875h3.434A.938.938,0,0,0,15,14.063V10.629A.937.937,0,0,0,14.063,9.691Zm0-9.691H10.629a.938.938,0,0,0,0,1.875h2.5v2.5a.938.938,0,0,0,1.875,0V.938A.937.937,0,0,0,14.063,0Z" fill="#77a0df" />
                                            </svg>
                                            View in full page
                                        </button>
                                    </div>
                                    <span className="d-flex gap-2">
                                        <a >
                                            <button className="btn-cancel">Cancel</button>
                                        </a>
                                        <a >
                                            <button className="btn-blue" type="submit" >Update</button>
                                        </a>
                                    </span>
                                </div>
                            </div>
                            {/* <div className="col-md-4 px-0">
                                    <div className=" col-md-12 mt-4 px-4 date mt-3 mb-5">
                                        {editTaskDetail.custom_field && Object.keys(editTaskDetail.custom_field).map((key) => (
                                            <div className="col-md-12 mt-3" key={key}>
                                                <input
                                                    type="text"
                                                    name={key}
                                                    className="form-input mt-1"
                                                    placeholder={key}
                                                    value={editTaskDetail.custom_field[key] || ''}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        ))}
                                    </div>
                            </div> */}
                        </div>
                    </form >
                </div >

            </Modal >



            <Modal
                show={showCustomFormModal}
                onHide={() => setShowCustomFormModal(false)}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal modal-lg email-modal"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-body position-relative">
                    <div className="modal-head d-flex align-items-center justify-content-between gap-3 px-3 pt-3">
                        <h4 className="fs-20 fw-semibold">Fill Required Forms</h4>
                        <svg onClick={() => setShowCustomFormModal(false)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path id="circle-xmark" d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm3.707,14.293a1,1,0,1,1-1.414,1.414L12,13.414,9.707,15.707a1,1,0,0,1-1.414-1.414L10.586,12,8.293,9.707A1,1,0,0,1,9.707,8.293L12,10.586l2.293-2.293a1,1,0,0,1,1.414,1.414L13.414,12l2.293,2.293Z" fill="#212529" />
                        </svg>
                    </div>
                    <form>
                        <div className="tabs-section mt-2">
                            <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
                                <div className="p-2">
                                    <TabList>
                                        {currentTask?.links?.map((form, index) => (
                                            <Tab key={index}>
                                                {form.form_data?.length > 0 && form.form_data[0]?.title
                                                    ? form.form_data[0].title
                                                    : form.title || `Form ${index + 1}`}
                                            </Tab>
                                        ))}
                                    </TabList>
                                </div>


                                <div className="tabs-contentmain pb-3">
                                    {currentTask?.links?.map((form, index) => (
                                        <TabPanel key={index}>
                                            <div className="row p-0">
                                                {selectedForm?.id === form.id && surveyModels[index] && (
                                                    <div className="survey-container">
                                                        <Survey model={surveyModels[index]} onComplete={handleCompleteSurvey} />

                                                    </div>
                                                )}
                                            </div>
                                        </TabPanel>
                                    ))}
                                </div>
                            </Tabs>

                        </div>
                    </form>
                </div >
            </Modal >
            <Add_Task_Interaction
                showInderaction={showInderaction}
                setShowInderaction={setShowInderaction}
                handleInteractionData={handleInteractionData}
            />
            <Add_Task_Priority
                showPriority={showPriority}
                setShowPriority={setShowPriority}
                handlePriorityData={handlePriorityData}
            />
            <Add_Task_Assisgn
                showAssisgnModal={showAssisgnModal}
                setShowAssignModal={setShowAssignModal}
                handleAssisgnData={handleAssisgnData}
            />
            <Add_Task_Status
                showStatus={showStatus}
                setShowStatus={setShowStatus}
                handleStatusData={handleStatusData}
            />

        </div>
    )
}
export default Task_Details;