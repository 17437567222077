import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import { Staff_id, Bookkeeper_id, Assistant_id, Communicator_id } from "../../../helpers/staticList";
import Add_NewStaff from "./Add_NewStaff";
import Add_Assistant from "./Add_Assistant";
import Add_Bookkeeper from "./Add_Bookkeeper";
import Add_Communicator from "./Add_Communicator";

const Assigent_Member = (props) => {
    const { fetchAsiData, assigentModal, setAssigentModal, staffList,
        fetchStaffData, assignedListNew, fetchAssisgnedData, bookeeperList, fetchBookeeperData, communiList, fetchCommuniData } = props;
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const [assignedData, setAssignedData] = useState([]);

    const [isStaffOpen, setIsStaffOpen] = useState(false);
    const [isAssistantOpen, setIsAssistantOpen] = useState(false);
    const [isBookepperOpen, setIsBoookepperOpen] = useState(false);
    const [isCommunicaterOpen, setIsCommunicoterOpen] = useState(false);

    const handleAssigned = () => {


        http(
            {
                method: "POST",
                url: `corporate/assign/${id}/add`,
                isSecure: true,
                body: {
                    staff_id: formValues.staff_id,
                    assistant_id: formValues.assistant_id,
                    bookkeeper_id: formValues.bookkeeper_id,
                    communicator_id: formValues.communicator_id
                }
            },
            (res) => {
                setAssignedData(res?.data?.data);
                toast.success("Add Assigned Member Successfully");
                reset();
                fetchAsiData();
                setAssigentModal(false);
            },
            (err) => {
                // Handle error
            }
        );
    };


    const onHideHandler = () => {

        setAssigentModal(false);
    };


    return (
        <Modal
            show={assigentModal}
            onHide={onHideHandler}
            centered
            contentClassName="mx-auto p-3 h-auto"
            className="modal pin-modal font-poppins" 
            backdrop="static"
            keyboard={false}
        >

            <div className="modal-header p-0 justify-content-between align-items-center border-0">
                <h4 className="fs-20">Add Asssigend Member</h4>
                <Modal.Header closeButton className="close-icon border-0 pt-0 pe-2"></Modal.Header>
            </div>
            <div className="modal-body p-0">
                <form
                    onSubmit={handleSubmit(handleAssigned)}
                >
                    <div className="services-form row">
                        <div className="col-lg-12 mt-3 p-0">
                            <label className="fs-18">Account Manger</label>
                            <select
                                name="staff_id"
                                className="form-input mt-2"
                                id="staff_id"
                                {...register("staff_id", {
                                    required: "Required"
                                })}
                            >
                                <option value="" selected>Select</option>
                                {staffList.map((staff) => (
                                    <option key={staff.id} value={staff.id}>
                                        {staff.name}
                                    </option>
                                ))}
                            </select>
                            {errors.staff_id && <span className="error">{errors.staff_id.message}</span>}
                            <p className="text-blue fw-bold mt-1 text-end w-fit ms-auto cursor-pointer" onClick={() => setIsStaffOpen(true)}>+ Add More</p>
                        </div>
                        <div className="col-lg-12 mt-3 p-0">
                            <label className="fs-18">Assistant</label>
                            <select
                                name="assistant_id"
                                className="form-input mt-2"
                                id="assistant_id" {...register("assistant_id", {
                                    required: "Required"
                                })}
                            >
                                <option value="" selected>Select</option>
                                {assignedListNew.map((assigned) => (
                                    <option key={assigned.id} value={assigned.id}>
                                        {assigned.name}
                                    </option>
                                ))}
                            </select>
                            {errors.assistant_id && <span className="error">{errors.assistant_id.message}</span>}
                            <p className="text-blue fw-bold mt-1 text-end w-fit ms-auto cursor-pointer" onClick={() => setIsAssistantOpen(true)}>+ Add More</p>
                        </div>
                        <div className="col-lg-12 mt-3 p-0">
                            <label className="fs-18">Bookkeeper</label>
                            <select
                                name="bookkeeper_id"
                                className="form-input mt-2"
                                id="bookkeeper_id"
                                {...register("bookkeeper_id", {
                                    required: "Required"
                                })}
                            >
                                <option value="" selected>Select</option>
                                {bookeeperList.map((bookeeper) => (
                                    <option key={bookeeper.id} value={bookeeper.id}>
                                        {bookeeper.name}
                                    </option>
                                ))}
                            </select>
                            {errors.bookkeeper_id && <span className="error">{errors.bookkeeper_id.message}</span>}
                            <p className="text-blue fw-bold mt-1 text-end w-fit ms-auto cursor-pointer" onClick={() => setIsBoookepperOpen(true)}>+ Add More</p>
                        </div>
                        <div className="col-lg-12 mt-3 p-0">
                            <label className="fs-18">Communicator</label>
                            <select
                                name="communicator_id"
                                className="form-input mt-2"
                                id="communicator_id"
                                {...register("communicator_id", {
                                    required: "Required"
                                })}
                            >
                                <option value="" selected>Select</option>
                                {communiList.map((communi) => (
                                    <option key={communi.id} value={communi.id}>
                                        {communi.name}
                                    </option>
                                ))}
                            </select>
                            {errors.communicator_id && <span className="error">{errors.communicator_id.message}</span>}
                            <p className="text-blue fw-bold mt-1 text-end w-fit ms-auto cursor-pointer" onClick={() => setIsCommunicoterOpen(true)}>+ Add More</p>
                        </div>

                        <div className="modal-footer border-0 px-0 pb-0 mt-2">
                            <button className="btn-blue">Add</button>
                        </div>

                    </div>
                </form>
            </div>
            <Add_NewStaff
                isStaffOpen={isStaffOpen}
                setIsStaffOpen={setIsStaffOpen}
                fetchStaffData={fetchStaffData}
            />
            <Add_Assistant
                isAssistantOpen={isAssistantOpen}
                setIsAssistantOpen={setIsAssistantOpen}
                fetchAssisgnedData={fetchAssisgnedData}
            />
            <Add_Bookkeeper
                isBookepperOpen={isBookepperOpen}
                setIsBoookepperOpen={setIsBoookepperOpen}
                fetchBookeeperData={fetchBookeeperData}
            />
            <Add_Communicator
                isCommunicaterOpen={isCommunicaterOpen}
                setIsCommunicoterOpen={setIsCommunicoterOpen}
                fetchCommuniData={fetchCommuniData}
            />
        </Modal>
    )
}
export default Assigent_Member;