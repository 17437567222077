import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../../http/http";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
const TagServices = () => {
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const [openAddServices, setOpenAddServices] = useState(false);
    const [serviceTagList, setServiceTagList] = useState([]);
    const [formList, setFormList] = useState([])
    const [selectedServices, setSelectedServices] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedForm, setSelectedForm] = useState('');  // Empty string as initial value
    const [isIndividual, setIsIndividual] = useState(false);  // Boolean values
    const [isBusiness, setIsBusiness] = useState(false);
    const [tagsList, setTagsList] = useState([]);
    console.log(tagsList?.id, "tagsList")
    const [openViewServices, setOpenViewServices] = useState(false);
    const [openDeleteServices, setOpenDeleteServices] = useState(false);
    const [openAllServices, setOpenAllServices] = useState(false);
    const [serviceToDelete, setServiceToDelete] = useState(null);


    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleCheckboxChange = (service) => {
        if (selectedServices.includes(service)) {
            setSelectedServices(selectedServices.filter((selected) => selected !== service));
        } else {
            setSelectedServices([...selectedServices, service]);
        }
    };

    const isChecked = (service) => {
        return selectedServices.includes(service);
    };


    const removeService = (serviceToRemove) => {
        setSelectedServices(selectedServices.filter((service) => service !== serviceToRemove));
    };



    const handleServiceTagList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `custom_service/listing`,
                    isSecure: true,
                },
                (res) => {
                    console.log(res, "res Service Tags ")
                    setServiceTagList(res?.data?.data);
                },
            )
        } catch (error) {

        }
    };

    const handleFormTagList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `form/listing`,
                    isSecure: true,
                },
                (res) => {
                    console.log(res, "res Service Tags ")
                    setFormList(res?.data?.data);
                },
            )
        } catch (error) {

        }
    };

    const handleTagsList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `form_custom_service_tag/listing`,
                    isSecure: true,
                },
                (res) => {
                    console.log(res, "res Service Tags ")
                    setTagsList(res?.data?.data);
                },
            )
        } catch (error) {

        }
    };

    const handleDeleteServiceTags = async (id) => {
        try {
            http(
                {
                    method: "DELETE",
                    url: `form_custom_service_tag/${id}/delete`,
                    isSecure: true,
                },
                (res) => {
                    console.log(res, "res Service Tags ");
                    setOpenDeleteServices(false);
                    // Remove the deleted service from the tagsList state
                    setTagsList(prevTags => prevTags.filter(service => service.id !== id));
                },
            );
        } catch (error) {
            console.error("Error deleting service tag", error);
        }
    };

    const confirmDeleteService = (id) => {
        setServiceToDelete(id);
        setOpenDeleteServices(true);
    };

    const executeDeleteService = () => {
        if (serviceToDelete) {
            handleDeleteServiceTags(serviceToDelete);
            setServiceToDelete(null);
        }
    };

    const handleTaggedServices = (event) => {
        event.preventDefault(); // Prevent default form submission

        // Safeguard against undefined values
        const selectedServiceIds = selectedServices.map(service => service?.id || 0);  // Using 0 as fallback ID
        let flag = 0;

        // Determine the flag value based on module type selection
        if (isIndividual && isBusiness) {
            flag = 3;
        } else if (isIndividual) {
            flag = 1;
        } else if (isBusiness) {
            flag = 2;
        }

        // Prepare the payload
        const payload = {
            form_id: selectedForm || null,  // Ensure a fallback if form is not selected
            custom_service_id: selectedServiceIds,
            flag: flag
        };

        // Check for any potential undefined values in the payload before sending
        if (!payload.form_id || payload.custom_service_id.length === 0 || payload.flag === 0) {
            console.error("Missing required fields in the payload:", payload);
            return;
        }

        // Send the payload using the http function
        http(
            {
                method: "POST",
                url: `form_custom_service_tag/add`,
                isSecure: true,
                body: payload
            },
            (res) => {
                console.log(res, "res Service Tags submit");
                setOpenAddServices(false)
            }
        );
    };

    useEffect(() => {
        handleServiceTagList();
        handleFormTagList();
        handleTagsList();
    }, []);


    return (
        <div>
            <div className="accord-head d-flex align-items-center justify-content-between px-4 pt-4">
                <span>
                    <h4 className="fs-20 fw-bold">Tag Service</h4>
                    <p className='fs-16 t_blue'>Customization</p>
                </span>
                <button className="add-new" onClick={() => setOpenAddServices(true)}><i className="fa fa-plus me-2"></i>Add New</button>
            </div>

            <div className="accord-table px-4">
                <div className="table-responsive">
                    <table className='w-100'>
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Form Name</th>
                                <th>Tagged Service</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tagsList.length > 0 ? (
                                tagsList.map((service, index) => (
                                    <tr key={service.id}>
                                        <td onClick={() => setOpenViewServices(true)}>{index + 1}</td>
                                        <td onClick={() => setOpenViewServices(true)}>{service?.name}</td> {/* Assuming service has form_name */}
                                        <td className="t_blue fw-medium" onClick={() => setOpenAllServices(true)}>View</td> {/* Assuming service has service_count */}
                                        <td className="d-flex align-items-center gap-2">
                                            <div className="editicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
                                                    <g id="write" transform="translate(-7.998 -8)">
                                                        <path id="Path_3528" data-name="Path 3528" d="M8.3,26.038,8,28.469a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z" transform="translate(0 -7.297)" fill="#202529" />
                                                        <path id="Path_3529" data-name="Path 3529" d="M41.979,9.951,40.472,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.859a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z" transform="translate(-20.425 0)" fill="#202529" />
                                                        <path id="Path_3530" data-name="Path 3530" d="M36.875,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.708A.583.583,0,0,0,36.875,52.583Z" transform="translate(-14.877 -31.168)" fill="#202529" />
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="deticon" onClick={() => confirmDeleteService(service.id)} >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12.598" height="13.998" viewBox="0 0 12.598 13.998">
                                                    <path id="Path_3741" data-name="Path 3741" d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z" transform="translate(-399 -1335)" fill="#202529" fill-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" align="center">No service tags available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* ADD TAG MODAL START */}
            <Modal
                show={openAddServices}
                onHide={() => setOpenAddServices(false)}
                centered
                contentClassName="mx-auto p-4"
                className="modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header border-0 justify-content-between p-0">
                    <h4 className="fs-20">Add Service Tags</h4>
                </div>

                <div className="modal-body p-0">
                    <form onSubmit={handleTaggedServices}>
                        <div className="row mt-2">
                            <div className="col-lg-12 p-0">
                                <div className="input-grp mt-4">
                                    <label htmlFor="formSelect">Form</label>
                                    <select
                                        name="formSelect"
                                        id="formSelect"
                                        className="form-input mt-2"
                                        value={selectedForm}
                                        onChange={(e) => setSelectedForm(e.target.value)}
                                    >
                                        <option value="">Select</option>
                                        {formList.map((form, index) => (
                                            <option key={form.id} value={form.id}>{form.title}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-12 p-0 mt-4">
                                <div className="input-grp">
                                    <label htmlFor="service">Service</label>
                                    <div
                                        className={`form-input mt-2 custom-dropdown ${dropdownOpen ? 'open' : ''}`}
                                        onClick={toggleDropdown}
                                    >
                                        <div className="selected-value d-flex align-items-center gap-4 justify-content-between">
                                            Select
                                            {/* {selectedServices.length > 0
                                                ? selectedServices.map((service) => service.name).join(', ')
                                                : 'Select'} */}
                                            <img className="me-3" alt="" src={require('../../../../assets/images/drop_down.png')} />
                                        </div>
                                        <div className={`selectdrop dropdown-menu shadow-main ${dropdownOpen ? 'show' : ''}`}>
                                            {serviceTagList.map((service, index) => (
                                                <div key={index} className="dropdown-item d-flex align-items-center gap-2">
                                                    <input
                                                        type="checkbox"
                                                        id={`service-${service.id}`}
                                                        checked={isChecked(service)}
                                                        onChange={() => handleCheckboxChange(service)}
                                                    />
                                                    <label htmlFor={`service-${service.id}`}>{service.name}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="selected-services mt-2">
                                        {selectedServices.length > 0 ? (
                                            <ul className="d-flex flex-wrap align-items-center gap-2">
                                                {selectedServices.map((service, index) => (
                                                    <li key={index}>
                                                        {service.name}
                                                        <span
                                                            type="button"
                                                            className="fs-16 ms-2"
                                                            onClick={() => removeService(service)}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12.001" height="12.001" viewBox="0 0 12.001 12.001">
                                                                <path id="Union_10" data-name="Union 10" d="M10462.146,9389a1.14,1.14,0,0,1-.636-.192,1.145,1.145,0,0,1-.487-1.175,1.146,1.146,0,0,1,.315-.587l4.044-4.046-4.044-4.048a1.1,1.1,0,0,1-.249-.371,1.157,1.157,0,0,1,0-.875,1.114,1.114,0,0,1,.249-.371,1.132,1.132,0,0,1,.37-.248,1.138,1.138,0,0,1,.877,0,1.178,1.178,0,0,1,.371.248l4.045,4.047,4.045-4.047a1.149,1.149,0,0,1,.81-.336,1.14,1.14,0,0,1,.808.336,1.114,1.114,0,0,1,.249.371,1.145,1.145,0,0,1,0,.875,1.1,1.1,0,0,1-.249.371l-4.044,4.047,4.045,4.047a1.138,1.138,0,0,1,.249,1.248,1.125,1.125,0,0,1-.422.514,1.144,1.144,0,0,1-.636.192,1.108,1.108,0,0,1-.438-.087,1.175,1.175,0,0,1-.371-.247l-4.045-4.048-4.047,4.048a1.13,1.13,0,0,1-.369.247,1.119,1.119,0,0,1-.436.087Z" transform="translate(-10461.001 -9377)" fill="#a6a7a9" />
                                                            </svg>
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p>No services selected</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 p-0 mt-4">
                                <div className="input-grp">
                                    <label htmlFor="">Module Type</label>
                                    <div className="d-flex align-items-center gap-5">
                                        <div className="mt-2">
                                            <div className="input-grp d-flex align-items-center gap-2">
                                                <input
                                                    type="checkbox"
                                                    id="indv"
                                                    checked={isIndividual}
                                                    onChange={() => setIsIndividual(!isIndividual)}
                                                />
                                                <label htmlFor="indv">Individual</label>
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <div className="input-grp d-flex align-items-center gap-2">
                                                <input
                                                    type="checkbox"
                                                    id="busi"
                                                    checked={isBusiness}
                                                    onChange={() => setIsBusiness(!isBusiness)}
                                                />
                                                <label htmlFor="busi">Business</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 p-0 mt-5 d-flex align-items-center gap-3 justify-content-end">
                                <button type="button" onClick={() => setOpenAddServices(false)} className="btn-cancel">Cancel</button>
                                <button type="submit" className="btn-add">Add</button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            {/* ADD TAG MODAL END */}

            {/* VIEW TAG MODAL START */}
            <Modal
                show={openViewServices}
                onHide={setOpenViewServices}
                centered

                contentClassName="mx-auto p-4"
                className="modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header border-0 justify-content-between p-0">
                    <h4 className="fs-20">Service Tags Details</h4>
                    <div onClick={() => setOpenViewServices(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path id="circle-xmark" d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm3.707,14.293a1,1,0,1,1-1.414,1.414L12,13.414,9.707,15.707a1,1,0,0,1-1.414-1.414L10.586,12,8.293,9.707A1,1,0,0,1,9.707,8.293L12,10.586l2.293-2.293a1,1,0,0,1,1.414,1.414L13.414,12l2.293,2.293Z" fill="#212529" />
                        </svg>
                    </div>
                </div>

                <div className="modal-body p-0">
                    <form //  onSubmit={handleSubmit(addHStOpenHandle)}
                    >
                        <div className="row mt-2">
                            <div className="col-lg-12 p-0 mt-2 d-flex align-items-center justify-content-between flex-wrap gap-2">
                                <h4 className="fs-20 mt-2">Client Onboarding Form</h4>
                                <div className="d-flex align-items-center gap-2 mt-2">
                                    <div className="editicon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
                                            <g id="write" transform="translate(-7.998 -8)">
                                                <path id="Path_3528" data-name="Path 3528" d="M8.3,26.038,8,28.469a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z" transform="translate(0 -7.297)" fill="#202529" />
                                                <path id="Path_3529" data-name="Path 3529" d="M41.979,9.951,40.472,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.859a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z" transform="translate(-20.425 0)" fill="#202529" />
                                                <path id="Path_3530" data-name="Path 3530" d="M36.875,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.708A.583.583,0,0,0,36.875,52.583Z" transform="translate(-14.877 -31.168)" fill="#202529" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="deticon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.598" height="13.998" viewBox="0 0 12.598 13.998">
                                            <path id="Path_3741" data-name="Path 3741" d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z" transform="translate(-399 -1335)" fill="#202529" fill-rule="evenodd" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <hr className="mt-4 mb-0 opacity-25" />
                            <div className="col-lg-12 p-0 mt-4">
                                <div className="input-grp">
                                    <label htmlFor="">Individual</label>
                                    <div className="d-flex align-items-center gap-2 mt-2">
                                        <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Task</span>
                                        <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Invoice</span>
                                        <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Notes</span>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-12 p-0 mt-4">
                                <div className="input-grp">
                                    <label htmlFor="">Business</label>
                                    <div className="d-flex align-items-center gap-2 mt-2">
                                        <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Advance</span>
                                        <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Director</span>
                                        <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Contact</span>
                                        <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Spouse</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 p-0 mt-4">
                                <div className="input-grp">
                                    <label htmlFor="">Others</label>
                                    <div className="d-flex align-items-center gap-2 mt-2">
                                        <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Service</span>
                                        <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Invoice</span>
                                        <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Notes</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </Modal>
            {/* VIEW TAG MODAL END */}

            {/* DELETE TAG MODAL START */}
            <Modal
                show={openDeleteServices}
                onHide={setOpenDeleteServices}
                centered
                contentClassName="mx-auto p-4"
                className="modal font-poppins"
                backdrop="static"
                keyboard={false}
            >

                <div className="modal-body p-0">
                    <form //  onSubmit={handleSubmit(addHStOpenHandle)}
                    >
                        <div className="row">
                            <div className="col-lg-12 p-0 mt-2 text-center">
                                <h4 className="fs-20">Delete Service Tag Form</h4>
                                <p className="mt-2">Are you sure, you want to delete this service tag form?</p>
                            </div>
                            {openDeleteServices && (
                                <div className="col-lg-12 p-0 d-flex align-items-center justify-content-center gap-2 mt-4">
                                    <p>Are you sure you want to delete this service?</p>
                                    <button className="btn-cancel px-4" onClick={executeDeleteService}>Yes, delete</button>
                                    <button className="btn-cancel px-4" onClick={() => setOpenDeleteServices(false)}>Cancel</button>
                                </div>
                            )}
                        </div>

                    </form>
                </div>
            </Modal>
            {/* DELETE TAG MODAL END */}

            {/* ALL SERVICES MODAL START */}
            <Modal
                show={openAllServices}
                onHide={setOpenAllServices}
                centered

                contentClassName="mx-auto p-4"
                className="modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header border-0 justify-content-between p-0">
                    <h4 className="fs-20">Tagged Services</h4>
                    <div onClick={() => setOpenAllServices(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path id="circle-xmark" d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm3.707,14.293a1,1,0,1,1-1.414,1.414L12,13.414,9.707,15.707a1,1,0,0,1-1.414-1.414L10.586,12,8.293,9.707A1,1,0,0,1,9.707,8.293L12,10.586l2.293-2.293a1,1,0,0,1,1.414,1.414L13.414,12l2.293,2.293Z" fill="#212529" />
                        </svg>
                    </div>
                </div>

                <div className="modal-body p-0">
                    <div className="row">
                        <div className="col-lg-12 p-0 mt-4">
                            <div className="input-grp">
                                <label className="text-black fw-regular" htmlFor="">Individual</label>
                                <div className="d-flex align-items-center gap-2 mt-2">
                                    <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Task</span>
                                    <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Invoice</span>
                                    <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Notes</span>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-12 p-0 mt-4">
                            <div className="input-grp">
                                <label className="text-black fw-regular" htmlFor="">Business</label>
                                <div className="d-flex align-items-center gap-2 mt-2">
                                    <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Advance</span>
                                    <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Director</span>
                                    <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Contact</span>
                                    <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Spouse</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 p-0 mt-4">
                            <div className="input-grp">
                                <label className="text-black fw-regular" htmlFor="">Others</label>
                                <div className="d-flex align-items-center gap-2 mt-2">
                                    <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Service</span>
                                    <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Invoice</span>
                                    <span className="px-3 py-1 rounded-2 bg-gray-light d-block fs-14">Notes</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* ALL SERVICES MODAL END */}


        </div>
    )
}
export default TagServices;