import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../http/http";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Add_Assistant = (props) => {
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const { isAssistantOpen, setIsAssistantOpen, fetchAssisgnedData } = props;



    const addAssistantHandle = () => {
        http(
            {
                method: "POST",
                url: "assistant/add",
                isSecure: true,
                body: {
                    first_name: formValues.first_name,
                    middle_name: formValues.middle_name,
                    last_name: formValues.last_name,
                    email: formValues.email,
                    sin: formValues.sin,
                    date_of_birth: formValues.date_of_birth,
                },
            },
            (res) => {
                toast.success("Add Staff Successfully")
                setIsAssistantOpen(false);
                fetchAssisgnedData();
                reset();
            },
            (err) => {

            }
        );
    }

    return (
        <Modal
            show={isAssistantOpen}
            onHide={setIsAssistantOpen}
            centered
            size="xl" 
            contentClassName="mx-auto p-3 h-auto"
            className="modal pin-modal font-poppins" 
            backdrop="static"
            keyboard={false}
        >
            <div className="modal-header py-0 px-2 justify-content-between align-items-center border-0">
                <h4 className="fs-20">Add Assistant</h4>
                <Modal.Header closeButton className="close-icon border-0 pe-2 pb-0"></Modal.Header>
            </div>



            <div className="modal-body px-0 pb-2">
                <form onSubmit={handleSubmit(addAssistantHandle)}>
                    <div className="advance-form row">



                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">First Name</label>
                            <input
                                type="text"
                                name="first_name"
                                id="first_name"
                                className="form-input mt-2"
                                placeholder="Type here..."
                                {...register("first_name", {

                                })}

                            />
                        </div>

                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">Middle Name</label>
                            <input
                                type="text"
                                name="middle_name"
                                id="middle_name"
                                className="form-input mt-2"
                                placeholder="Type here..."
                                {...register("middle_name", {

                                })}

                            />
                        </div>

                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">Last Name</label>
                            <input
                                type="text"
                                name="last_name"
                                id="last_name"
                                className="form-input mt-2"
                                placeholder="Type here..."
                                {...register("last_name", {

                                })}

                            />
                        </div>

                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">Email</label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                className="form-input mt-2"
                                placeholder="Type here..."
                                {...register("email", {

                                })}

                            />
                        </div>


                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">SIN</label>
                            <input
                                type="text"
                                name="sin"
                                id="sin"
                                className="form-input mt-2"
                                placeholder="Type here..."
                                {...register("sin", {

                                })}

                            />
                        </div>



                        <div className="col-lg-4 col-md-12 mt-4">
                            <label className="fs-18">Date of Brith</label>
                            <input
                                type="date"
                                className="form-input mt-2"
                                name="date_of_birth"
                                id="date_of_birth"

                                {...register("date_of_birth", {


                                })}
                            />
                        </div>
                    </div>

                    <div className="modal-footer border-0 mt-2 pb-0">
                        <button type="submit" className="btn-blue">Add</button>
                    </div>
                </form>



            </div>
        </Modal>
    )
}
export default Add_Assistant;