import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { GlobalContext } from "../../App";

const ClientTabs = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [clientId, setClientId] = useState(id);
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    useEffect(() => {
        if (id) {
            localStorage.setItem('clientId', id);
            setClientId(id);
        } else {
            const storedId = localStorage.getItem('clientId');
            if (storedId) {
                setClientId(storedId);
            }
        }
    }, [id]);

    // // Update id in state when a tab is clicked
    // const handleTabClick = (newId) => {
    //     setClientId(newId);
    //     localStorage.setItem('clientId', newId);

    // };

    // Update id in state and show loader when a tab is clicked
    const handleTabClick = (newId, path) => {
        setClientId(newId);
        localStorage.setItem('clientId', newId);
        setShowLoader(true); // Show loader

        navigate(path, {
            state: {
                from: location.pathname
            }
        });
        // Hide loader after navigation
        setTimeout(() => setShowLoader(false), 1000);
    };

    const getTabClassName = (path) => {
        return location.pathname === path ? 'nav-link active' : 'nav-link';
    };
    // const getTabClassName = (basePath) => {
    //     return location.pathname.startsWith(basePath) ? 'nav-link active' : 'nav-link';
    // };

    return (
        <div className="row client-detail-head align-items-center">
            <div className="col-md-12 client-detail-tabs">
                <ul className="nav nav-tabs border-0">
                    <li className="nav-item me-2 rounded mt-2">
                        <button
                            className={getTabClassName(`/admin/client/individual_profile/${clientId}`)}
                            onClick={() => {
                                handleTabClick(clientId);
                                navigate(`/admin/client/individual_profile/${clientId}`);
                            }}
                        >
                            Home
                        </button>


                    </li>
                    <li className="nav-item me-2 mt-2">
                        <button
                            className={getTabClassName('/admin/communication')}
                        // onClick={() => navigate('/admin/communication')}
                        >
                            Communication
                        </button>
                    </li>
                    <li className="nav-item me-2 mt-2">
                        <button
                            className={getTabClassName('/admin/client/note')}
                            onClick={() => navigate('/admin/client/note')}
                        >
                            Notes
                        </button>
                    </li>
                    <li className="nav-item me-2 mt-2">
                        <button
                            className={getTabClassName('/admin/files')}
                        // onClick={() => navigate('/admin/files')}
                        >
                            Files
                        </button>
                    </li>
                    <li className="nav-item me-2 mt-2">
                        <button
                            className={getTabClassName('/admin/client/individual_task')}
                            onClick={() => navigate('/admin/client/individual_task')}
                        >
                            Tasks
                        </button>
                    </li>
                    <li className="nav-item me-2 mt-2">
                        <button
                            className={getTabClassName('/admin/client/Transactions')}
                        // onClick={() => navigate('/admin/client/Transactions')}
                        >
                            Transactions
                        </button>
                    </li>
                </ul>
            </div>
        </div>

    );
};

export default ClientTabs;