import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../http/http";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Add_ArcGrid = (props) => {
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const { isArcGridOpen, setIsArcGridOpen, showArcGridList } = props;



    const addArcGridHandle = () => {
        http(
            {
                method: "POST",
                url: `arc_grid/add`,
                isSecure: true,
                body: {
                    name: formValues.name,
                },
            },
            (res) => {
                toast.success("Add Arc Grid Successfully")
                setIsArcGridOpen(false);
                showArcGridList();

                reset();
            },
            (err) => {

            }
        );
    }

    return (
        <Modal
            show={isArcGridOpen}
            onHide={setIsArcGridOpen}
            centered
            size="md" 
            contentClassName="mx-auto p-3 h-auto"
            className="modal pin-modal font-poppins" 
            backdrop="static" 
            keyboard={false}
        >
            <div className="modal-header p-0 justify-content-between align-items-center border-0">
                <h4 className="fs-20">Add Arc Grid</h4>
                <Modal.Header closeButton className="close-icon border-0 pe-2 pb-0"></Modal.Header>
            </div>

            <div className="services-form row">
                <div className="col-lg-12 mt-3 p-0">
                    <form onSubmit={handleSubmit(addArcGridHandle)}>
                        <div className="modal-footer border-0 justify-content-center p-0">
                        <label htmlFor="name">ARC Grid</label>
                            <input
                                name="name"
                                id="name"
                                className="form-input mx-0 mt-1"
                                type="text"
                                {...register("name", {
                                })}
                            />
                        </div>
                        <button type="submit" className="btn-blue ms-auto mt-3">Add</button>
                    </form>
                </div>
            </div>
        </Modal>
    )
}
export default Add_ArcGrid;