import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { http } from "../../http/http";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Marital_Status, Gender, Residency_Status, Client_Category, Occupation_Category, Refer_by_Client } from "../../../helpers/staticList";
import Add_Contact from "./Add_Contact";
import Spouse from "./Spouse";
import Dependent_Detail from "./Dependent_Detail";
import ClientTabs from "../../common/ClientTabs";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { GlobalContext } from "../../../App";


const IndClientProfile = () => {
    const { id } = useParams();
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const [IndClientProfile, setIndClientProfile] = useState();
    const [editClientProfile, setEditClientProfile] = useState(false);
    const [showcCntactModal, setShowContactModal] = useState(false)
    const [spouseModal, setSpouseModal] = useState(false);
    const [editContactModal, setEditContactModal] = useState(false);
    const [editSpouse, setEditSpouse] = useState(false);
    const [editClientProfileData, setEditClientProfileData] = useState()
    console.log(editClientProfileData, "editClientProfileData")
    const [picture, setPicture] = useState(null);
    const [IdPicture, setIdPicture] = useState(null);
    const [fileType, setFileType] = useState("");
    const [contactList, setContactList] = useState();
    const [page, setPage] = useState(1);
    const [editContactDetail, setEditContactDetail] = useState();
    const [editSpouseDetail, setEditSpouseDeatail] = useState();
    const [spouseList, setSpouseList] = useState();
    const [showDependentModal, setShowDependentModal] = useState(false);
    const [editDepentModal, setEditDepententModal] = useState(false)
    const [dependentList, setDependentList] = useState([]);
    const [editDepentDetail, setEditDepentDetail] = useState(null);
    const [activeAccordionIndex, setActiveAccordionIndex] = useState(null);
    const [currentTask, setCurrentTask] = useState(null);
    const [showCustomFormModal, setShowCustomFormModal] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0); // Track the selected tab
    const [showSurvey, setShowSurvey] = useState(false);
    const [formListing, setFormListing] = useState([])
    const [surveyFormId, setSurveyFormId] = useState(null);
    const [selectedForm, setSelectedForm] = useState(null);
    const [isFormUpdate, setIsFormUpdate] = useState(false);
    const [surveyModels, setSurveyModels] = useState([]);
    const [editForm, setEditForm] = useState(null);
    const { register, handleSubmit, setValue, getValues, watch, formState: { errors }, } = useForm();
    const [address, setAddress] = useState(editClientProfileData?.office_location || '');
    const [addressContect, setAddressContect] = useState(editContactDetail?.address || '');
    const [province, setProvince] = useState(editContactDetail?.province || '');
    const [postal, setPostal] = useState(editContactDetail?.postal_code || '');
    const [addressSpouse, setAddressSpouse] = useState(editSpouseDetail?.address || '');
    const [provinceSpouse, setProvinceSpouse] = useState(editSpouseDetail?.province || '');
    const [postalSpouse, setPostalSpouse] = useState(editSpouseDetail?.postal_code || '');

    const [error, setErrors] = useState({});
    const validateForm = () => {
        const newErrors = {};
        if (!editDepentDetail.first_name) newErrors.first_name = ' required';
        if (!editDepentDetail.last_name) newErrors.last_name = 'required';
        return newErrors;
    };
    // Ensure address is updated when editClientProfileData changes
    useEffect(() => {
        if (editClientProfileData?.office_location) {
            setAddress(editClientProfileData.office_location);
        }
    }, [editClientProfileData]);
    // Ensure address is updated when editClientProfileData changes
    useEffect(() => {
        if (editContactDetail?.address) {
            setAddressContect(editContactDetail.address);
        }
    }, [editContactDetail]);
    useEffect(() => {
        if (editSpouseDetail?.address) {
            setAddressSpouse(editSpouseDetail.address);
        }
    }, [editSpouseDetail]);

    // Update local address state and form state when address input changes
    const handleChangeOfficeAddress = (value) => {
        setAddress(value); // Update local state
        setEditClientProfileData((prevState) => ({
            ...prevState,
            office_location: value, // Update form state
        }));
    };
    const handleChangeContactAddress = (value) => {
        setAddressContect(value); // Update address state on input change
    };
    const handleChangeSpouseAddress = (value) => {
        setAddressSpouse(value); // Update address state on input change
    };


    // Handle address selection from suggestions
    const handleSelect = async (value) => {
        try {
            const results = await geocodeByAddress(value);
            if (results && results[0]) {
                const formattedAddress = results[0].formatted_address;
                setAddress(formattedAddress); // Update local state with full address
                setEditClientProfileData((prevState) => ({
                    ...prevState,
                    office_location: formattedAddress, // Update form state with full address
                }));
            } else {
                console.warn('No results found for the selected address.');
            }
        } catch (error) {
            console.error('Error selecting place:', error?.message || 'No message', error?.stack || 'No stack trace');
        }
    };

    const handleSelectContect = async (value) => {
        setAddressContect(value);

        try {
            const results = await geocodeByAddress(value);
            if (results && results[0]) {
                const addressComponents = results[0].address_components;
                const formattedAddress = results[0].formatted_address;
                let selectedProvince = '';
                let postalCode = '';

                // Extract the province (administrative_area_level_1) and postal code
                addressComponents.forEach((component) => {
                    if (component.types.includes('administrative_area_level_1')) {
                        selectedProvince = component.short_name; // Use `long_name` for full province name
                    }
                    if (component.types.includes('postal_code')) {
                        postalCode = component.short_name; // or long_name if you want the full postal code
                    }
                });

                // Update the form values and state
                setValue('address', formattedAddress);
                setValue('province', selectedProvince);
                setValue('postal_code', postalCode);
                setAddressContect(formattedAddress);
                setProvince(selectedProvince);
                setPostal(postalCode);
            } else {
                console.warn('No results found for the selected address.');
            }
        } catch (error) {
            console.error('Error selecting place:', error?.message || 'No message', error?.stack || 'No stack trace');
        }
    };

    const handleSelectSpouse = async (value) => {
        setAddressSpouse(value);

        try {
            const results = await geocodeByAddress(value);
            if (results && results[0]) {
                const addressComponents = results[0].address_components;
                const formattedAddress = results[0].formatted_address;
                let selectedProvince = '';
                let postalCode = '';

                // Extract the province (administrative_area_level_1) and postal code
                addressComponents.forEach((component) => {
                    if (component.types.includes('administrative_area_level_1')) {
                        selectedProvince = component.short_name; // Use `long_name` for full province name
                    }
                    if (component.types.includes('postal_code')) {
                        postalCode = component.short_name; // or long_name if you want the full postal code
                    }
                });

                // Update the form values and state
                setValue('address', formattedAddress);
                setValue('province', selectedProvince);
                setValue('postal_code', postalCode);
                setProvinceSpouse(formattedAddress);
                setProvinceSpouse(selectedProvince);
                setPostalSpouse(postalCode);
            } else {
                console.warn('No results found for the selected address.');
            }
        } catch (error) {
            console.error('Error selecting place:', error?.message || 'No message', error?.stack || 'No stack trace');
        }
    };

    const handleModalOpen = (taskData) => {
        setCurrentTask(taskData);
        setShowCustomFormModal(true);
    };
    const handleTabSelect = async (index) => {
        setSelectedTabIndex(index);
        setShowSurvey(true);

        const form = currentTask.links[index];

        const formListingItem = formListing.find(item => item.id === form.id);
        console.log(formListingItem, "formListingItem")

        console.log('Selected form:', form);
        console.log('Form ID:', form?.id);

        if (!form?.id) {
            console.error(`No valid form or ID found for index ${index}`);
            return;
        }

        setSurveyFormId(form.id);
        setSelectedForm(form);

        // Parse the form_data structure
        let surveyData = form.form_data[0];
        console.log(surveyData, "surveyData");

        // Fetch data from the API using fetchSurveyDataForEdit
        let apiData = await fetchSurveyDataForEdit(formListingItem);

        if (!apiData) {
            console.error('Failed to fetch survey data from API.');
            return;
        }

        console.log('API Response Data:', apiData);

        const existingData = apiData.data?.data?.data || {};
        console.log("Existing Data Extracted from API Response:", existingData);

        // Check if there is existing data to decide if this form is in "update" mode
        setIsFormUpdate(Object.keys(existingData).length > 0);

        const surveyModel = new Model({
            title: surveyData.title,
            pages: surveyData.pages.map((page) => ({
                ...page,
                elements: page.elements.map((element) => {
                    const elementName = element.name;
                    const existingValue = existingData[elementName];

                    console.log(`Processing element: ${elementName}, Found value: ${existingValue}`);

                    const updatedElement = {
                        ...element,
                        value: existingValue !== undefined ? existingValue : element.value,
                        defaultValue: existingValue !== undefined ? existingValue : element.defaultValue,
                    };

                    console.log("Updated Element:", updatedElement);

                    return updatedElement;
                }),
            })),
        });

        console.log('Created Survey Model:', surveyModel);

        const updatedSurveyModels = [...surveyModels];
        updatedSurveyModels[index] = surveyModel;
        setSurveyModels(updatedSurveyModels);

        console.log('Updated Survey Models:', updatedSurveyModels);
    };
    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if (files) {
            setEditClientProfileData((prevData) => ({
                ...prevData,
                [name]: files[0], // Handle file input
            }));
        } else {
            setEditClientProfileData((prevData) => ({
                ...prevData,
                [name]: value, // Handle text input
            }));

            // Special handling for address input
            if (name === 'office_location') {
                setAddress(value); // Update local state for address
            }
        }
    };

    const onChangePicture = (e) => {
        const file = e.target.files[0];

        if (file) {
            setPicture(file);
            const mimeType = file.type;
            setFileType(mimeType.split("/")[1]); // Get the file extension from MIME type
        } else {
            setPicture(null);
            setFileType("");
        }
    };
    const onChangeIdProof = (e) => {
        const file = e.target.files[0];
        if (file) {
            setIdPicture(file);
        } else {
            setIdPicture(null);
        }
    };


    const handleAccordionClick = (index) => {
        setActiveAccordionIndex(index); // Update state with clicked index
    };



    //// Edit Client Profile Api //////

    // const handleSubmitProfile = async (event) => {
    //     event.preventDefault();
    //     const formData = new FormData();

    //     if (picture) {
    //         formData.append("profile_picture", picture);
    //     }
    //     if (IdPicture) {
    //         formData.append("id_proof", IdPicture);
    //     }
    //     Object.entries(editClientProfileData).forEach(([key, value]) => {
    //         if (key !== "id_proof" && key !== "profile_picture") {
    //             formData.append(key, value);
    //         }
    //     });

    //     try {
    //         const response = await http({
    //             method: "POST",
    //             url: `clients/update/${id}`,
    //             isSecure: true,
    //             contentType: "multipart/form-data",
    //             body: formData,
    //         });
    //         toast.success("Individual client successfully updated");
    //         setEditClientProfile(false);


    //     } catch (error) {
    //         console.error("Error:", error);
    //     }
    // };

    const replaceNullsWithEmptyStrings = (data) => {
        const updatedData = {};
        Object.entries(data).forEach(([key, value]) => {
            updatedData[key] = value === null ? "" : value; // Replace null with empty string
        });
        return updatedData;
    };

    const handleSubmitProfile = async (event) => {
        event.preventDefault();
        const formData = new FormData();

        if (picture) {
            formData.append("profile_picture", picture);
        }
        if (IdPicture) {
            formData.append("id_proof", IdPicture);
        }

        // Process the data to replace null values with empty strings
        const processedProfileData = replaceNullsWithEmptyStrings(editClientProfileData);

        Object.entries(processedProfileData).forEach(([key, value]) => {
            if (key !== "id_proof" && key !== "profile_picture") {
                formData.append(key, value);
            }
        });

        try {
            const response = await http({
                method: "POST",
                url: `clients/update/${id}`,
                isSecure: true,
                contentType: "multipart/form-data",
                body: formData,
            });
            toast.success("Individual client successfully updated");
            setEditClientProfile(false);
        } catch (error) {
            console.error("Error:", error);
        }
    };


    const formListingData = () => {
        try {
            http(
                {
                    method: "GET",
                    url: `custom_form/listing`,
                    isSecure: true,

                },
                (res) => {
                    setFormListing(res?.data?.data)
                },
            )
        }
        catch (error) {
            console.error('Error fetching Comment data:', error);
        }
    }

    useEffect(() => {

        formListingData();
    }, []);

    const handleCompleteSurvey = (sender) => {
        try {
            // Capture the filled survey data
            const surveyData = sender.data;

            // Dynamically retrieve id, title, and module_name
            const moduleName = editClientProfileData?.links?.[0]?.module_name;

            const url = isFormUpdate
                ? `custom_form/link/update/${surveyFormId}`
                : `custom_form/link/${surveyFormId}/save`;

            http(
                {
                    method: "POST",
                    url: url,
                    isSecure: true,
                    body: {
                        "module_name": moduleName, // Dynamic module name
                        "data": {
                            "data": surveyData // Captured survey data
                        }
                    }
                },
                (res) => {
                    console.log('Survey data saved successfully:', res);
                    if (!isFormUpdate) {
                        // After first save, mark the form as updateable
                        setIsFormUpdate(true);
                    }
                },
                (error) => {
                    console.error('Error saving survey data:', error);
                }
            );
        } catch (error) {
            console.error('Error completing survey:', error);
        }
    };


    const fetchSurveyDataForEdit = async (formListingItem) => {
        try {
            console.log('Fetching data for form ID:', formListingItem?.id); // Debugging: Log the ID being used
            return new Promise((resolve, reject) => {
                http(
                    {
                        method: "GET",
                        url: `custom_form/link/${formListingItem?.id}/edit`,  // Use the id from the formListingItem passed in
                        isSecure: true,
                    },
                    (res) => {
                        console.log(res, "API Response Data");
                        resolve(res?.data);  // Return the nested data object
                        setEditForm(res?.data)
                    },
                    (error) => {
                        console.error('Error fetching survey data for edit:', error);
                        reject(error);
                    }
                );
            });
        } catch (error) {
            console.error('Error fetching survey data for edit:', error);
            return null;
        }
    };
    ///// Update Client Contact API Calling ///////////

    const updateContact = (updatedData) => {
        setShowLoader(true);
        http(
            {
                method: "PUT",
                url: `client/contact/update/${editContactDetail?.client_id}/${editContactDetail?.id}`,
                body: updatedData,
                isSecure: true,
            },
            (res) => {
                setEditContactModal(false);
                fetchConData();
                setTimeout(() => {
                    setShowLoader(false);
                }, 500);
                // Optionally, you can perform additional actions after the profile is updated
            },
            (err) => {
                setTimeout(() => {
                    setShowLoader(false);
                }, 500);
                console.error("Error updating profile:", err);
            }
        );
    };
    const onContactSubmit = (formData) => {
        // Call the updateProfile function with the formData
        updateContact(formData);
    };


    ////// Edit Main Clinet Profile Api ///////////////

    useEffect(() => {
        http(
            {
                method: "GET",
                url: `clients/${id}/edit`,
                isSecure: true,
            },
            (res) => {
                setEditClientProfileData(res?.data);
            },
            (err) => { }
        );
    }, [])

    //// Client View Api /////

    useEffect(() => {
        http(
            {
                method: "GET",
                url: `clients/${id}/view`,
                isSecure: true,
            },
            (res) => {
                setIndClientProfile(res?.data, "dtata");
                localStorage.setItem("ClientUserData", JSON.stringify(res?.data?.first_name));
            },
            (err) => { },
        );
    }, []);


    //// Listing Client Contact Api /////////

    const fetchConData = () => {
        http(
            {
                method: "GET",
                url: `client/contact/${id}/listing?page=${page}&limit=${1}`,
                isSecure: true,
            },
            (res) => {
                setContactList(res?.data?.data);
            },
            (err) => { },
        );

    }
    useEffect(() => {
        fetchConData();
    }, [page]);




    ////// Edit Client Contact Api //////   

    const fetchContactData = () => {
        if (contactList && contactList.length > 0) {
            http(
                {
                    method: "GET",
                    url: `client/contact/${contactList[0]?.client_id}/${contactList[0]?.id}/edit`,
                    isSecure: true,
                },
                (res) => {
                    setEditContactDetail(res?.data);
                    Object.keys(res?.data).forEach((key) => {
                        setValue(key, res?.data[key]);
                    });
                },
                (err) => { },
            );
        }
    }

    useEffect(() => {

        fetchContactData();
    }, [contactList]);




    // Client Spouse Listing Api ----->>

    const fetchSpouData = () => {
        http(
            {
                method: "GET",
                url: `client/spouse/${id}/listing?page=${page}&limit=${1}`,
                isSecure: true,
            },
            (res) => {
                setSpouseList(res?.data?.data);
            },
            (err) => { },
        );
    }

    useEffect(() => {

        fetchSpouData();
    }, []);


    // Client  Spouse Edit Api ----->>



    const fetchSpouseData = () => {
        if (spouseList && spouseList.length > 0) {
            http(
                {
                    method: "GET",
                    url: `client/spouse/${spouseList[0]?.client_id}/${spouseList[0]?.id}/edit`,
                    isSecure: true,
                },
                (res) => {
                    setEditSpouseDeatail(res?.data);
                    Object.keys(res?.data).forEach((key) => {
                        setValue(key, res?.data[key]);
                    });
                },
                (err) => { },
            );
        }
    }
    useEffect(() => {
        fetchSpouseData();
    }, [spouseList]);



    ///// Update Client Contact API Calling ///////////

    const updateSposue = (updatedData) => {
        setShowLoader(true);
        http(
            {
                method: "PUT",
                url: `client/spouse/update/${editSpouseDetail?.client_id}/${editSpouseDetail?.id}`,
                body: updatedData,
                isSecure: true,
            },
            (res) => {
                setEditSpouse(false);
                fetchSpouData();
                setTimeout(() => {
                    setShowLoader(false);
                }, 500);
                // Optionally, you can perform additional actions after the profile is updated
            },
            (err) => {
                setTimeout(() => {
                    setShowLoader(false);
                }, 500);
                console.error("Error updating profile:", err);
            }
        );
    };
    const onSpouseSubmit = (formData) => {
        // Call the updateProfile function with the formData
        updateSposue(formData);
    };

    //  Denpendet Listing Api Calling -->

    const fetchData = async () => {

        try {
            http(
                {
                    method: "GET",
                    url: `client/dependent/${id}/listing`,
                    isSecure: true,
                },
                (res) => {
                    setDependentList(res?.data?.data)
                }

            );
        } catch (error) {

        }
    };

    useEffect(() => {
        fetchData(); // Call the function here
    }, [id]);

    /// Edit Depentent Api calling -->
    const editDependentData = async (clientId, dependentId) => {
        try {
            http(
                {
                    method: "GET",
                    url: `client/dependent/${clientId}/${dependentId}/edit`,
                    isSecure: true,
                },
                (res) => {
                    setEditDepentDetail(res?.data); // Set data for edit modal
                    setEditDepententModal(true); // Show the edit modal
                }

            );

        } catch (error) {
            console.error('Error fetching edit operation data:', error);
        }
    };

    const handleEditIconClick = (clientId, dependentId) => {
        editDependentData(clientId, dependentId);
    };

    /// Update Dependent Api Calling -->

    const updateDependtentData = async (clientId, dependentId) => {
        setShowLoader(true);
        const validationErrors = validateForm(); // Validate inputs
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors); // Set errors if validation fails
            return; // Exit if validation fails
        }
        try {
            http({
                method: "PUT", // Assuming you are updating the data with a PUT request
                url: `client/dependent/update/${clientId}/${dependentId}`,
                body: {
                    first_name: editDepentDetail?.first_name, // Assuming showEditOperationData contains the updated name
                    last_name: editDepentDetail?.last_name,
                    middle_name: editDepentDetail?.middle_name,
                    sin: editDepentDetail?.sin,
                    relation: editDepentDetail?.relation,
                    date_of_birth: editDepentDetail?.date_of_birth
                },
                isSecure: true,
            },

                (res) => {
                    hideEditDepentModal();
                    fetchData();
                    setTimeout(() => {
                        setShowLoader(false);
                    }, 500);
                });
        } catch (error) {
            setTimeout(() => {
                setShowLoader(false);
            }, 500);
            console.error('Error updating Dependent data:', error);
        }

    };

    const showClientEditModal = () => {
        setEditClientProfile(true);
    };
    const onHideHandler = () => {
        setEditClientProfile(false);

    };
    const showContactModal = () => {
        setShowContactModal(true);
    };
    const showSpouseModal = () => {
        setSpouseModal(true);
    }

    const showEditContactModal = () => {
        setEditContactModal(true);
    }
    const hideEditContact = () => {
        setEditContactModal(false);
    }

    const showEditSpouse = () => {
        setEditSpouse(true);
    }
    const hideEditSpouse = () => {
        setEditSpouse(false);
    }

    const ShowDependent = () => {
        setShowDependentModal(true);
    }

    // const showEditDepentModal = () => {
    //     setEditDepententModal(true);
    // }
    const hideEditDepentModal = () => {
        setEditDepententModal(false);
    }
    return (
        <>
            <div className="right_section font-poppins">
                <div className="clients-detail-area py-3">
                    <ClientTabs />
                    <div className="row tabs-content">
                        <div className="col-md-12 tab-content p-0">

                            {/* <!-- HOME TAB START --> */}
                            <div className="tab-pane active" id="home">
                                <div className="row mt-2 justify-content-between">

                                    <div className="col-md-6 left_head">
                                        <h4 className="fs-24 mt-3">Profile</h4>
                                    </div>
                                    <div className="col-md-6 mt-2 right_head">
                                        <ul className="ps-0 d-flex align-items-center gap-2 justify-content-end">
                                            <li
                                                onClick={() => handleModalOpen(editClientProfileData)}
                                            >
                                                <span className="file-form"
                                                //  style={style}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.599" height="13.999" viewBox="0 0 12.599 13.999">
                                                        <path id="Path_506" data-name="Path 506" d="M402.367,1279h5.866a3.067,3.067,0,0,1,3.366,3.381v7.231a3.056,3.056,0,0,1-3.366,3.388h-5.866a3.072,3.072,0,0,1-3.367-3.388v-7.231A3.083,3.083,0,0,1,402.367,1279Zm.189,3.262v-.007h2.092a.541.541,0,0,1,.388.161.549.549,0,0,1,0,.777.541.541,0,0,1-.388.161h-2.092a.546.546,0,0,1,0-1.092Zm0,4.256h5.488a.546.546,0,0,0,0-1.092h-5.488a.546.546,0,0,0,0,1.092Zm0,3.2h5.488a.531.531,0,0,0,.35-.182.55.55,0,0,0,0-.735.531.531,0,0,0-.35-.182h-5.488a.5.5,0,0,0-.3.056.5.5,0,0,0-.225.2.56.56,0,0,0,0,.588.541.541,0,0,0,.525.252Z" transform="translate(-399 -1279)" fill="#202529" fillRule="evenodd" />
                                                    </svg>
                                                    Fill Required Forms
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-12 mt-4">
                                        <div className="profile-box position-relative">
                                            <div className="login-box d-flex align-items-center position-absolute top-0 start-0">
                                                <span></span>
                                                <p className="ms-2">Logged in 21 min ago</p>
                                            </div>
                                            <div className="position-absolute start-0 end-0 bottom-0">
                                                <div className="position-relative p-detail-box px-4 py-3 d-flex align-items-center justify-content-between flex-wrap align-items-center">
                                                    <div className="left-prof d-flex align-items-center mt-2">
                                                        <div className="dp-box me-2">
                                                            <img src={require("../../../assets/images/dp.jpg")} alt="" />
                                                        </div>
                                                        <div className="dp-detail">
                                                            <h5>{IndClientProfile?.first_name} {IndClientProfile?.last_name}</h5>
                                                            <span className="d-flex align-items-center">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18.333" viewBox="0 0 20 18.333">
                                                                    <path id="Path_3524" data-name="Path 3524" d="M468.939,1224a5,5,0,0,1,3.576,1.507,5.182,5.182,0,0,1,1.485,3.636v8.046a5.182,5.182,0,0,1-1.485,3.636,5,5,0,0,1-3.576,1.507H459.06a5,5,0,0,1-3.575-1.507A5.183,5.183,0,0,1,454,1237.19v-8.046a5.212,5.212,0,0,1,.385-1.966,5.139,5.139,0,0,1,2.739-2.791,5.056,5.056,0,0,1,1.936-.387Zm1.591,6.661.08-.082a.8.8,0,0,0-.011-1.019.825.825,0,0,0-.529-.265.759.759,0,0,0-.3.041.857.857,0,0,0-.26.163L465,1233.167a1.547,1.547,0,0,1-2,0l-4.5-3.667a.771.771,0,0,0-.512-.153.739.739,0,0,0-.486.224.794.794,0,0,0-.073,1.018l.131.132,4.55,3.616a3.1,3.1,0,0,0,3.909,0Z" transform="translate(-454 -1224)" fill="#fff" fillRule="evenodd" />
                                                                </svg>
                                                                <p className="ms-2">{IndClientProfile?.email}</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <a
                                                        onClick={() => showClientEditModal(true)}
                                                    >
                                                        <div className="write-icon">
                                                            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                                                                <path className="a" d="m0.6 21.5l-0.6 4.8q0 0.4 0.1 0.7 0.1 0.3 0.3 0.5 0.2 0.2 0.5 0.4 0.3 0.1 0.6 0.1 0 0 0 0 0 0 0.1 0 0 0 0 0 0 0 0.1 0l4.8-0.6q0.4 0 0.8-0.2 0.3-0.1 0.7-0.2 0.3-0.2 0.6-0.4 0.4-0.3 0.6-0.5l12.6-12.6q0.1-0.1 0.1-0.2 0.1-0.1 0.1-0.2 0-0.2-0.1-0.3 0-0.1-0.1-0.2l-6.4-6.4q-0.1-0.1-0.2-0.1-0.1-0.1-0.3-0.1-0.1 0-0.2 0.1-0.1 0-0.2 0.1l-12.6 12.6q-0.2 0.3-0.5 0.6-0.2 0.3-0.4 0.6-0.1 0.4-0.2 0.7-0.1 0.4-0.2 0.8z" /><path className="a" d="m27.1 3.9l-3-3q-0.4-0.4-1-0.7-0.5-0.2-1.1-0.2-0.6 0-1.2 0.2-0.6 0.3-1 0.7l-2.8 2.8q-0.1 0.1-0.1 0.2-0.1 0.1-0.1 0.2 0 0.1 0.1 0.3 0 0.1 0.1 0.1l6.5 6.5q0 0.1 0.1 0.1 0.2 0.1 0.3 0.1 0.1 0 0.2-0.1 0.1 0 0.2-0.1l2.8-2.8q0.4-0.4 0.7-1 0.2-0.6 0.2-1.2 0-0.6-0.2-1.1-0.3-0.6-0.7-1z" /><path className="a" d="m28 26.8q0-0.2-0.1-0.4-0.1-0.2-0.2-0.4-0.2-0.2-0.4-0.2-0.2-0.1-0.5-0.1h-13.4c-0.3 0-0.6 0.1-0.8 0.3-0.2 0.2-0.3 0.5-0.3 0.8 0 0.3 0.1 0.6 0.3 0.8 0.2 0.3 0.5 0.4 0.8 0.4h13.4q0.3 0 0.5-0.1 0.2-0.1 0.4-0.2 0.1-0.2 0.2-0.4 0.1-0.2 0.1-0.5z" /></svg>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                        <div className="prof-inside p-3 bg-white shadow-lg rounded-5">
                                            <div className="row about-info mt-2">
                                                <div className="col-md-12 d-flex align-items-center justify-content-between">
                                                    <h6 className="fs-20">About</h6>
                                                </div>
                                                <div className="col-md-6 about-info-box mt-2">
                                                    <p className="fs-14">SIN</p>
                                                    <h4 className="fs-18">{IndClientProfile?.sin}</h4>
                                                </div>
                                                <div className="col-md-6 about-info-box mt-2">
                                                    <p className="fs-14">Occupation Type</p>
                                                    <h4 className="fs-18">{Occupation_Category.getName(IndClientProfile?.occupation_category)}</h4>
                                                </div>
                                                <div className="col-md-6 about-info-box mt-2">
                                                    <p className="fs-14">Client Since</p>
                                                    <h4 className="fs-18"> {moment(IndClientProfile?.date_of_landing).format('DD MMM, YYYY')}</h4>

                                                </div>
                                                <div className="col-md-6 about-info-box mt-2">
                                                    <p className="fs-14">Client Type</p>
                                                    <h4 className="fs-18">Client</h4>
                                                </div>
                                                <div className="col-md-6 about-info-box mt-2">
                                                    <p className="fs-14">Client Category</p>
                                                    <h4 className="fs-18">{Client_Category.getName(IndClientProfile?.client_category)}</h4>
                                                </div>
                                                <div className="col-md-6 about-info-box mt-2">
                                                    <p className="fs-14">Residence Status</p>
                                                    <h4 className="fs-18">{Residency_Status.getName(IndClientProfile?.residency_status)}</h4>
                                                </div>
                                                <div className="col-md-6 about-info-box mt-2">
                                                    <p className="fs-14">Date of Birth</p>
                                                    <h4 className="fs-18">{moment(IndClientProfile?.date_of_birth).format('DD MMM, YYYY')}</h4>
                                                </div>
                                                <div className="col-md-6 about-info-box mt-2">
                                                    <p className="fs-14">Marital Status</p>
                                                    <h4 className="fs-18">{Marital_Status.getName(IndClientProfile?.marital_status)}</h4>
                                                </div>
                                                <div className="col-md-6 about-info-box mt-2">
                                                    <p className="fs-14">Family Head</p>
                                                    <h4 className="fs-18">{IndClientProfile?.family_head}</h4>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                        <div className="prof-inside p-3 bg-white shadow-lg rounded-5">
                                            <div className="row task-info mt-2">
                                                <div className="col-md-12 d-flex align-items-center justify-content-between">
                                                    <h6 className="fs-20">Tasks</h6>
                                                    <p className="text-muted">View All</p>
                                                </div>
                                                <div className="col-md-6 task-info-box mt-2">
                                                    <h4 className="fs-18">Submit return for Client e-signature.</h4>
                                                    <p className="fs-14">3 Mar, 2023</p>
                                                </div>
                                                <div className="col-md-6 task-info-box align-content-center mt-2">
                                                    <span className="status-review">Need Review</span>
                                                </div>
                                                <hr />
                                                <div className="col-md-6 task-info-box mt-2">
                                                    <h4 className="fs-18">Client Request</h4>
                                                    <p className="fs-14">3 Mar, 2023</p>
                                                </div>
                                                <div className="col-md-6 task-info-box align-content-center">
                                                    <span className="status-client">With Client</span>
                                                </div>
                                                <hr />

                                                <div className="col-md-6 task-info-box mt-2">
                                                    <h4 className="fs-18">File This Out</h4>
                                                    <p className="fs-14">3 Mar, 2023</p>
                                                </div>
                                                <div className="col-md-6 task-info-box align-content-center">
                                                    <span className="status-review">Need Review</span>
                                                </div>
                                                <hr />

                                                <div className="col-md-6 task-info-box mt-2">
                                                    <h4 className="fs-18">Invoice Sent & Paid</h4>
                                                    <p className="fs-14">3 Mar, 2023</p>
                                                </div>
                                                <div className="col-md-6 task-info-box align-content-center">
                                                    <span className="status-client">With Client</span>
                                                </div>
                                                <hr />

                                                <div className="col-md-6 task-info-box mt-2">
                                                    <h4 className="fs-18">Prepare Return</h4>
                                                    <p className="fs-14">3 Mar, 2023</p>
                                                </div>
                                                <div className="col-md-6 task-info-box align-content-center">
                                                    <span className="nostatus">NO Status</span>
                                                </div>
                                                <hr />

                                                <div className="col-md-6 task-info-box mt-2">
                                                    <h4 className="fs-18">Submit return for Client e-signature.</h4>
                                                    <p className="fs-14">3 Mar, 2023</p>
                                                </div>
                                                <div className="col-md-6 task-info-box align-content-center">
                                                    <span className="nostatus">NO Status</span>
                                                </div>
                                                <hr />

                                                <div className="col-md-6 task-info-box mt-2">
                                                    <h4 className="fs-18">Invoice Sent & Paid</h4>
                                                    <p className="fs-14">3 Mar, 2023</p>
                                                </div>
                                                <div className="col-md-6 task-info-box align-content-center">
                                                    <span className="status-review">Need Review</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12 mt-4">
                                        <div className="prof-inside p-3 bg-white shadow-lg rounded-5">
                                            <div className="row notes-box mt-2">
                                                <div className="col-md-12 -flex align-items-center justify-content-between">
                                                    <h6 className="fs-20">Notes</h6>
                                                    <img src={require("../../../assets/images/plus-icon.svg")} alt="" />
                                                </div>
                                                <div className="col-md-12 notes-info-box mt-2 d-flex align-items-start">
                                                    <span></span>
                                                    <div className="note-content ms-2">
                                                        <h4 className="fs-18">Missing w2</h4>
                                                        <p className="fs-14">Still waiting on Clients w2</p>
                                                    </div>
                                                </div>
                                                <hr />

                                                <div className="col-md-12 notes-info-box mt-2 d-flex align-items-start">
                                                    <span></span>
                                                    <div className="note-content ms-2">
                                                        <h4 className="fs-18">Client Call</h4>
                                                        <p className="fs-14">Client called about unpaid quarterly taxes for q2 of 2023.</p>
                                                    </div>
                                                </div>
                                                <hr />

                                                <div className="col-md-12 notes-info-box mt-2 d-flex align-items-start">
                                                    <span></span>
                                                    <div className="note-content ms-2">
                                                        <h4 className="fs-18">Missing Document</h4>
                                                        <p className="fs-14">w2</p>
                                                    </div>
                                                </div>
                                                <hr />

                                                <div className="col-md-12 notes-info-box mt-2 d-flex align-items-start">
                                                    <span></span>
                                                    <div className="note-content ms-2">
                                                        <h4 className="fs-18">Missing w2</h4>
                                                        <p className="fs-14">Still waiting on Clients w2</p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="col-md-12 notes-info-box mt-2 d-flex align-items-start">
                                                    <span></span>
                                                    <div className="note-content ms-2">
                                                        <h4 className="fs-18">Client Call</h4>
                                                        <p className="fs-14">Client called about unpaid quarterly taxes for q2 of 2023.</p>
                                                    </div>
                                                </div>
                                                <hr />

                                                <div className="col-md-12 notes-info-box mt-2 d-flex align-items-start">
                                                    <span></span>
                                                    <div className="note-content ms-2">
                                                        <h4 className="fs-18">Missing Document</h4>
                                                        <p className="fs-14">w2</p>
                                                    </div>
                                                </div>
                                                <hr />

                                                <div className="col-md-12 notes-info-box mt-2 d-flex align-items-start">
                                                    <span></span>
                                                    <div className="note-content ms-2">
                                                        <h4 className="fs-18">Missing w2</h4>
                                                        <p className="fs-14">Still waiting on Clients w2</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4 imp-clients">
                                        <div className="prof-inside p-3 bg-white shadow-lg rounded-5">
                                            <div className="row">
                                                <div className="col-md-12 mt-2">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h6 className="fs-20">Important Clients</h6>
                                                        <a href="#"><img src={require("../../../assets/images/plus-icon.svg")} alt="" /></a>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-2">
                                                    <ul className="mt-1 ps-0">
                                                        <li className="d-flex align-items-center justify-content-between flex-wrap mt-3">
                                                            <div className="left-cl d-flex align-items-center gap-2 mt-1">
                                                                <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                                <div className="mt-2">
                                                                    <h4 className="fs-18">Turner Homenick</h4>
                                                                    <p className="fs-14">Owner</p>
                                                                </div>
                                                            </div>
                                                            <div className="right-cl mt-1">
                                                                <img src={require("../../../assets/images/drop-arrow.svg")} alt="" />
                                                                <img src={require("../../../assets/images/three-icon.svg")} alt="" />
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-center justify-content-between flex-wrap mt-3">
                                                            <div className="left-cl d-flex align-items-center gap-2 mt-1">
                                                                <img src="assets/images/user-pic.png" alt="" />
                                                                <div className="mt-2">
                                                                    <h4 className="fs-18">Turner Homenick</h4>
                                                                    <p className="fs-14">Owner</p>
                                                                </div>
                                                            </div>
                                                            <div className="right-cl mt-1">
                                                                <img src={require("../../../assets/images/drop-arrow.svg")} alt="" />
                                                                <img src={require("../../../assets/images/three-icon.svg")} alt="" />
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-center justify-content-between flex-wrap mt-3">
                                                            <div className="left-cl d-flex align-items-center gap-2 mt-1">
                                                                <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                                <div className="mt-2">
                                                                    <h4 className="fs-18">Turner Homenick</h4>
                                                                    <p className="fs-14">Owner</p>
                                                                </div>
                                                            </div>
                                                            <div className="right-cl mt-1">
                                                                <img src={require("../../../assets/images/drop-arrow.svg")} alt="" />
                                                                <img src={require("../../../assets/images/three-icon.svg")} alt="" />
                                                            </div>
                                                        </li>
                                                        <li className="d-flex align-items-center justify-content-between flex-wrap mt-3">
                                                            <div className="left-cl d-flex align-items-center gap-2 mt-1">
                                                                <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                                <div className="mt-2">
                                                                    <h4 className="fs-18">Turner Homenick</h4>
                                                                    <p className="fs-14">Owner</p>
                                                                </div>
                                                            </div>
                                                            <div className="right-cl mt-1">
                                                                <img src={require("../../../assets/images/drop-arrow.svg")} alt="" />
                                                                <img src={require("../../../assets/images/three-icon.svg")} alt="" />
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4 imp-clients">
                                        <div className="row">
                                            <div className="col-md-12 p-0">
                                                <div className="prof-inside p-4 bg-white shadow-lg rounded-5 important-box">
                                                    <h6 className="fs-20">Important Clients</h6>
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div className="left-in d-flex align-items-center">
                                                            <img src={require("../../../assets/images/qb.svg")} alt="" />
                                                            <h4 className="fs-18 ms-2">QuickBooks Online</h4>
                                                        </div>
                                                        <span className="status-access">Access</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-3 p-0">
                                                <div className="prof-inside p-4 bg-white shadow-lg rounded-5 linked-account">
                                                    <h6 className="fs-20">Linked Clients</h6>
                                                    <div className="row mt-3">
                                                        <div className="col-md-12 notes-info-box d-flex align-items-start">
                                                            <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                            <div className="note-content ms-2 mt-1">
                                                                <h4 className="fs-18">Missing w2</h4>
                                                                <p className="fs-14">Still waiting on Clients w2</p>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="col-md-12 notes-info-box d-flex align-items-start">
                                                            <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                            <div className="note-content ms-2 mt-1">
                                                                <h4 className="fs-18">Missing w2</h4>
                                                                <p className="fs-14">Still waiting on Clients w2</p>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="col-md-12 notes-info-box d-flex align-items-start">
                                                            <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                            <div className="note-content ms-2 mt-1">
                                                                <h4 className="fs-18">Missing w2</h4>
                                                                <p className="fs-14">Still waiting on Clients w2</p>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="col-md-12 notes-info-box d-flex align-items-start">
                                                            <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                            <div className="note-content ms-2 mt-1">
                                                                <h4 className="fs-18">Missing w2</h4>
                                                                <p className="fs-14">Still waiting on Clients w2</p>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="col-md-12 notes-info-box d-flex align-items-start">
                                                            <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                            <div className="note-content ms-2 mt-1">
                                                                <h4 className="fs-18">Missing w2</h4>
                                                                <p className="fs-14">Still waiting on Clients w2</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-3 p-0">
                                                <div className="prof-inside p-4 bg-white shadow-lg rounded-5 tags">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h6 className="fs-20">Tags</h6>
                                                        <a href="#"><img src={require("../../../assets/images/user-pic.png")} alt="" /></a>
                                                    </div>
                                                    <div className="mt-3 d-flex align-items-center flex-wrap gap-2">
                                                        <span className="status-access rounded-5 ms-0">
                                                            Tax Resolution
                                                            <img src={require("../../../assets/images/blue-cross.svg")} alt="" />
                                                        </span>
                                                        <span className="status-access rounded-5 ms-0">
                                                            Spouse
                                                            <img src={require("../../../assets/images/blue-cross.svg")} alt="" />
                                                        </span>
                                                        <span className="status-access rounded-5 ms-0">
                                                            1040 - tier 1
                                                            <img src={require("../../../assets/images/blue-cross.svg")} alt="" />
                                                        </span>
                                                        <span className="status-access rounded-5 ms-0">
                                                            Credit Repair
                                                            <img src={require("../../../assets/images/blue-cross.svg")} alt="" />
                                                        </span>
                                                        <span className="status-access rounded-5 ms-0">
                                                            Sales Tax
                                                            <img src={require("../../../assets/images/blue-cross.svg")} alt="" />
                                                        </span>
                                                        <span className="status-access rounded-5 ms-0">
                                                            Google
                                                            <img src={require("../../../assets/images/blue-cross.svg")} alt="" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12 mt-4">
                                        <div className="prof-inside p-4 bg-white shadow-lg rounded-5 recent-files">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h6 className="fs-20">Recent Files</h6>
                                                <a href="#"><img src={require("../../../assets/images/plus-icon.svg")} alt="" /></a>
                                            </div>
                                            <ul className="mt-1 ps-0">
                                                <li className="d-flex align-items-center justify-content-between flex-wrap mt-3">
                                                    <div className="left-cl d-flex align-items-center gap-2">
                                                        <img src={require("../../../assets/images/file-jpg.svg")} alt="" />
                                                        <div className="mt-2">
                                                            <h4 className="fs-18">File Name.jpeg</h4>
                                                            <p className="fs-14">Maci Ernser added this 15 min ago.</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="d-flex align-items-center justify-content-between flex-wrap mt-3">
                                                    <div className="left-cl d-flex align-items-center gap-2">
                                                        <img src={require("../../../assets/images/file-doc.svg")} alt="" />
                                                        <div className="mt-2">
                                                            <h4 className="fs-18">File Name.doc</h4>
                                                            <p className="fs-14">Maci Ernser added this 15 min ago.</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="d-flex align-items-center justify-content-between flex-wrap mt-3">
                                                    <div className="left-cl d-flex align-items-center gap-2">
                                                        <img src={require("../../../assets/images/file-pdf.svg")} alt="" />
                                                        <div className="mt-2">
                                                            <h4 className="fs-18">File Name.pdf</h4>
                                                            <p className="fs-14">Maci Ernser added this 15 min ago.</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="d-flex align-items-center justify-content-between flex-wrap mt-3">
                                                    <div className="left-cl d-flex align-items-center gap-2">
                                                        <img src={require("../../../assets/images/file-xls.svg")} alt="" />
                                                        <div className="mt-2">
                                                            <h4 className="fs-18">File Name.xls</h4>
                                                            <p className="fs-14">Maci Ernser added this 15 min ago.</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="d-flex align-items-center justify-content-between flex-wrap mt-3">
                                                    <div className="left-cl d-flex align-items-center gap-2">
                                                        <img src={require("../../../assets/images/file-doc.svg")} alt="" />
                                                        <div className="mt-2">
                                                            <h4 className="fs-18">File Name.doc</h4>
                                                            <p className="fs-14">Maci Ernser added this 15 min ago.</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                        <div className="prof-inside p-4 bg-white shadow-lg rounded-4">
                                            <div className="d-flex align-items-center justify-content-between" >
                                                <h6 className="fs-20">Contact Details</h6>
                                                {contactList?.length > 0 ? (
                                                    <a onClick={() => showEditContactModal()}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28.001" height="28" viewBox="0 0 28.001 28">
                                                            <g id="write" transform="translate(-7.998 -8)">
                                                                <path id="Path_3528" data-name="Path 3528" d="M8.6,33.775l-.587,4.863a1.462,1.462,0,0,0,1.457,1.654,1.436,1.436,0,0,0,.186-.012l4.863-.587a4.634,4.634,0,0,0,2.7-1.326l12.6-12.6a.583.583,0,0,0,0-.825l-6.465-6.47a.583.583,0,0,0-.825,0l-12.6,12.6a4.634,4.634,0,0,0-1.326,2.7Z" transform="translate(0 -4.292)" fill="#202529" />
                                                                <path id="Path_3529" data-name="Path 3529" d="M47.127,11.9,44.112,8.886a3.023,3.023,0,0,0-4.277,0L37,11.718a.583.583,0,0,0,0,.825l6.468,6.468a.583.583,0,0,0,.825,0l2.832-2.832A3.023,3.023,0,0,0,47.127,11.9Z" transform="translate(-12.014 0)" fill="#202529" />
                                                                <path id="Path_3530" data-name="Path 3530" d="M44.75,53.167A1.167,1.167,0,0,0,43.583,52H30.167a1.167,1.167,0,0,0,0,2.333H43.583A1.167,1.167,0,0,0,44.75,53.167Z" transform="translate(-8.751 -18.333)" fill="#202529" />
                                                            </g>
                                                        </svg>
                                                    </a>

                                                ) : (
                                                    <a onClick={() => showContactModal()}>
                                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                            <path id="Path_3531" data-name="Path 3531" d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z" transform="translate(-1 -1)" fill="#212529" />
                                                        </svg>
                                                    </a>
                                                )}

                                            </div>
                                            {contactList && contactList.length > 0 ? (
                                                contactList.map((clientList) => (
                                                    <div className="row contact-info mt-2" key={clientList?.id}>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Phone No.</p>
                                                            <h4 className="fs-18">{clientList?.phone_number}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Unit</p>
                                                            <h4 className="fs-18">{clientList?.unit_no}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Client Since</p>
                                                            <h4 className="fs-18">{clientList?.client_since}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Client Type</p>
                                                            <h4 className="fs-18">{clientList?.client_type}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Whatsapp Contact</p>
                                                            <h4 className="fs-18">{clientList?.whatsapp_contact}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">City</p>
                                                            <h4 className="fs-18">{clientList?.city}</h4>
                                                        </div>
                                                        <div className="col-md-12 other-info-box mt-2">
                                                            <p className="fs-14">Street Address</p>
                                                            <h4 className="fs-18">{clientList?.address}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Province</p>
                                                            <h4 className="fs-18">{clientList?.province}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Postal Code</p>
                                                            <h4 className="fs-18">{clientList?.postal_code}</h4>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="row contact-info mt-2 position-relative">
                                                    <div className="col-md-12 other-info-box mt-2">
                                                        <h4 className="fs-18 c-text">No Data found</h4>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                        <div className="prof-inside p-4 bg-white shadow-lg rounded-4">
                                            <div className="d-flex align-items-center justify-content-between" >
                                                <h6 className="fs-20">Spouse</h6>
                                                {spouseList?.length > 0 ? (
                                                    <a onClick={() => showEditSpouse()} >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="28.001" height="28" viewBox="0 0 28.001 28">
                                                            <g id="write" transform="translate(-7.998 -8)">
                                                                <path id="Path_3528" data-name="Path 3528" d="M8.6,33.775l-.587,4.863a1.462,1.462,0,0,0,1.457,1.654,1.436,1.436,0,0,0,.186-.012l4.863-.587a4.634,4.634,0,0,0,2.7-1.326l12.6-12.6a.583.583,0,0,0,0-.825l-6.465-6.47a.583.583,0,0,0-.825,0l-12.6,12.6a4.634,4.634,0,0,0-1.326,2.7Z" transform="translate(0 -4.292)" fill="#202529" />
                                                                <path id="Path_3529" data-name="Path 3529" d="M47.127,11.9,44.112,8.886a3.023,3.023,0,0,0-4.277,0L37,11.718a.583.583,0,0,0,0,.825l6.468,6.468a.583.583,0,0,0,.825,0l2.832-2.832A3.023,3.023,0,0,0,47.127,11.9Z" transform="translate(-12.014 0)" fill="#202529" />
                                                                <path id="Path_3530" data-name="Path 3530" d="M44.75,53.167A1.167,1.167,0,0,0,43.583,52H30.167a1.167,1.167,0,0,0,0,2.333H43.583A1.167,1.167,0,0,0,44.75,53.167Z" transform="translate(-8.751 -18.333)" fill="#202529" />
                                                            </g>
                                                        </svg>
                                                    </a>

                                                ) : (
                                                    <a onClick={() => showSpouseModal()}>
                                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                            <path id="Path_3531" data-name="Path 3531" d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z" transform="translate(-1 -1)" fill="#212529" />
                                                        </svg>
                                                    </a>
                                                )}
                                            </div>


                                            {spouseList && spouseList.length > 0 ? (
                                                spouseList.map((spouseList) => (
                                                    <div className="row contact-info mt-2">
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Full Name</p>
                                                            <h4 className="fs-18">{spouseList?.first_name}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">SIN</p>
                                                            <h4 className="fs-18">{spouseList?.sin}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Email Address</p>
                                                            <h4 className="fs-18">{spouseList?.email}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Phone No.</p>
                                                            <h4 className="fs-18">{spouseList?.contact_number}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Date of Birth</p>
                                                            <h4 className="fs-18">{spouseList?.date_of_birth}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Date of Landing</p>
                                                            <h4 className="fs-18">{spouseList?.date_of_landing}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Country of Residence</p>
                                                            <h4 className="fs-18">{spouseList?.country_id}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">City</p>
                                                            <h4 className="fs-18">{spouseList?.city}</h4>
                                                        </div>
                                                        <div className="col-md-12 other-info-box mt-2">
                                                            <p className="fs-14">Street Address</p>
                                                            <h4 className="fs-18">{spouseList?.address}</h4>
                                                        </div>

                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Province</p>
                                                            <h4 className="fs-18">{spouseList?.province}</h4>
                                                        </div>
                                                        <div className="col-md-6 other-info-box mt-2">
                                                            <p className="fs-14">Postal Code</p>
                                                            <h4 className="fs-18">{spouseList?.postal_code}</h4>
                                                        </div>
                                                        <div className="col-md-12 other-info-box mt-2">
                                                            <p className="fs-14">Misc.</p>
                                                            <h4 className="fs-18">{spouseList?.mis_notes}</h4>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="row contact-info mt-2 position-relative">
                                                    <div className="col-md-12 other-info-box mt-2">
                                                        <h4 className="fs-18 c-text">No Data found</h4>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                        <div className=" p-4 bg-white shadow-lg rounded-4">
                                            <div className="prof-insides">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h6 className="fs-20">Dependent Details</h6>
                                                    <a onClick={() => ShowDependent()}>
                                                        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                            <path id="Path_3531" data-name="Path 3531" d="M15,1A14,14,0,1,0,29,15,14,14,0,0,0,15,1Zm6.364,15.273H16.273v5.091a1.273,1.273,0,1,1-2.545,0V16.273H8.636a1.273,1.273,0,1,1,0-2.545h5.091V8.636a1.273,1.273,0,1,1,2.545,0v5.091h5.091a1.273,1.273,0,1,1,0,2.545Z" transform="translate(-1 -1)" fill="#212529" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>

                                            <div>
                                                {dependentList.length === 0 ? (
                                                    <div className="row contact-info mt-2 position-relative">
                                                        <div className="col-md-12 other-info-box mt-2">
                                                            <h4 className="fs-18 c-text">No Data found</h4>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    dependentList.map((dependent, index) => (
                                                        <div key={index} className="director-drop mt-3">
                                                            <div className="accordion mb-2" id={`accordion${index}`}>
                                                                <div className="accordion-item position-relative">
                                                                    <h2 className="accordion-header fs-18 position-relative" id={`heading${index}`}>
                                                                        <button
                                                                            className="accordion-button"
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#collapse${index}`}
                                                                            aria-expanded={activeAccordionIndex === index ? "true" : "false"}
                                                                            aria-controls={`collapse${index}`}
                                                                            onClick={() => handleAccordionClick(index)}
                                                                        >
                                                                            Dependent {index + 1}
                                                                            <a
                                                                                className="position-absolute edt_icon"
                                                                                onClick={() => handleEditIconClick(dependent?.client_id, dependent?.id)}
                                                                            >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="28.001" height="28" viewBox="0 0 28.001 28">
                                                                                    <g id="write" transform="translate(-7.998 -8)">
                                                                                        <path id="Path_3528" data-name="Path 3528" d="M8.6,33.775l-.587,4.863a1.462,1.462,0,0,0,1.457,1.654,1.436,1.436,0,0,0,.186-.012l4.863-.587a4.634,4.634,0,0,0,2.7-1.326l12.6-12.6a.583.583,0,0,0,0-.825l-6.465-6.47a.583.583,0,0,0-.825,0l-12.6,12.6a4.634,4.634,0,0,0-1.326,2.7Z" transform="translate(0 -4.292)" fill="#202529" />
                                                                                        <path id="Path_3529" data-name="Path 3529" d="M47.127,11.9,44.112,8.886a3.023,3.023,0,0,0-4.277,0L37,11.718a.583.583,0,0,0,0,.825l6.468,6.468a.583.583,0,0,0,.825,0l2.832-2.832A3.023,3.023,0,0,0,47.127,11.9Z" transform="translate(-12.014 0)" fill="#202529" />
                                                                                        <path id="Path_3530" data-name="Path 3530" d="M44.75,53.167A1.167,1.167,0,0,0,43.583,52H30.167a1.167,1.167,0,0,0,0,2.333H43.583A1.167,1.167,0,0,0,44.75,53.167Z" transform="translate(-8.751 -18.333)" fill="#202529" />
                                                                                    </g>
                                                                                </svg>
                                                                            </a>
                                                                        </button>
                                                                    </h2>
                                                                    <div
                                                                        id={`collapse${index}`}
                                                                        className={`accordion-collapse collapse${activeAccordionIndex === index ? ' show' : ''}`}
                                                                        aria-labelledby={`heading${index}`}
                                                                        data-bs-parent={`#accordion${index}`}
                                                                    >
                                                                        {activeAccordionIndex === index && (
                                                                            <div className="row accordion-body">
                                                                                <div className="col-md-6 other-info-box mt-2 p-2">
                                                                                    <p className="fs-14">Full Name</p>
                                                                                    <h4 className="fs-18">{dependent?.first_name}</h4>
                                                                                </div>
                                                                                <div className="col-md-6 other-info-box mt-2">
                                                                                    <p className="fs-14">Middle Name</p>
                                                                                    <h4 className="fs-18">{dependent?.middle_name}</h4>
                                                                                </div>
                                                                                <div className="col-md-6 other-info-box mt-2">
                                                                                    <p className="fs-14">Last Name</p>
                                                                                    <h4 className="fs-18">{dependent?.last_name}</h4>
                                                                                </div>
                                                                                <div className="col-md-6 other-info-box mt-2">
                                                                                    <p className="fs-14">SIN</p>
                                                                                    <h4 className="fs-18">{dependent?.sin}</h4>
                                                                                </div>
                                                                                <div className="col-md-6 other-info-box mt-2">
                                                                                    <p className="fs-14">Date of Birth</p>
                                                                                    <h4 className="fs-18">{dependent?.date_of_birth}</h4>
                                                                                </div>
                                                                                <div className="col-md-6 other-info-box mt-2">
                                                                                    <p className="fs-14">Relation</p>
                                                                                    <h4 className="fs-18">{dependent?.relation}</h4>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* <!-- HOME TAB END --> */}

                            {/* <!-- COMMUNICATION TAB START --> */}
                            <div className="tab-pane" id="communication">
                                <div className="row mt-2 align-items-center">
                                    <div className="col-md-7 d-flex align-items-center justify-content-between ">
                                        <div className="left_head">
                                            <h4 className="fs-24 mt-3">Communication</h4>
                                        </div>
                                        <div className="right_head mt-3">
                                            <a href="#" className="send_new text-white d-flex align-items-center justify-content-center">
                                                <img src={require("../../../assets/images/add.svg")} className="me-2" alt="" />
                                                Add New
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- COMMUNICATION TAB END --> */}



                            {/* <!-- FILES TAB START --> */}
                            <div className="tab-pane" id="files">
                                <div className="row mt-2">
                                    <div className="col-md-12 left_head">
                                        <h4 className="fs-24 mt-3">Files</h4>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- FILES TAB START --> */}

                            {/* <!-- TASKS TAB START --> */}
                            <div className="tab-pane" id="tasks">
                                <div className="row mt-2">
                                    <div className="col-md-12 left_head">
                                        <h4 className="fs-24 mt-3">Tasks</h4>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- TASKS TAB START --> */}
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={editClientProfile}
                onHide={onHideHandler}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >


                <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                    <h4 className="fs-20">Edit Profile</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body px-4 pt-0 pb-0">
                    <form
                        onSubmit={handleSubmitProfile}
                    >
                        <div className="advance-form row">
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">First Name</label>
                                <input
                                    type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="first_name"
                                    value={editClientProfileData?.first_name}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Middle Name</label>
                                <input
                                    type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="middle_name"
                                    value={editClientProfileData?.middle_name}
                                    onChange={handleChange}

                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Last Name</label>
                                <input
                                    type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="last_name"
                                    value={editClientProfileData?.last_name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Email Address</label>
                                <input
                                    type="email"
                                    className="form-input mt-2"
                                    placeholder="lisa88@gmail.com"
                                    name="email"
                                    value={editClientProfileData?.email}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">SIN</label>
                                <input
                                    type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="sin"
                                    value={editClientProfileData?.sin}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Date of Birth</label>
                                <input
                                    type="date"
                                    className="form-input mt-2"
                                    name="date_of_birth"
                                    value={editClientProfileData?.date_of_birth}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Date of Landing</label>
                                <input
                                    type="date"
                                    className="form-input mt-2"
                                    name="date_of_landing"
                                    value={editClientProfileData?.date_of_landing}
                                    onChange={handleChange}

                                />
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Marital Status</label>
                                <select className="form-input mt-2" name="marital_status" value={editClientProfileData?.marital_status} onChange={handleChange}>
                                    <option value=""
                                        selected
                                        disabled="disabled">Select Marital Status</option>
                                    {Marital_Status && Marital_Status.getList().map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Marital Status Change Date</label>
                                <input
                                    type="date"
                                    className="form-input mt-2"
                                    name="marital_status_change_date"
                                    value={editClientProfileData?.marital_status_change_date}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Office Location</label>
                                {/* <select

                                    id="office_location"
                                    className="form-input mt-2"
                                    name="office_location" value={editClientProfileData?.office_location}
                                    onChange={handleChange}

                                >
                                    <option value=""
                                        selected
                                        disabled="disabled">Select Gender</option>
                                    {Gender && Gender.getList().map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.value}
                                        </option>
                                    ))}
                                </select> */}

                                <PlacesAutocomplete
                                    value={address} // Replace with your state variable for the address
                                    onChange={handleChangeOfficeAddress} // Handle input change here
                                    onSelect={handleSelect} // Handle address selection here
                                    searchOptions={{
                                        componentRestrictions: { country: ['us', 'ca'] }, // Restrict search to US and Canada
                                    }}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Type address here...',
                                                    className: 'form-input mt-2 google-address',
                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion) => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                            })}
                                                            key={suggestion.placeId}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Primary Contact Person</label>
                                <input type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="primary_contact_person"
                                    value={editClientProfileData?.primary_contact_person}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Family Head</label>
                                <input
                                    type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="family_head"
                                    value={editClientProfileData?.family_head}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Residancy Status</label>
                                <select className="form-input mt-2" name="residency_status" value={editClientProfileData?.residency_status} onChange={handleChange}>
                                    <option value=""
                                        selected
                                        disabled="disabled">Select</option>
                                    {Residency_Status && Residency_Status.getList().map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Referred By Client/Other</label>
                                <select className="form-input mt-2" name="refer_by_client" value={editClientProfileData?.refer_by_client} onChange={handleChange}>
                                    <option value=""
                                        selected
                                        disabled="disabled">Select</option>
                                    {Refer_by_Client && Refer_by_Client.getList().map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Personal Account Manager</label>
                                <select className="form-input mt-2" name="account_manager" value={editClientProfileData?.account_manager} onChange={handleChange}>
                                    <option value="1">Admin admin</option>
                                    <option value="2">johantest@elarafy.com</option>
                                    <option value="3">userstafftest@elarafy.com</option>
                                    <option value="4">testskt skt</option>
                                    <option value="5">prince@savetax.com</option>
                                    <option value="6">test  staff40</option>
                                    <option value="7">Staff35 Prince (PP) </option>
                                    <option value="8">sanjeevstaff elarafy</option>
                                    <option value="9">demotest@elarafy.com</option>
                                    <option value="10">thudemostaff@elarafy.com</option>
                                    <option value="11">checkingstaff@elarafy.com</option>
                                    <option value="12">ddd@elarafy.com</option>
                                    <option value="13">dalvir@elarafy.com</option>
                                    <option value="14">testing2@gmail.com</option>
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label for="firstname">
                                    Refer Staff
                                </label>
                                <select

                                    id="refer_by_staff"
                                    className="form-input mt-2"
                                    name="refer_by_staff"
                                    value={editClientProfileData?.refer_by_staff}
                                    onChange={handleChange}

                                >
                                    <option
                                        value=""
                                        selected
                                        disabled="disabled"
                                    >
                                        Select
                                    </option>

                                    <option value="1">johantest@elarafy.com</option>
                                    <option value="2">userstafftest@elarafy.com</option>
                                    <option value="3">testskt skt</option>
                                    <option value="4">prince@savetax.com</option>
                                    <option value="5">test  staff40</option>
                                    <option value="6">Staff35 Prince (PP) </option>
                                    <option value="7">sanjeevstaff elarafy</option>
                                    <option value="8">demotest@elarafy.com</option>
                                    <option value="9">thudemostaff@elarafy.com</option>
                                    <option value="10">checkingstaff@elarafy.com</option>
                                    <option value="11">ddd@elarafy.com</option>
                                    <option value="12">dalvir@elarafy.com</option>
                                    <option value="13">testing2@gmail.com</option>
                                    <option value="14">testing3@gmail.com</option>
                                    <option value="15">superadmin@elarafy.com</option>
                                    <option value="16">testing1@gmail.com</option>

                                </select>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Client Category</label>
                                <select className="form-input mt-2"
                                    name="client_category"
                                    value={editClientProfileData?.client_category}
                                    onChange={handleChange}

                                >
                                    <option value=""
                                        selected
                                        disabled="disabled">Select</option>
                                    {Client_Category && Client_Category.getList().map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Occupation Category</label>
                                <select className="form-input mt-2" name="occupation_category"
                                    value={editClientProfileData?.occupation_category} onChange={handleChange}>
                                    <option value=""
                                        selected
                                        disabled="disabled">Select</option>
                                    {Occupation_Category && Occupation_Category.getList().map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Self-Employed HST #</label>
                                <input
                                    type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="self_employed_hst_no"
                                    value={editClientProfileData?.self_employed_hst_no}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Gender</label>
                                <select className="form-input mt-2" name="gender" value={editClientProfileData?.gender} onChange={handleChange}>
                                    <option value=""
                                        selected
                                        disabled="disabled">Select</option>
                                    {Gender && Gender.getList().map((data) => (
                                        <option key={data.key} value={data.key}>
                                            {data.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4">
                                <label htmlFor="firstname">Status</label>
                                <select
                                    name="status"
                                    id="status"
                                    className="form-input mt-2"
                                    {...register("status", {

                                    })}
                                >
                                    <option
                                        value=""
                                        selected
                                        disabled="disabled"
                                    >
                                        Status
                                    </option>
                                    <option value="1">Active</option>
                                    <option value="2">InActive</option>
                                </select>
                            </div>
                            <div className="col-md-12 mt-1">

                                <div className="row">
                                    <div className="col-lg-6 mt-3 ">
                                        <label for="firstname">
                                            Upload ID Proof
                                        </label>
                                        <div className="upload-input position-relative mt-2">
                                            <div className="main-wrapper">
                                                <div className="upload-main-wrapper mx-auto text-center">
                                                    <div className="upload-wrapper justify-content-center">
                                                        <input
                                                            type="file"
                                                            name="id_proof"
                                                            onChange={
                                                                onChangeIdProof
                                                            }

                                                        />

                                                        <i className="fa fa-upload me-2"></i>
                                                        <span className="file-upload-text">
                                                            Browse Files
                                                        </span>
                                                    </div>
                                                    <h4 className="fs-14 mt-2">
                                                        Drag & drop here
                                                    </h4>
                                                    <p className="fs-14 text-muted">
                                                        Supports: JPEG,
                                                        JPG, PDF
                                                        <br /> Max Size:
                                                        10 Mb
                                                    </p>
                                                </div>
                                                {IdPicture && (
                                                    <div className="upload-thumb text-center mt-4 d-flex align-items-center justify-content-center">
                                                        <div className="uploaded-img py-3 px-4 rounded-4 d-flex align-items-center">
                                                            {/* Render different thumbnail based on file type */}
                                                            {IdPicture.type.includes("image") ? (
                                                                <>
                                                                    <img src={URL.createObjectURL(IdPicture)} alt="Selected Image" />
                                                                    <h6 className="ms-2">{IdPicture.name}</h6>
                                                                </>

                                                            ) : (
                                                                <div>
                                                                    {/* Render file icon for non-image files */}
                                                                    {IdPicture.type.includes("pdf") && <img src={require("../../../assets/images/PDF_file_icon.png")} />}
                                                                    {IdPicture.type.includes("docx") && <img src={require("../../../assets/images/Google_Docs_Logo_512px.png")} />}
                                                                    <h6 className="ms-2">{IdPicture.name}</h6>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 mt-3">
                                        <label for="firstname">
                                            Upload Profile Picture
                                        </label>
                                        <div className="upload-input position-relative mt-2 text-center">
                                            <div className="main-wrapper">
                                                <div className="upload-main-wrapper mx-auto text-center">
                                                    <div className="upload-wrapper">
                                                        <input
                                                            type="file"
                                                            name="profile_picture"
                                                            onChange={onChangePicture}
                                                        />


                                                        <i className="fa fa-upload me-2"></i>
                                                        <span className="file-upload-text">
                                                            Browse Files
                                                        </span>
                                                    </div>
                                                    <h4 className="fs-14 mt-2">
                                                        Drag & drop here
                                                    </h4>
                                                    <p className="fs-14 text-muted">
                                                        Supports: JPEG,
                                                        JPG, PDF
                                                        <br /> Max Size:
                                                        10 Mb
                                                    </p>

                                                </div>
                                                {picture && (
                                                    <div className="upload-thumb text-center mt-4 d-flex align-items-center justify-content-center">
                                                        <div className="uploaded-img py-3 px-4 rounded-4 d-flex align-items-center">
                                                            {picture.type.includes("image") ? (
                                                                <>
                                                                    <img src={URL.createObjectURL(picture)} alt="Selected Image" />
                                                                    <h6 className="ms-2">{picture.name}</h6>
                                                                </>
                                                            ) : (
                                                                <div>
                                                                    {picture.type.includes("pdf") && <img src={require("../../../assets/images/PDF_file_icon.png")} alt="PDF Icon" />}
                                                                    {picture.type.includes("docx") && <img src={require("../../../assets/images/Google_Docs_Logo_512px.png")} alt="Docs Icon" />}
                                                                    <h6 className="ms-2">{picture.name}</h6>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="modal-footer border-0 px-0 pb-4 mt-4">
                            <button type="submit" className="btn-blue">Update</button>
                        </div>
                    </form>



                </div>
            </Modal>

            <Modal
                show={editContactModal}
                onHide={hideEditContact}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >

                <div className="modal-header border-0 justify-content-between">
                    <h4 className="fs-20">Edit Contact</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body">
                    <form onSubmit={handleSubmit(onContactSubmit)}>
                        <div className="advance-form row">
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Phone No.</label>
                                <input
                                    type="number"
                                    name="phone_number"
                                    id="phone_number"
                                    className="form-input mt-2"
                                    placeholder="Phone No."
                                    {...register("phone_number", {
                                        required: "Required",
                                        pattern: {
                                            value: /^[0-9]*$/, // Regex for only numeric characters
                                            message: "Please enter only numeric characters"
                                        },
                                        minLength: {
                                            value: 7,
                                            message: "Phone number must be at least 7 digits long"
                                        },
                                    })}
                                />
                                {errors.phone_number && (
                                    <span className="text-danger mb-0 pt-1 -fs-7 ms-1">{errors.phone_number.message}</span>
                                )}
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Unit</label>
                                <input
                                    type="text"
                                    name="unit_no"
                                    id="unit_no"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    {...register("unit_no", {
                                        required: "Required",
                                    })}
                                />
                                {errors.unit_no && (
                                    <span className="text-danger mb-0 pt-1 -fs-7 ms-1">{errors.unit_no.message}</span>
                                )}
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Client Since</label>
                                <input
                                    type="date"
                                    name="client_since"
                                    id="client_since"
                                    className="form-input mt-2"
                                    {...register("client_since", {
                                        required: "Required",
                                    })}
                                />
                                {errors.client_since && (
                                    <span className="text-danger mb-0 pt-1 -fs-7 ms-1">{errors.client_since.message}</span>
                                )}
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Client Type</label>
                                <select
                                    name="client_type"
                                    id="client_type"
                                    className="form-input mt-2"
                                    {...register("client_type", {
                                        required: "Required",

                                    })}

                                >
                                    <option value="0" disabled selected>Select</option>
                                    <option value="Client" selected="">Client</option>
                                    <option value="Director Only">Director Only</option>
                                </select>
                                {errors.client_type && (
                                    <span className="text-danger mb-0 pt-1 -fs-7 ms-1">{errors.client_type.message}</span>
                                )}
                            </div>

                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Whatsapp Contact No.</label>
                                <input
                                    type="number"
                                    name="whatsapp_contact"
                                    id="whatsapp_contact"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    {...register("whatsapp_contact", {

                                        required: "Required",
                                        pattern: {
                                            value: /^[0-9]*$/, // Regex for only numeric characters
                                            message: "Please enter only numeric characters"
                                        },
                                        minLength: {
                                            value: 7,
                                            message: "Phone number must be at least 7 digits long"
                                        },

                                    })}
                                />
                                {errors.whatsapp_contact && (
                                    <span className="text-danger mb-0 pt-1 -fs-7 ms-1">{errors.whatsapp_contact.message}</span>
                                )}
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">City</label>
                                <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    {...register("city", {

                                        // required: "Required ",
                                    })}
                                />

                                {errors.city && (
                                    <span className="text-danger mb-0 pt-1 -fs-7 ms-1">{errors.city.message}</span>
                                )}
                            </div>
                            <div className="col-lg-8 col-md-12 col-md-12 mt-4">
                                <label className="fs-18">Street Address</label>
                                {/* <input
                                    type="text"
                                    name="address"
                                    id="address"
                                    className="form-input mt-2 google-address"
                                    placeholder="Type here..."
                                    {...register("address", {


                                    })}

                                /> */}
                                <PlacesAutocomplete
                                    value={addressContect}
                                    onChange={handleChangeContactAddress}  // Handle input change here
                                    onSelect={handleSelectContect}  // Handle address selection here
                                    searchOptions={{
                                        componentRestrictions: { country: ['us', 'ca'] },
                                    }}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Type address here...',
                                                    className: 'form-input mt-2 google-address',
                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion) => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                            })}
                                                            key={suggestion.placeId}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>



                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Province</label>
                                <input
                                    className="form-input mt-2"
                                    name="province"
                                    id="province"
                                    value={province} // Bind the province state to this input field
                                    readOnly
                                    {...register("province", {})}
                                />
                                {/* {errors.province && (
                                <span className="error-message text-denger">{errors.province.message}</span>
                            )} */}
                            </div>

                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Postal Code</label>
                                <input
                                    type="text"
                                    name="postal_code"
                                    id="postal_code"
                                    className="form-input mt-2"
                                    value={postal} // Bind the postal state to this input field
                                    placeholder="Type here..."
                                    readOnly
                                    {...register("postal_code", {

                                    })}
                                />
                            </div>

                        </div>

                        <div className="modal-footer border-0 px-0 pb-4 mt-4">
                            <button type="submit" className="btn-blue">Update</button>
                        </div>
                    </form>



                </div>
            </Modal>
            <Modal
                show={editSpouse}
                onHide={hideEditSpouse}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >

                <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                    <h4 className="fs-20">Edit Spouse</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body px-4 pt-0 pb-0">
                    <form
                        onSubmit={handleSubmit(onSpouseSubmit)}

                    >
                        <div className="advance-form row">
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Name</label>
                                <input
                                    type="text"
                                    name="first_name"
                                    id="first_name"
                                    className="form-input mt-2"
                                    {...register("first_name", {
                                        required: "Required ",
                                    })}
                                />
                                {errors?.first_name && <span className="error">{errors?.first_name.message}</span>}
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Midlle Name</label>
                                <input
                                    type="text"
                                    name="middle_name"
                                    id="middle_name"
                                    className="form-input mt-2"
                                    {...register("middle_name", {

                                    })}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Last Name</label>
                                <input
                                    type="text"
                                    name="last_name"
                                    id="last_name"
                                    className="form-input mt-2"
                                    {...register("last_name", {
                                        required: "Required ",
                                    })}
                                />
                                {errors?.last_name && <span className="error">{errors?.last_name.message}</span>}
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">SIN</label>
                                <input
                                    type="text"
                                    name="sin"
                                    id="sin"
                                    className="form-input mt-2"
                                    {...register("sin", {

                                    })}

                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Email Address</label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="form-input mt-2"
                                    {...register("email", {
                                        required: "Required ",
                                    })}
                                />
                                {errors?.email && <span className="error">{errors?.email.message}</span>}
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Phone No.</label>
                                <input
                                    type="text"
                                    name="contact_number"
                                    id="contact_number"
                                    className="form-input mt-2"
                                    {...register("contact_number", {

                                    })}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Date of Birth</label>
                                <input
                                    type="date"
                                    name="date_of_birth"
                                    id="date_of_birth"
                                    className="form-input mt-2"
                                    {...register("date_of_birth", {

                                    })}

                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Date of Landing</label>
                                <input
                                    type="date"
                                    name="date_of_landing"
                                    id="date_of_landing"
                                    className="form-input mt-2"
                                    {...register("date_of_landing", {

                                    })}
                                />
                            </div>
                            <div className="col-lg-4 col-md-12 mt-4">
                                <label className="fs-18">Country of Residence</label>
                                <select
                                    className="form-input mt-2"
                                    name="country_id"
                                    id="country_id"
                                    {...register("country_id", {

                                    })}

                                >
                                    <option value="0" disabled selected>Select</option>
                                    <option value="1" >Canada</option>
                                    <option value="2">USA</option>
                                    <option value="3">India</option>
                                    <option value="4">Other</option>
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">City</label>
                                <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    className="form-input mt-2"
                                    {...register("city", {

                                    })}

                                />
                            </div>
                            <div className="col-lg-8 col-md-12 col-md-12 mt-4">
                                <label className="fs-18">Street Address</label>
                                {/* <input
                                    type="text"
                                    name="address"
                                    id="address"
                                    className="form-input mt-2 google-address"
                                    {...register("address", {

                                    })}
                                /> */}
                                <PlacesAutocomplete
                                    value={addressSpouse}
                                    onChange={handleChangeSpouseAddress}  // Handle input change here
                                    onSelect={handleSelectSpouse}  // Handle address selection here
                                    searchOptions={{
                                        componentRestrictions: { country: ['us', 'ca'] },
                                    }}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Type address here...',
                                                    className: 'form-input mt-2 google-address',
                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion) => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                            })}
                                                            key={suggestion.placeId}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Province</label>
                                <input
                                    className="form-input mt-2"
                                    name="province"
                                    id="province"
                                    value={provinceSpouse}
                                    readOnly
                                    {...register("province", {})}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Postal Code</label>
                                <input
                                    type="text"
                                    name="postal_code"
                                    id="postal_code"
                                    value={postalSpouse}

                                    className="form-input mt-2"
                                    {...register("postal_code", {


                                    })}

                                />
                            </div>
                            <div className="col-lg-8 col-md-12 col-md-12 mt-4">
                                <label className="fs-18">Misc. Note</label>
                                <input
                                    type="text"
                                    name="mis_notes"
                                    id="mis_notes"
                                    className="form-input mt-2"
                                    {...register("mis_notes", {


                                    })}

                                />
                            </div>

                        </div>

                        <div className="modal-footer border-0 px-0 pb-4 mt-4">
                            <button type="submit" className="btn-blue">Update</button>
                        </div>
                    </form>



                </div>
            </Modal>


            <Modal
                show={editDepentModal}
                onHide={hideEditDepentModal}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >


                <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                    <h4 className="fs-20">Edit Dependent Details</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>

                <form onSubmit={(e) => {
                    e.preventDefault(); // Prevent the default form submission behavior
                    // Call the function to update operation data
                    updateDependtentData(editDepentDetail?.client_id, editDepentDetail?.id);
                }}>


                    <div className="modal-body p-0">

                        <div className="spouse-form row mt-2 input_wrap">
                            <div className="row mb-3">
                                <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                    <label className="fs-18">First Name</label>
                                    <input
                                        type="text"
                                        name="first_name"
                                        id="first_name"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        value={editDepentDetail?.first_name} // Assuming 'registration_date' is the property to be displayed
                                        onChange={(e) => {
                                            setEditDepentDetail({ ...editDepentDetail, first_name: e.target.value });
                                            setErrors({ ...error, first_name: '' }); // Clear error when the user starts typing
                                        }}
                                    />
                                    {error.first_name && <p className="text-danger mt-1">{error.first_name}</p>} {/* Show error message */}
                                </div>
                                <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                    <label className="fs-18">Last Name</label>
                                    <input
                                        type="text"
                                        name="last_name"
                                        id="last_name"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        value={editDepentDetail?.last_name} // Assuming 'registration_date' is the property to be displayed
                                        onChange={(e) => {
                                            setEditDepentDetail({ ...editDepentDetail, last_name: e.target.value });
                                            setErrors({ ...error, last_name: '' }); // Clear error when the user starts typing
                                        }}
                                    />
                                    {error.last_name && <p className="text-danger mt-1">{error.last_name}</p>} {/* Show error message */}
                                </div>
                                <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                    <label className="fs-18">Middle_Name</label>
                                    <input
                                        type="text"
                                        name="middle_name"
                                        id="middle_name"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        value={editDepentDetail?.middle_name} // Assuming 'registration_date' is the property to be displayed
                                        onChange={(e) => setEditDepentDetail({ ...editDepentDetail, middle_name: e.target.value })}

                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                    <label className="fs-18">SIN</label>
                                    <input
                                        type="text"
                                        name="sin"
                                        id="sin"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        value={editDepentDetail?.sin} // Assuming 'registration_date' is the property to be displayed
                                        onChange={(e) => setEditDepentDetail({ ...editDepentDetail, sin: e.target.value })}

                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                    <label className="fs-18">Date_Of_Birth</label>
                                    <input
                                        type="date"
                                        name="date_of_birth"
                                        id="date_of_birth"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        value={editDepentDetail?.date_of_birth} // Assuming 'registration_date' is the property to be displayed
                                        onChange={(e) => setEditDepentDetail({ ...editDepentDetail, date_of_birth: e.target.value })}

                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                    <label className="fs-18">relation</label>
                                    <input
                                        type="text"
                                        name="relation"
                                        id="relation"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        value={editDepentDetail?.relation} // Assuming 'registration_date' is the property to be displayed
                                        onChange={(e) => setEditDepentDetail({ ...editDepentDetail, relation: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer border-0 px-0 pb-4 mt-4">
                        <button type="submit" className="btn-blue">Update</button>
                    </div>
                </form>
            </Modal>
            <Modal
                show={showCustomFormModal}
                onHide={() => setShowCustomFormModal(false)}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal modal-lg email-modal"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-body position-relative">
                    <div className="modal-head d-flex align-items-center justify-content-between gap-3 px-3 pt-3">
                        <h4 className="fs-20 fw-semibold">Fill Required Forms</h4>
                        <svg onClick={() => setShowCustomFormModal(false)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path id="circle-xmark" d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm3.707,14.293a1,1,0,1,1-1.414,1.414L12,13.414,9.707,15.707a1,1,0,0,1-1.414-1.414L10.586,12,8.293,9.707A1,1,0,0,1,9.707,8.293L12,10.586l2.293-2.293a1,1,0,0,1,1.414,1.414L13.414,12l2.293,2.293Z" fill="#212529" />
                        </svg>
                    </div>
                    <form>
                        <div className="tabs-section mt-2">
                            <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
                                <div className="p-2">
                                    <TabList>
                                        {currentTask?.links?.map((form, index) => (
                                            <Tab key={index}>
                                                {form.form_data?.length > 0 && form.form_data[0]?.title
                                                    ? form.form_data[0].title
                                                    : form.title || `Form ${index + 1}`}
                                            </Tab>
                                        ))}
                                    </TabList>
                                </div>


                                <div className="tabs-contentmain pb-3">
                                    {currentTask?.links?.map((form, index) => (
                                        <TabPanel key={index}>
                                            <div className="row p-0">
                                                {selectedForm?.id === form.id && surveyModels[index] && (
                                                    <div className="survey-container">
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger mt-3 del-survey"
                                                        // onClick={() => deleteCustomForm()}
                                                        >
                                                            Delete Survey Form
                                                        </button>
                                                        <Survey model={surveyModels[index]} onComplete={handleCompleteSurvey} />

                                                    </div>
                                                )}
                                            </div>
                                        </TabPanel>
                                    ))}
                                </div>
                            </Tabs>

                        </div>
                    </form>
                </div >
            </Modal >
            <Add_Contact
                showcCntactModal={showcCntactModal}
                setShowContactModal={setShowContactModal}
                fetchConData={fetchConData}
            />
            <Spouse
                spouseModal={spouseModal}
                setSpouseModal={setSpouseModal}
                fetchSpouData={fetchSpouData}
            />
            <Dependent_Detail
                showDependentModal={showDependentModal}
                setShowDependentModal={setShowDependentModal}
                fetchData={fetchData}
            />

        </>

    )
}
export default IndClientProfile;